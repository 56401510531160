import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';

@Component({
  selector: 'app-complaint-tickets-list',
  templateUrl: './complaint-tickets-list.component.html',
  styleUrls: ['./complaint-tickets-list.component.scss']
})
export class ComplaintTicketsListComponent implements OnInit {
  @Output() ticketSelected = new EventEmitter<any>();
  @Output() newComplaintClicked = new EventEmitter<void>();

  loading = false;
  error: string | null = null;
  selectedTabIndex:any ;
  searchText = '';
  assignedTickets: any[] = [];
  createdTickets: any[] = [];
  filteredAssignedTickets: any[] = [];
  filteredCreatedTickets: any[] = [];
  displayedTickets: any[] = [];
  usrData: any;
  selectedTicket: any = null;
  assignedFilter: 'all' | 'pending' | 'completed' = 'all';
  isFilterModalVisible = false;
  activeFilters: { status: string[], types: string[] } = {
    status: [],
    types: []
  };

  // Pagination properties
  pageSize = 4;
  assignedCurrentPage = 1;
  createdCurrentPage = 1;
  assignedTotalPages = 1;
  createdTotalPages = 1;
  paginatedAssignedTickets: any[] = [];
  paginatedCreatedTickets: any[] = [];

  constructor(
    private apiSrvc: AppsService,
    private message: NzMessageService
  ) {
    this.usrData = JSON.parse(localStorage.getItem('userdata') || '{}');
  }

  ngOnInit() {
   
      this.selectedTabIndex=0
    
   
    this.getComplaintList();
  }

  getComplaintList() {
    this.loading = true;
    this.error = null;
    const route = 'complaint/list';
    this.apiSrvc.get(route).subscribe({
      next: (response: any) => {
        console.log('API Response:', response);
        if (response && response.data) {
          // Process and validate ticket data
          // this.selectedTabIndex=this.newComplaintClicked ? 1 : 0
        
          this.assignedTickets = response.data[0].map((ticket: any) => {
            return {
              ...ticket,
              tckt_id: ticket.tckt_id || ticket.id || null,
              wrk_sts_nm: ticket.wrk_sts_nm || 'Pending',
              wrk_grp_nm: ticket.wrk_grp_nm || 'Not Assigned',
              wrk_typ_nm: ticket.wrk_typ_nm || 'Not Specified'
            };
          });
          
          this.createdTickets = response.data[1].map((ticket: any) => {
            return {
              ...ticket,
              tckt_id: ticket.tckt_id || ticket.id || null,
              wrk_sts_nm: ticket.wrk_sts_nm || 'Pending',
              wrk_grp_nm: ticket.wrk_grp_nm || 'Not Assigned',
              wrk_typ_nm: ticket.wrk_typ_nm || 'Not Specified'
            };
          });

          this.filterTickets();

          // if(this.selectedTicket){
          //   console.log('saiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii:', this.selectedTicket);
          //   this.selectedTicket.isAssignedTab =false
          //    this.selectTicket(this.selectedTicket );

          // }
        } else {
          this.assignedTickets = [];
          this.createdTickets = [];
          console.warn('No ticket data received from API');
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching complaints:', error);
        this.error = 'Failed to load tickets. Please try again.';
        this.loading = false;
        this.message.error('Failed to load tickets. Please try again.');
      }
    });
  }

  onTabChange(event: NzTabChangeEvent) {
    console.log('indexxxxtoooooooooooooooooo:', event);
    this.selectedTabIndex = event;
    this.filterTickets();
  }

  onSearchTextChange(value: string): void {
    this.searchText = value;
    this.filterTickets();
  }

  applyFilters(filters: { status: string[], types: string[] }): void {
    this.activeFilters = filters;
    this.filterTickets();
  }

  showFilterModal(): void {
    this.isFilterModalVisible = true;
  }

  onAssignedFilterChange(value: 'all' | 'pending' | 'completed'): void {
    this.assignedFilter = value;
    
    // Apply the assigned filter
    const tickets = this.selectedTabIndex === 0 ? this.assignedTickets : this.createdTickets;
    let filtered = [...tickets];

    // First apply the quick filter (all, pending, completed)
    if (value !== 'all') {
      filtered = filtered.filter(ticket => {
        if (value === 'pending') {
          return ticket.assgn_sts === 'pending';
        } else if (value === 'completed') {
          return ticket.assgn_sts === 'complited';
        }
        return true;
      });
    }

    // Then apply the modal filters if they exist
    if (this.activeFilters.status.length > 0) {
      filtered = filtered.filter(ticket => 
        this.activeFilters.status.includes(ticket.wrk_sts_nm)
      );
    }

    if (this.activeFilters.types.length > 0) {
      filtered = filtered.filter(ticket => 
        this.activeFilters.types.includes(ticket.wrk_grp_nm)
      );
    }

    // Apply search text filter
    if (this.searchText) {
      const searchLower = this.searchText.toLowerCase();
      filtered = filtered.filter(ticket =>
        this.ticketMatchesSearch(ticket, searchLower)
      );
    }

    // Update the filtered results
    if (this.selectedTabIndex === 0) {
      this.filteredAssignedTickets = filtered;
    } else {
      this.filteredCreatedTickets = filtered;
    }
    
    this.updatePagination();
  }

  filterTickets(): void {
    // Reset to the current assigned filter value to reapply all filters
    this.onAssignedFilterChange(this.assignedFilter);
  }

  ticketMatchesSearch(ticket: any, searchTerm: string): boolean {
    if (!ticket) return false;
    
    const searchableFields = [
      ticket.tckt_id?.toString(),
      ticket.cmplnt_typ,
      ticket.wrk_grp_nm,
      ticket.wrk_typ_nm,
      ticket.wrk_sts_nm
    ];
    
    return searchableFields.some(field => 
      field && field.toLowerCase().includes(searchTerm)
    );
  }

  selectTicket(ticket: any) {
    console.log('indexxxx:', ticket);
    this.selectedTicket = ticket;
    // Pass both the ticket and the current tab index
    this.ticketSelected.emit({
      ticket: ticket,
      isAssignedTab: this.selectedTabIndex == 0 && ticket.wrk_sts_id!=6 && ticket.assgn_sts=='pending' // 0 is the index for Assigned Tickets tab
    });
  }

  updatePagination() {
    // Update assigned tickets pagination
    this.assignedTotalPages = Math.ceil(this.filteredAssignedTickets.length / this.pageSize);
    const assignedStartIndex = (this.assignedCurrentPage - 1) * this.pageSize;
    this.paginatedAssignedTickets = this.filteredAssignedTickets.slice(
      assignedStartIndex,
      assignedStartIndex + this.pageSize
    );

    // Update created tickets pagination
    this.createdTotalPages = Math.ceil(this.filteredCreatedTickets.length / this.pageSize);
    const createdStartIndex = (this.createdCurrentPage - 1) * this.pageSize;
    this.paginatedCreatedTickets = this.filteredCreatedTickets.slice(
      createdStartIndex,
      createdStartIndex + this.pageSize
    );
  }

  getPaginatedTicketsAssigned(): any[] {
    return this.paginatedAssignedTickets;
  }

  getPaginatedTicketsCreated(): any[] {
    return this.paginatedCreatedTickets;
  }

  getCurrentPageAssigned(): number {
    return this.assignedCurrentPage;
  }

  getCurrentPageCreated(): number {
    return this.createdCurrentPage;
  }

  getTotalPagesAssigned(): number {
    return this.assignedTotalPages;
  }

  getTotalPagesCreated(): number {
    return this.createdTotalPages;
  }

  getFilteredTicketsLengthAssigned(): number {
    return this.filteredAssignedTickets.length;
  }

  getFilteredTicketsLengthCreated(): number {
    return this.filteredCreatedTickets.length;
  }

  onPageChangeAssigned(page: number) {
    this.assignedCurrentPage = page;
    this.updatePagination();
  }

  onPageChangeCreated(page: number) {
    this.createdCurrentPage = page;
    this.updatePagination();
  }

  getStatusColor(status: string): string {
    switch (status?.toLowerCase()) {
      case 'open':
        return 'blue';
      case 'in progress':
        return 'processing';
      case 'resolved':
        return 'success';
      case 'closed':
        return 'default';
      case 'pending':
        return 'warning';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  }

  refreshAndSelectCreatedTab() {
     this.selectedTabIndex = 1;
    this.getComplaintList();
    setTimeout(() => {
      
      this.filterTickets();
    }, 100);
  }

  refreshAndSelectCreatedTabassign(){
    this.selectedTabIndex = 0;
    this.getComplaintList();
    setTimeout(() => {
      // this.selectedTabIndex = 1;
      this.filterTickets();
    }, 100);
  }

  setSelectedTab(index: number) {
    this.selectedTabIndex = index;
    this.filterTickets();
  }

  getStatusCount(status: 'all' | 'pending' | 'completed'): number {
    const tickets = this.selectedTabIndex === 0 ? this.assignedTickets : this.createdTickets;
    
    if (status === 'all') {
      return tickets.length;
    } else if (status === 'pending') {
      return tickets.filter(ticket => ticket.assgn_sts === 'pending').length;
    } else {
      return tickets.filter(ticket => ticket.assgn_sts === 'complited').length;
    }
  }
}
