<div class="container-fluid">
    <nz-card>
        <div class="row mt-3">
            <div class="col-md-12">
                <dx-data-grid id="gridContainer" [dataSource]="gridData" [showBorders]="true" [columnAutoWidth]="true"
                    [showRowLines]="true" (onToolbarPreparing)="onToolbarPreparing($event)">

                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                    <dxi-column caption="S.No" cellTemplate="serialNumberTemplate" [width]="70"></dxi-column>

                    <!-- <dxi-column dataField="sno" caption="Sno" [width]="100" alignment="center"></dxi-column> -->
                    <dxi-column dataField="cty_nm" caption="City Name"></dxi-column>
                    <dxi-column dataField="i_ts" caption="Creation Time" alignment="center"></dxi-column>
                    <dxi-column dataField="u_ts" caption="Updated Time" alignment="center"></dxi-column>
                    <dxi-column dataField="crte_usr" caption="Created By" alignment="center"></dxi-column>
                    <dxi-column dataField="updte_usr" caption="Updated By" alignment="center"></dxi-column>
                    <dxi-column caption="Actions" [width]="100" cellTemplate="cellTemplate"></dxi-column>

                    <div *dxTemplate="let data of 'cellTemplate'">
                        <button nz-button nzType="primary" class="mr-2" (click)="editRecord(data.data)">
                            <i nz-icon nzType="edit"></i>
                        </button>
                        <button nz-button nzType="danger" (click)="deleteRecord(data.data)">
                            <i nz-icon nzType="delete"></i>
                        </button>
                    </div>
                    <div *dxTemplate="let data of 'serialNumberTemplate'">
                        {{data.rowIndex + 1}}
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </nz-card>

    <nz-drawer [nzVisible]="shwSidebar" [nzTitle]="editdata ? 'Edit City' : 'Add City'" (nzOnClose)="closeSidebar()" [nzWidth]="500">
        <ng-container *nzDrawerContent>
            <div *ngIf="shwDeleteCnfrm == false">
                <form nz-form [formGroup]="cityForm" (ngSubmit)="submitForm()">
                    <div class="row">
                        <div class="col-md-12">
                            <nz-form-item>
                                <nz-form-label>City Name</nz-form-label>
                                <nz-form-control>
                                    <input nz-input formControlName="cty_nm" placeholder="Enter city name" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <button nz-button nzType="primary" [nzLoading]="shwLdng" type="submit">
                                {{ editdata ? 'Update' : 'Save' }}
                            </button>
                            <button nz-button class="ml-2" type="button" (click)="closeSidebar()">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="shwDeleteCnfrm == true">
                <nz-alert nzType="warning" nzMessage="Warning" nzDescription="You are about to delete the following record."
                    nzShowIcon></nz-alert>
                <div>
                    <div>
                        <table class="del-confirm-table">
                            <tr class="row">
                                <td class="col">City</td>
                                <td class="col">:{{ cityForm.value.cty_nm }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <button nz-button nzType="default" class="col m-5" (click)="shwDeleteCnfrm = false">Cancel</button>
                    <button nz-button nzType="primary" class="col m-5" nz-popconfirm
                        nzPopconfirmTitle="Are you sure to delete city?" nzPopconfirmPlacement="bottom"
                        (nzOnConfirm)="confirmDelete()" (nzOnCancel)="shwDeleteCnfrm = false">Delete</button>
                </div>
            </div>
        </ng-container>
    </nz-drawer>
</div>
