<nz-spin [nzSpinning]="loading">
  <nz-card class="ticket-details-card">
    <!-- Header Information -->
    <nz-descriptions nzTitle="Ticket Details" [nzColumn]="3">
      <nz-descriptions-item nzTitle="Complaint Type">
        {{ ticketDtls[0]?.wrk_grp_nm || '--' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Complaint Category">
        {{ ticketDtls[0]?.wrk_typ_nm || '--' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Created Date">
        {{ ticketDtls[0]?.wrk_strt_dt || '--' }}
      </nz-descriptions-item>

      <nz-descriptions-item nzTitle="CAF Name">
        {{ ticketDtls[0]?.cstmr_nm || '--' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="CAF Count">
        {{ ticketDtls[0]?.cstmr_efctd_ct || '--' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="District">
        {{ ticketDtls[0]?.dstrt_nm || '--' }}
      </nz-descriptions-item>

      <nz-descriptions-item nzTitle="Mandal">
        {{ ticketDtls[0]?.mndl_nm || '--' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Ticket Type">
        {{ ticketDtls[0]?.tckt_typ_nm || '--' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Priority">
        <nz-tag [nzColor]="getPriorityColor(ticketDtls[0]?.priority)">
          {{ ticketDtls[0]?.priority || '--' }}
        </nz-tag>
      </nz-descriptions-item>
    </nz-descriptions>




    <!-- Description Section -->

    <!-- <div class="description-section">
      <nz-divider></nz-divider>
      <h3 nz-typography>Description</h3>
      <p nz-typography nzType="secondary">
        {{ ticketDtls[0]?.oms_wrm_dsc_tx || 'No description available' }}
      </p>
    </div> -->

    <!-- Comments Section -->
    <!-- <div class="comments-section">
      <nz-divider></nz-divider>
      <h3 nz-typography>Comments</h3> -->

    <!-- Add Comment -->
    <!-- <div class="add-comment">
        <nz-comment [nzAuthor]="'Support Agent'" [nzAvatar]="'assets/images/support/avatar.png'">
          <nz-comment-content>
            <nz-form-item>
              <nz-form-control>
                <nz-textarea-count [nzMaxCharacterCount]="500">
                  <textarea 
                    rows="4" 
                    nz-input 
                    [(ngModel)]="lng_dscn_tx"
                    placeholder="Write a comment..."></textarea>
                </nz-textarea-count>
              </nz-form-control>
            </nz-form-item>
          </nz-comment-content>
          <nz-comment-action>
            <button nz-button nzType="primary" (click)="clickbtns('cmntsbmt',Tckt_dtls.mail)" [disabled]="!lng_dscn_tx.trim()">
              Submit
            </button>
          </nz-comment-action>
        </nz-comment>
      </div> -->

    <!-- Timeline of Updates -->
    <!-- <div class="max-h-120 overflow-y-auto">
        <div class="relative flex flex-col mx-4 sm:mx-8 my-6">
          <div *ngFor="let f of Tckt_dtls?.tcktHisData" class="flex">
            <div class="w-16 flex relative justify-center">
              <div class="vr-line relative m-auto">
                <div class="absolute w-10 h-10 flex justify-center"
                  style="transform: translateX(-50%);">
                  <img *ngIf="f.usr_imge_url_tx"
                    class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-gray-400"
                    [src]="f.usr_imge_url_tx" alt="Card cover image">
                  <img *ngIf="!f.usr_imge_url_tx"
                    class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-gray-400"
                    src="assets/images/support/avatar.png" alt="Card cover image">
                </div>
              </div>
            </div>

            <div class="flex flex-col mt-0.5 p-2">
              <div class="flex  gap-2">
                <b>{{f.fst_nm}} {{f.lst_nm}}</b>
                <span class="flex items-center text-sm text-secondary mt-0">
                  <span>on {{f.chng_dt}}</span>
                </span>
              </div>
              <div class="flex flex-col gap-2">
                <div mat-line *ngIf="f.chnge_type_nm">
                  <span
                    *ngIf="!( f.chnge_type_nm == 'Comment Added')">{{f.chnge_type_nm}}</span>
                  <span
                    *ngIf="f.fst_nm?.length && ( f.chnge_type_nm == 'Ticket Reverted' || f.chnge_type_nm == 'Officer Assigned')">
                    <span> {{f.rejct_in == 1 ? 'from':'-'}}</span>
                    <span class="font-semibold text-orange-400"> {{ f.fst_nm}}
                      <span *ngIf="f?.dsgns_nm?.length"> ({{f.dsgns_nm}})</span>
                    </span>
                  </span>
                </div>
                <div mat-line *ngIf="f.cmnt_tx"
                  class="mt-4 rounded-lg bg-gray-200 px-5 py-4 dark:bg-gray-800 ng-star-inserted">
                  <b>Commented</b><br />
                  {{f.cmnt_tx}}
                </div>

              </div>
              <div mat-line *ngIf="f.atchmnts.length " class="flex flex-wrap gap-2">
                <div *ngFor="let data of f.atchmnts" class="pt-2">
                  <div class="pb-1"><span class="font-semibold">{{data.fle_typ_nm}}</span>
                  </div>
                  <div [title]="data.atcht_nm"
                    class="flex flex-row items-center border border-gray-300 rounded-md p-1 gap-3 w-64">
                    
                    <div class="w-10 h-10" *ngIf="data.fle_type_id == 4">
                      <span><mat-icon class="icon-size-10">attachment</mat-icon></span>
                      <a [href]="data.url_tx" style="text-decoration: none"></a>
                    </div>

                    <div class="w-10 h-10" *ngIf="data.fle_type_id != 4">
                      <img src="assets/images/support/pdf.png"
                        class="object-cover w-full h-full rounded-md">
                      <a [href]="data.url_tx" style="text-decoration: none"></a>
                    </div>

                    <div class="w-3/5">
                      <p class="text-sm font-semibold truncate">{{data.atcht_nm}}</p>
                    </div>
                    <div>
                      
                      <mat-icon class="icon-size-5 mr-3" style="cursor: pointer;"
                        (click)="findFileType(data.url_tx)">download</mat-icon>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> -->
    <!-- </div> -->
  </nz-card>
</nz-spin>