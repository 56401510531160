import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { MessageDialogComponent } from '../../../shared/components/message-dialog/message-dialog.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { Location } from '@angular/common';
@Component({
  selector: 'app-asset-types',
  templateUrl: './asset-types.component.html',
  styleUrls: ['./asset-types.component.scss']
})
export class AssetTypesComponent implements OnInit {
  categories: any;
  catgryType: any;
  flag: boolean = false;

  constructor(private fb: FormBuilder, private themeService: ThemeConstantService, public apiSrv: AppsService, public atmSrv: AtomServiceService, private message: NzMessageService, private modal: NzModalService, private location: Location, private nzMessageService: NzMessageService) { }
  shwschvlymDelDiv
  shwschvlymEditDiv
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  columnDefs
  mainMessage
  loader
  gridData
  shwSidebar
  sidebarHeading
  typeForm
  shwLdng
  editdata
  delData
  delete: boolean = false;
  ngOnInit() {
    this.typeForm = new FormGroup({
      ast_typ_nm: new FormControl(''),
      dscn_tx: new FormControl(''),
      ast_ctgry_id: new FormControl(''),
      
    });
    this.getGridData();
    this.getCategories()
  }
  getCategoryType(e) {
    console.log("xxxxxxxxxxxxxxxxx")
    console.log(e)
    this.flag = false
    this.categories.filter((c) => {
      if (c.wrk_ast_ctgry_id == e) {
        this.catgryType = c.ast_type_lbl_tx
        this.flag = true;
      }

    }

    )

  }
  getGridData() {
    // console.log('grid data called')
    this.mainMessage = null;
    this.loader = true;
    this.usrAcsErrMsg = false;
    this.apiSrv.get('asset/tracking/types').subscribe((res) => {
      console.log(res)
      this.loader = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          // console.log(res['data'])
          // this.user.permissions = (res['perm'] === undefined) ? this.user.permissions : res['perm'][0];
          if (res['data'].length === 0) { this.mainMessage = 'No entries found in the database.'; }

          this.gridData = res['data'];
          console.log(this.gridData,"kalyaniiiiiiiiiiiiiiii")

          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 60, sortable: true, filter: false },
            { headerName: 'Type Name', field: 'ast_typ_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Description', field: 'dscn_tx', alignment: 'left', cellClass: 'pm-grid-number-cell', ortable: true, filter: true },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true }
          ];
          // else if (res['status'] === 404) {
          //   let permTxt = 'Desginations Creation';
          //   let prmeRte = `user/permissions/${permTxt}`;
          //   this.crdsrv.get(prmeRte).subscribe((prmres) => {
          //   // console.log(prmres['data']);

          //   });
          //   // this.user.permissions = { 'slct_in': 0, 'insrt_in': 0, 'updt_in': 0, 'dlte_in': 0, 'exprt_in': 0 };
          // }
        } else {
          this.usrAcsErrMsg = true;
        }
      } else {

        //   this.toastr.error( res["message"], '', {
        //    timeOut: 10000,
        //    positionClass: 'toast-top-right',
        //  });
        this.message.error(res["message"], {
          nzDuration: 2000
        });
      }
    }, (err) => {
      this.loader = false;
    });
  }
  getCategories() {
    this.shwLdng = true
    this.apiSrv.get(`asset/tracking/categories`).subscribe((res) => {
      this.shwLdng = false;
      if (res['status'] == 200) {
        this.categories = res['data']
        console.log(this.categories);
      } else {
        // this.toastr.error(res['message'], '', {
        //   timeOut: 3000
        // });
        this.message.error(res["message"], {
          nzDuration: 2000
        });
      }
    }, (err) => {
      this.shwLdng = false;
      // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
      //   timeOut: 3000
      // });
      this.message.error('Something Went Wrong. Please contact the administrator', {
        nzDuration: 2000
      });
    })

  }
  onToolbarPreparing(e) {
    // console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Type',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(opt, data) {
    this.shwSidebar = true;
    this.typeForm.value.ast_typ_nm = '';
    this.typeForm.value.dscn_tx = '';
    if (opt == 'edit') {
      this.shwschvlymEditDiv = true;
      this.shwschvlymDelDiv = false;
      this.sidebarHeading = 'Edit Type';
      this.typeForm.get('ast_typ_nm').setValue(data.ast_typ_nm)
      this.typeForm.get('dscn_tx').setValue(data.dscn_tx)
      this.typeForm.get('ast_ctgry_id').setValue(data.wrk_ast_ctgry_id)
      this.getCategoryType(data.wrk_ast_ctgry_id);
    }
    else if (opt == 'delete') {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = true;
      this.sidebarHeading = 'Delete Type';
    }
    else {
      this.shwschvlymEditDiv = false
      this.shwschvlymDelDiv = false
      this.sidebarHeading = 'Add New type';
      this.delete = false

    }
  }
  closeSideBar(): void {
    this.shwSidebar = false;
    this.flag = false;
    this.catgryType = ""
  }
  goBack() {
    this.location.back();
  }
  saveDeletetype() {
    this.shwLdng = true
    this.apiSrv.delete(`asset/tracking/type/${this.delData.ast_typ_id}`).subscribe((res) => {
      this.shwLdng = false;
      if (res['status'] == 200) {
        // this.toastr.success('Type Deleted Successfully', '', {
        //   timeOut: 3000,
        // });
        this.message.success('Type Deleted Successfully', {
          nzDuration: 2000
        });
        this.cancel()
      } else {
        // this.toastr.error(res['message'], '', {
        //   timeOut: 3000
        // });
        this.message.error(res['message'], {
          nzDuration: 2000
        });
      }
    }, (err) => {
      this.shwLdng = false;
      // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
      //   timeOut: 3000
      // });
      this.message.error('Something Went Wrong. Please contact the administrator', {
        nzDuration: 2000
      });
    })
  }
  saveEdittype() {
    let data = {
      ast_typ_nm: this.typeForm.value.ast_typ_nm,
      dscn_tx: this.typeForm.value.dscn_tx,
      ast_ctgry_id: this.typeForm.value.ast_ctgry_id,
      
    }
    console.log(this.editdata)
    console.log(data)
    if (data.ast_typ_nm != "" && data.dscn_tx != "") {
      this.shwLdng = true
      this.apiSrv.update(data, `asset/tracking/type/${this.editdata.ast_typ_id}`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Type Updated Successfully', '', {
          //   timeOut: 3000,
          // });
          this.message.success('Type Updated Successfully', {
            nzDuration: 2000
          });
          this.cancel()
        } else {
          // this.toastr.error(res['message'], '', {
          //   timeOut: 3000
          // });
          this.message.error(res['message'], {
            nzDuration: 2000
          });
        }
      }, (err) => {
        this.shwLdng = false;
        // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
        //   timeOut: 3000
        // });
        this.message.error('Something Went Wrong. Please contact the administrator', {
          nzDuration: 2000
        });
      })
    }
    else {
      // this.toastr.warning('Please Enter Required Fields Correctly', '', {
      //   timeOut: 3000
      // });
      this.message.error('Please Enter Required Fields Correctly', {
        nzDuration: 2000
      });
    }
  }
  savetype() {
    console.log(this.typeForm.value)
    let data = {
      ast_typ_nm: this.typeForm.value.ast_typ_nm,
      dscn_tx: this.typeForm.value.dscn_tx,
      ast_ctgry_id: this.typeForm.value.ast_ctgry_id
    }
    console.log(data)
    if (data.ast_typ_nm != "" && data.dscn_tx != "") {
      this.shwLdng = true
      this.apiSrv.create(data, `asset/tracking/type`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Type Created Successfully', '', {
          //   timeOut: 3000,
          // });
          this.message.success('Type Created Successfully', {
            nzDuration: 2000
          });
          this.cancel()
        } else {
          this.message.error(res['message'], {
            nzDuration: 2000
          });
        }
      }, (err) => {
        this.shwLdng = false;
        this.message.error('Something Went Wrong. Please contact the administrator', {
          nzDuration: 2000
        });
      })
    }
    else {
      this.message.error('Please Enter Required Fields Correctly', {
        nzDuration: 2000
      });
    }
    console.log(data)
  }
  onDelete2(event) {
    console.log(event)
    this.delete = true;
    this.delData = event.data
    this.openSideBar("delete", this.delData);
    this.getCategoryType(event.data.ast_ctgry_id);
  }
  onEdit2(event) {
    this.delete = false
    this.editdata = event.data;
    this.openSideBar("edit", this.editdata);
  }
  cancel() {
    this.typeForm.reset()
    this.shwSidebar = false;
    this.flag = false;
    this.catgryType = ""
    this.getGridData();
  }
  oncancel(): void {
    this.nzMessageService.info('Type Not Deleted');
  }
}
