import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(private swPush: SwPush) {}

  requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Push notifications enabled');
        } else if (permission === 'denied') {
          console.warn('Push notifications denied by the user');
        } else {
          console.log('Push notification permission prompt dismissed');
        }
      });
    } else {
      console.warn('Push notifications are not supported in this browser');
    }
  }

  showNotification(title: string, body: string): Notification | void {
    if ('Notification' in window) {
      console.log('Notification API is supported.');
      if (Notification.permission === 'granted') {
        console.log('Notification permission granted. Creating notification...');
        const options = {
          body,
          // Uncomment and provide a valid icon path if needed
          // icon: '/assets/icons/icon-96x96.png',
        };
        return new Notification(title, options);
      } else if (Notification.permission === 'default') {
        console.warn('Notification permission is not granted. Requesting...');
        this.requestPermission();
      } else {
        console.error('Notification permission denied');
      }
    } else {
      console.warn('Push notifications are not supported in this browser');
    }
  }
}
