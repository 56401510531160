import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';

// NG-ZORRO Modules
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';

import {
  DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule, DxNumberBoxModule,
  DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule, DxChartModule, DxPieChartModule,
  DxTabsModule, DxLoadPanelModule, DxDiagramModule,
} from 'devextreme-angular';

import { ComplaintManagementRoutingModule } from './complaint-management-routing.module';
import { ComplaintComponent } from './complaint/complaint.component';
import { ComplaintFormComponent } from './complaint/complaint-form/complaint-form.component';
import { ComplaintTicketsListComponent } from './complaint/complaint-tickets-list/complaint-tickets-list.component';
import { TicketDetailsComponent } from './complaint/ticket-details/ticket-details.component';
import { ComplaintDetailsComponent } from './complaint/complaint-details/complaint-details.component';
import { ComplaintFilterModalComponent } from './complaint/complaint-tickets-list/complaint-filter-modal/complaint-filter-modal.component';

@NgModule({
  declarations: [
    ComplaintComponent,
    ComplaintFormComponent,
    ComplaintTicketsListComponent,
    TicketDetailsComponent,
    ComplaintDetailsComponent,ComplaintFilterModalComponent
  ],
  imports: [
    CommonModule,
    ComplaintManagementRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgChartsModule,
    NzCheckboxModule,
    NzButtonModule,
    NzCardModule,
    NzDatePickerModule,
    NzTabsModule,
    NzMessageModule,
    NzListModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzIconModule,
    NzSelectModule,
    NzTimePickerModule,
    NzModalModule,
    NzDividerModule,
    NzBadgeModule,
    NzDropDownModule,
    NzPopoverModule,
    NzToolTipModule,
    NzTableModule,
    NzRadioModule,
    NzLayoutModule,
    NzDrawerModule,
    NzMenuModule,
    NzSpaceModule,
    NzUploadModule,
    NzGridModule,
    NzAvatarModule,
    NzTimelineModule,
    NzPageHeaderModule,
    NzBreadCrumbModule,
    NzTagModule,
    NzSpinModule,
    NzDescriptionsModule,
    NzCommentModule,
    NzTypographyModule,
    NzPaginationModule,
    DxDataGridModule,
    DxBulletModule,
    DxTemplateModule,
    DxButtonModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTabPanelModule,
    DxChartModule,
    DxPieChartModule,
    DxTabsModule,
    DxLoadPanelModule,
    DxDiagramModule
  ],
  exports: [
    ComplaintComponent,
    ComplaintFormComponent,
    ComplaintTicketsListComponent,
    TicketDetailsComponent,
    ComplaintDetailsComponent
  ]
})
export class ComplaintManagementModule { }
