<div class="top-header">
    <h1 class="page-title">Create Purchase Order</h1>
    <div class="header-right">
        <!-- <span>Back to Purchase Orders</span> -->
        <button nz-button nzType="default" class="go-back-btn" (click)="goBack()">
            <span nz-icon nzType="arrow-left"></span>
            Go Back
        </button>
    </div>
</div>


<div class="container">

    <!-- Purchase Form -->
    <form nz-form [formGroup]="purchaseForm" (ngSubmit)="onSubmit()" class="purchase-form" nzLayout="vertical">

        <div class="sctin">
            <div class="ribbon lgnd">Basic Information</div>
            <div nz-row [nzGutter]="24">

                <div nz-col [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label nzRequired>Client Company</nz-form-label>
                        <nz-form-control nzErrorTip="Please select Company">
                            <nz-select formControlName="company" nzPlaceHolder="Select Company">
                                <nz-option *ngFor="let company of companyList" [nzValue]="company.value"
                                    [nzLabel]="company.label">
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label nzRequired>Vendor Company</nz-form-label>
                        <nz-form-control nzErrorTip="Please select Vendor Company">
                            <nz-select formControlName="vendorCompany" nzPlaceHolder="Select Vendor Company">
                                <nz-option *ngFor="let vendor of vendorList" [nzValue]="vendor.value"
                                    [nzLabel]="vendor.label">
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label nzRequired>Vendor Location</nz-form-label>
                        <nz-form-control nzErrorTip="Please enter Vendor Location">
                            <input nz-input formControlName="vendorLocation" placeholder="Enter Vendor Location" />
                        </nz-form-control>
                    </nz-form-item>
                </div>

            </div>

            <div nz-row [nzGutter]="24">

                <div nz-col [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label nzRequired>Purchase Date</nz-form-label>
                        <nz-form-control nzErrorTip="Please select Purchase Date">
                            <nz-date-picker formControlName="purchaseDate"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label nzRequired>poNo</nz-form-label>
                        <nz-form-control nzErrorTip="Please enter Vendor poNo">
                            <input nz-input formControlName="poNo" placeholder="Enter Vendor poNo" />
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label nzRequired>Billing Address</nz-form-label>
                        <nz-form-control nzErrorTip="Please enter Billing Address">
                            <textarea nz-input [formControlName]="'billingAddress'"
                                [nzAutosize]="{ minRows: 2, maxRows: 3 }"
                                placeholder="Enter Billing Address"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="12">
                    <nz-form-item>
                        <nz-form-label nzRequired>Delivery Address</nz-form-label>
                        <nz-form-control nzErrorTip="Please enter Delivery Address">
                            <textarea nz-input [formControlName]="'deliveryAddress'"
                                [nzAutosize]="{ minRows: 2, maxRows: 3 }"
                                placeholder="Enter Delivery Address"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div class="form-group">
                <label>Attachments :</label>
                <nz-upload [nzAction]="''" [nzBeforeUpload]="beforeUpload" [nzMultiple]="true"
                    [nzAccept]="'.pdf,.jpg,.jpeg,.png'">
                    <button nz-button class="upload-btn">
                        <span nz-icon nzType="upload"></span>
                        Upload Files
                    </button>
                </nz-upload>

                <div class="attachments-preview" *ngIf="uploadedFiles.length > 0">
                    <div class="preview-grid">
                        <div class="preview-item" *ngFor="let file of uploadedFiles; let i = index">
                           
                            <div class="preview-content" *ngIf="isImage(file)">
                                <img [src]="getPreviewUrl(file)" [alt]="file.name">
                                <button nz-button nzType="text" nzDanger class="remove-btn" (click)="removeFile(i)">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </div>
                            <div class="preview-content pdf-preview" *ngIf="isPdf(file)">
                                <span nz-icon nzType="file-pdf" nzTheme="fill"></span>
                                <span class="file-name">{{ file.name }}</span>
                                <button nz-button nzType="text" nzDanger class="remove-btn" (click)="removeFile(i)">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="dynamic-section" formArrayName="items">
            <div *ngFor="let item of items.controls; let i=index" [formGroupName]="i" class="item-section">
                <div class="sctin">
                    <div class="ribbon lgnd">Branch {{i + 1}}</div>

                    <div class="item-header">
                        <button *ngIf="i > 0" nz-button nzType="text" nzDanger (click)="removeItem(i)">
                            <span nz-icon nzType="delete"></span>
                            Remove
                        </button>
                    </div>

                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>Branch</nz-form-label>
                                <nz-form-control nzErrorTip="Please select Branch">
                                    <nz-select formControlName="branch" nzPlaceHolder="Select Branch">
                                        <nz-option *ngFor="let branch of branchList" [nzValue]="branch.value"
                                            [nzLabel]="branch.label">
                                        </nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSpan]="16">
                            <nz-form-item>
                                <nz-form-label nzRequired>Delivery Address</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter Delivery Address">
                                    <textarea nz-input [formControlName]="'deliveryAddress'"
                                        [nzAutosize]="{ minRows: 2, maxRows: 3 }"
                                        placeholder="Enter Delivery Address"></textarea>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>

                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>Category</nz-form-label>
                                <nz-form-control nzErrorTip="Please select Category">
                                    <nz-select formControlName="category" nzPlaceHolder="Select Category">
                                        <nz-option *ngFor="let category of categoryList" [nzValue]="category.value"
                                            [nzLabel]="category.label">
                                        </nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>Inventory Name</nz-form-label>
                                <nz-form-control nzErrorTip="Please select Inventory Name">
                                    <nz-select formControlName="inventoryName" nzPlaceHolder="Select Inventory Name">
                                        <nz-option *ngFor="let inventory of inventoryList" [nzValue]="inventory.value"
                                            [nzLabel]="inventory.label">
                                        </nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>Quantity</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter Quantity">
                                    <input nz-input formControlName="quantity" type="number" [nzMin]="1" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>

                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>Make</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter Make">
                                    <input nz-input formControlName="make" placeholder="Enter Make" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>Model</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter Model">
                                    <input nz-input formControlName="model" placeholder="Enter Model" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>Single PC Price</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter Single PC Price">
                                    <input nz-input formControlName="singlePcPrice" type="number" [nzMin]="0" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>

                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>Discount</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter Discount">
                                    <input nz-input formControlName="discount" [nzMin]="0" type="number"
                                        placeholder="Enter Discount" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>GST Included</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter GST Included">
                                    <nz-select formControlName="gstIncluded" nzPlaceHolder="Select GST Included">
                                        <nz-option nzValue="yes" nzLabel="Yes"></nz-option>
                                        <nz-option nzValue="no" nzLabel="No"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>GST In (%)</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter gstIn">
                                    <input nz-input formControlName="gstIn" type="number" [nzMin]="0" [nzMax]="100" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>

                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="8">
                            <nz-form-item>
                                <nz-form-label nzRequired>Total Price</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter Total Price">
                                    <input nz-input formControlName="totalPrice" [nzMin]="0" type="number"
                                        [nzDisabled]="true" placeholder="Enter Total Price" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSpan]="16">
                            <nz-form-item>
                                <nz-form-label nzRequired>Remarks</nz-form-label>
                                <nz-form-control nzErrorTip="Please enter Remarks">
                                    <textarea nz-input [formControlName]="remarks"
                                        [nzAutosize]="{ minRows: 2, maxRows: 3 }"
                                        placeholder="Enter Remarks"></textarea>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="add-btn-sec">
            <button nz-button class="add-more-btn" (click)="addItem()">
                <span nz-icon nzType="plus"></span>
                Add Branch
            </button>
        </div>

        <!-- Form Actions -->
        <div class="form-actions">
            <button nz-button nzType="default" (click)="cancel()">Cancel</button>
            <button nz-button nzType="primary" type="submit">Create Purchase Order</button>
        </div>
    </form>
</div>