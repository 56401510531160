import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit, OnChanges {
  @Input() ticket: any;
  ticketDtls: any;
  loading = false;
  commentText = '';

  ticketUpdates = [
    { time: '2024-12-01 10:00 AM', event: 'Ticket Created', type: 'created' },
    { time: '2024-12-02 12:00 PM', event: 'Status Changed to In Progress', type: 'status' },
    { time: '2024-12-03 02:00 PM', event: 'Assigned to Support Team', type: 'assigned' },
    { time: '2024-12-04 04:00 PM', event: 'Resolved', type: 'resolved' }
  ];

  constructor(
    private apiSrvc: AppsService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    if (this.ticket && this.ticket.length > 0) {
      this.getticketdetails(this.ticket[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
   console.log('Ticket details changed:', this.ticket);
      this.getticketdetails(this.ticket);
  

  }

  getticketdetails(tkt) {
    this.loading = true;
    console.log('Fetching details for ticket ID:', tkt.tckt_id);
    
    this.apiSrvc.get('complaint/ticket/' + tkt.tckt_id).subscribe(
      (res: any) => {
        this.loading = false;
        if (res.status === 200) {
          this.ticketDtls = res.data;
          console.log('Fetched ticket detailsssssssssss:', this.ticketDtls);
        } else {
          this.message.error(res.message || 'Error fetching ticket details');
        }
      },
      (error) => {
        this.loading = false;
        this.message.error('Failed to fetch ticket details');
        console.error('API error:', error);
      }
    );
  }

  submitComment(): void {
    if (!this.commentText.trim()) {
      this.message.warning('Please enter a comment');
      return;
    }

    // Add your comment submission logic here
    this.message.success('Comment submitted successfully');
    this.commentText = '';
  }

  getPriorityColor(priority: string): string {
    switch (priority?.toLowerCase()) {
      case 'high':
        return 'red';
      case 'medium':
        return 'orange';
      case 'low':
        return 'green';
      default:
        return 'blue';
    }
  }

  getStatusColor(status: string): string {
    switch (status?.toLowerCase()) {
      case 'open':
        return 'blue';
      case 'in progress':
        return 'processing';
      case 'resolved':
        return 'success';
      case 'closed':
        return 'default';
      default:
        return 'default';
    }
  }
}