import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { MessageDialogComponent } from '../../../shared/components/message-dialog/message-dialog.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { Location } from '@angular/common';
@Component({
  selector: 'app-asset-categories',
  templateUrl: './asset-categories.component.html',
  styleUrls: ['./asset-categories.component.scss']
})
export class AssetCategoriesComponent implements OnInit {

  constructor( private fb: FormBuilder,private themeService: ThemeConstantService, public apiSrv: AppsService, public atmSrv: AtomServiceService, private message: NzMessageService, private modal: NzModalService, private location: Location,private nzMessageService: NzMessageService) { }
  shwschvlymDelDiv
  shwschvlymEditDiv
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  columnDefs
  mainMessage
  loader
  gridData
  shwSidebar
  sidebarHeading
  categoryForm
  shwLdng
  editdata
  delData
  delete :boolean =false;
  ngOnInit() {
    this.categoryForm = new FormGroup({
      ast_ctgry_nm: new FormControl(''),
      dscn_tx: new FormControl(''),
      ast_grp_lbl_tx: new FormControl(''),
      ast_type_lbl_tx: new FormControl(''),
      ast_cd_lbl_tx: new FormControl(''),
      vndr_ast_cd_lble_tx: new FormControl('')
    });
    this.getGridData();
  }
  getGridData()
  {
    // console.log('grid data called')
    this.mainMessage = null;
    this.loader = true;
    this.usrAcsErrMsg = false;
    this.apiSrv.get('asset/tracking/categories').subscribe((res) => {
      console.log(res)
      this.loader = false;
      if (res['status'] == 404) {
        this.usrAcsErrMsg = true;
      } else if (res['status'] == 200) {
        this.USER_PERMISSIONS = res['perm'][0];
        if (this.USER_PERMISSIONS.slct_in === 1) {
          // console.log(res['data'])
          // this.user.permissions = (res['perm'] === undefined) ? this.user.permissions : res['perm'][0];
          if (res['data'].length === 0) { this.mainMessage = 'No entries found in the database.'; }

          this.gridData = res['data'];
          // console.log(this.gridData)

          this.columnDefs = [
            { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 60, sortable: true, filter: false },
            { headerName: 'Category Name', field: 'wrk_ast_ctgry_nm', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Group Name', field: 'ast_grp_lbl_tx', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Type Name', field: 'ast_type_lbl_tx', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Asset Code Name', field: 'ast_cd_lbl_tx', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Vendor Asset Code Name', field: 'vndr_ast_cd_lble_tx', alignment: 'left', cellClass: 'pm-grid-number-cell' },
            { headerName: 'Description', field: 'dscn_tx', alignment: 'left', cellClass: 'pm-grid-number-cell', ortable: true, filter: true },
            { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Created By', field: 'crte_usr', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
            { headerName: 'Updated By', field: 'updte_usr', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true }
          ];
          // else if (res['status'] === 404) {
          //   let permTxt = 'Desginations Creation';
          //   let prmeRte = `user/permissions/${permTxt}`;
          //   this.crdsrv.get(prmeRte).subscribe((prmres) => {
          //   // console.log(prmres['data']);

          //   });
          //   // this.user.permissions = { 'slct_in': 0, 'insrt_in': 0, 'updt_in': 0, 'dlte_in': 0, 'exprt_in': 0 };
          // }
        } else {
          this.usrAcsErrMsg = true;
        }
      }else {
       
      //   this.toastr.error( res["message"], '', {
      //    timeOut: 10000,
      //    positionClass: 'toast-top-right',
      //  });
      this.message.error(res["message"], {
        nzDuration: 2000
      });
     }
    }, (err) => {
      this.loader = false;
    });
  }
  onToolbarPreparing(e) {
    // console.log(e);
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Category',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar(opt, data)
  {
    console.log(data , 'dataa')
    this.shwSidebar = true;
    if(opt == 'edit')
    {
      this.shwschvlymEditDiv = true;
      this.shwschvlymDelDiv = false;
      this.sidebarHeading = 'Edit Category';
      this.categoryForm.get('ast_ctgry_nm').setValue(data.wrk_ast_ctgry_nm)
      this.categoryForm.get('dscn_tx').setValue(data.dscn_tx)
      this.categoryForm.get('ast_grp_lbl_tx').setValue(data.ast_grp_lbl_tx)
      this.categoryForm.get('ast_type_lbl_tx').setValue(data.ast_type_lbl_tx)
      this.categoryForm.get('ast_cd_lbl_tx').setValue(data.ast_cd_lbl_tx)
      this.categoryForm.get('vndr_ast_cd_lble_tx').setValue(data.vndr_ast_cd_lble_tx)
    }
    else if(opt == 'delete')
    {
      this.shwschvlymEditDiv = false;
      this.shwschvlymDelDiv = true;
      this.sidebarHeading = 'Delete Category';
    }
    else
    {
      this.shwschvlymEditDiv=false
      this.shwschvlymDelDiv=false
      this.sidebarHeading = 'Add New Category';
    }
  }
  closeSideBar():void
  {
    this.shwSidebar = false;
    this.delete = true;
  }
  goBack()
  {
    this.location.back();
  }
  saveDeletecategory(){
    console.log(this.delData)
    this.shwLdng=true
    this.apiSrv.delete(`asset/tracking/category/${this.delData.wrk_ast_ctgry_id}`).subscribe((res) => {
      this.shwLdng = false;
      if (res['status'] == 200) {
        // this.toastr.success('Category Deleted Successfully', '', {
        //   timeOut: 3000,
        // });
        this.message.success('Category Deleted Successfully', {
          nzDuration: 2000
        });
        this.cancel()
      } else {
        // this.toastr.error(res['message'], '', {
        //   timeOut: 3000
        // });
        this.message.error(res["message"], {
          nzDuration: 2000
        });
      }
    }, (err) => {
      this.shwLdng = false;
      // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
      //   timeOut: 3000
      // });
      this.message.error('Something Went Wrong. Please contact the administrator', {
        nzDuration: 2000
      });
    })
  }
  saveEditcategory()
  {
    let data={
      ast_ctgry_nm:this.categoryForm.value.ast_ctgry_nm,
      dscn_tx:this.categoryForm.value.dscn_tx,
      ast_grp_lbl_tx: this.categoryForm.value.ast_grp_lbl_tx,
      ast_type_lbl_tx: this.categoryForm.value.ast_type_lbl_tx,
      ast_cd_lbl_tx: this.categoryForm.value.ast_cd_lbl_tx,
      vndr_ast_cd_lble_tx:this.categoryForm.value.vndr_ast_cd_lble_tx
    }
    console.log(this.editdata)
    console.log(data)
    if((data.ast_ctgry_nm!=undefined ||  data.ast_ctgry_nm !="")  && (data.dscn_tx!=undefined || data.dscn_tx !=""))
    {
      this.shwLdng=true
      this.apiSrv.update(data, `asset/tracking/category/${this.editdata.wrk_ast_ctgry_id}`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Category Updated Successfully', '', {
          //   timeOut: 3000,
          // });
          this.message.success('Category Updated Successfully', {
            nzDuration: 2000
          });
          this.cancel()
        } else {
          // this.toastr.error(res['message'], '', {
          //   timeOut: 3000
          // });
          this.message.error(res["message"], {
            nzDuration: 2000
          });
        }
      }, (err) => {
        this.shwLdng = false;
        // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
        //   timeOut: 3000
        // });
        this.message.error('Something Went Wrong. Please contact the administrator', {
          nzDuration: 2000
        });
      })
    }
    else
    {
      // this.toastr.warning('Please Enter Required Fields Correctly', '', {
      //   timeOut: 3000
      // });
      this.message.error('Please Enter Required Fields Correctly', {
        nzDuration: 2000
      });
    }
  }
  savecategory()
  {
    console.log(this.categoryForm.value)
    let data={
      ast_ctgry_nm:this.categoryForm.value.ast_ctgry_nm,
      dscn_tx:this.categoryForm.value.dscn_tx,
      ast_grp_lbl_tx: this.categoryForm.value.ast_grp_lbl_tx,
      ast_type_lbl_tx: this.categoryForm.value.ast_type_lbl_tx,
      ast_cd_lbl_tx: this.categoryForm.value.ast_cd_lbl_tx,
      vndr_ast_cd_lble_tx:this.categoryForm.value.vndr_ast_cd_lble_tx
    }
    console.log(data)
    if(data.ast_ctgry_nm !=""  && data.dscn_tx !="")
    {
      this.shwLdng=true
      this.apiSrv.create(data, `asset/tracking/category`).subscribe((res) => {
        this.shwLdng = false;
        if (res['status'] == 200) {
          // this.toastr.success('Category Created Successfully', '', {
          //   timeOut: 3000,
          // });
          this.message.success('Category Created Successfully', {
            nzDuration: 2000
          });
          this.cancel()
        } else {
          // this.toastr.error(res['message'], '', {
          //   timeOut: 3000
          // });
          this.message.error(res["message"], {
            nzDuration: 2000
          });
        }
      }, (err) => {
        this.shwLdng = false;
        // this.toastr.error('Something Went Wrong. Please contact the administrator', '', {
        //   timeOut: 3000
        // });
        this.message.error('Something Went Wrong. Please contact the administrator', {
          nzDuration: 2000
        });
      })
    }
    else
    {
      // this.toastr.warning('Please Enter Required Fields Correctly', '', {
      //   timeOut: 3000
      // });
      this.message.error('Please Enter Required Fields Correctly', {
        nzDuration: 2000
      });
    }
    console.log(data)
  }
  onDelete2(event)
  {
    this.delete = true;
    this.delData=event.data
    this.openSideBar("delete", this.delData);
  }
  onEdit2(event)
  {
    this.delete=false
    this.editdata = event.data;
    this.openSideBar("edit", this.editdata);
  }
  cancel()
  {
    this.categoryForm.ast_ctgry_nm='';
    this.categoryForm.dscn_tx='';
    this.shwSidebar=false;
    this.delete = false;
    this.categoryForm.reset();
    this.getGridData();
  }
  oncancel(): void {
    this.nzMessageService.info('Category Not Deleted');
  }
}
