<div class="page-header">
    <div class=" align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-4 col-md-4">
                <h1>
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
            <!-- <div class="col-lg-8 col-md-8 mg-a">
                <span class="slctDteLbl">Select District:</span>

                <nz-select class="slctBx" style="width: 200px;    text-align: left;" nzShowSearch
                    [(ngModel)]="slctd_dstrt" (ngModelChange)="getDstrwiseData($event)"
                    [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select a district">
                    <nz-option *ngIf="slctd_dstrt == 0" [nzValue]="0" nzLabel="All"></nz-option>
                    <nz-option *ngFor="let v of dstrtdata" [nzValue]="v.tnt_id" [nzLabel]="v.tnt_nm"></nz-option>
                </nz-select>
            </div> -->
        </div>
    </div>
</div>

<div class="apsfl_dshbrd">
    <div class="row">
        <div class="col-md-3" *ngFor="let g of ComplaintTypeCardsData; let i= index;">
            <div class="crd_style" [ngClass]=" g.ast_typ_id == slectdCrd ? 'bg-css' : 'crd_style' "
                (click)="selctedcard(g,i)">
                <div class="row">

                    <div class="col-md-8 cstmcol">
                        <div class="actvts" [ngStyle]="{'background': g.crd_clr}">{{g.wrk_grp_nm}} <span
                                style="color: #030303;">({{g.ttl}})</span></div>
                    </div>


                </div>
                <div class="row rwpdng">

                    <div class="col-md-6">
                        <div class="cnt">{{g.pending}}
                        </div>
                        <div class="crd_title"> <span class="cstmfnt" style="color: #f19000;">Open</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="cnt">{{g.completed}}
                        </div>
                        <div class="crd_title"> <span class="cstmfnt" style="    color: #4CAF50;"> Close</span>
                        </div>
                    </div>
                </div>
                <span nz-icon nzType="caret-down" nzTheme="outline" style="    position: absolute;
            left: 50%;
            font-size: 30px;    color: #544795;" *ngIf="slectdCrd == g.ast_typ_id"></span>
            </div>

        </div>
    </div>

    <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;">
        <div class="col-lg-12 col-md-12 mgnb-2 p-8 ">
            <div class="card_ttl">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card_hdr_ttl" style="position: relative;">
                            {{slectdCrd_nm}}</div>

                    </div>
                </div>
            </div>
            <dx-data-grid class="p-8" id="gridContainer" [dataSource]="ComplaintGridData" [remoteOperations]="true"
                [allowColumnReordering]="true" [allowColumnResizing]="true" [showColumnLines]="true"
                [showRowLines]="true" [focusedRowEnabled]="true" [showBorders]="true" style="width:100%" keyExpr="sno">

                <dxo-paging [pageSize]="25"></dxo-paging>
                <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 25, 50]"
                    [showInfo]="true">
                </dxo-pager>

                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
                <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                <dxo-export [enabled]="true" fileName=""></dxo-export>

                <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [visible]="d.visible"
                    [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true"
                    [groupIndex]="d.groupIndex" [groupCellTemplate]="groupCellTemplate"  [cellTemplate]="d.cellTemplate">
                </dxi-column>
                
                <dxi-column [showInColumnChooser]="false" type="buttons" [width]="100" caption="View"
                cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="tblEdtBtn" (click)="gotodetails(data)">
                        <i nz-icon nzType="eye" nzTheme="outline"></i>
                        <p>View details</p>
                    </div>
                </div>
            </dxi-column>
            </dx-data-grid>

        </div>

    </div>



    <nz-drawer  [nzMaskClosable]="false" [nzWidth]="800" [nzVisible]="sidenav"
        nzTitle="Complaint Details" (nzOnClose)="closeSideBar()" nzWrapClassName="wrkPrfleDrawer">
        <ng-container *nzDrawerContent>
            <app-complaint-details 
            [selectedTicket]="selectedTicket"
           >
          </app-complaint-details>   

        </ng-container>
    </nz-drawer>
    



</div>