import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Location } from '@angular/common';

@Component({
  selector: 'app-vendor-status',
  templateUrl: './vendor-status.component.html',
  styleUrls: ['./vendor-status.component.scss']
})
export class VendorStatusComponent implements OnInit {
  shwDeleteCnfrm: boolean;
  constructor(
    private fb: FormBuilder,
    private themeService: ThemeConstantService,
    public apiSrv: AppsService,
    public atmSrv: AtomServiceService,
    private message: NzMessageService,
    private modal: NzModalService,
    private location: Location,
    private nzMessageService: NzMessageService
  ) { }

  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  columnDefs;
  mainMessage;
  loader;
  gridData;
  shwSidebar;
  sidebarHeading;
  vendorStatusForm: FormGroup;
  shwLdng;
  editdata;
  delData;
  delete: boolean = false;

  ngOnInit() {
    this.vendorStatusForm = new FormGroup({
      cmpny_sts_nm: new FormControl('')
    });
    this.getGridData();
  }

  getGridData() {
    this.mainMessage = null;
    this.loader = true;

    // this.apiSrv.callStoredProcedureV2(params).subscribe((res: any) => {
    this.apiSrv.get('/core/api/master/vendor-status/list').subscribe((res) => {

      this.loader = false;
      if (res['status'] == 200) {

        this.gridData = res['data'];
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add Vendor Status',
        onClick: this.addNew.bind(this)
      }
    });
  }

  addNew() {
    this.editdata = null;
    this.shwSidebar = true;
    this.shwDeleteCnfrm = false;

    this.sidebarHeading = "Add Vendor Status";
    this.vendorStatusForm.reset();
  }

  editRecord(data) {
    this.editdata = data;
    this.shwSidebar = true;
    this.shwDeleteCnfrm = false;

    this.sidebarHeading = "Edit Vendor Status";
    this.vendorStatusForm.patchValue({
      cmpny_sts_nm: data.cmpny_sts_nm
    });
  }

  deleteRecord(data) {
    this.delData = data;
    this.shwDeleteCnfrm = true;
  }

  closeSidebar() {
    this.shwSidebar = false;
  }

  submitForm() {
    if (this.vendorStatusForm.valid) {
      this.shwLdng = true;


      if (this.editdata) {

        this.apiSrv.put(this.vendorStatusForm.value, `core/api/master/vendor-status/${this.editdata.cmpny_sts_id}`).subscribe((res) => {


          this.shwLdng = false;

          if (res['status'] == 200) {


            this.message.success(res['message']);
            this.shwSidebar = false;
            this.getGridData();
          }
        });
      }
      else {

        this.apiSrv.post(this.vendorStatusForm.value, 'core/api/master/vendor-status').subscribe((res) => {
          this.shwLdng = false;
          if (res['status'] == 200) {
            this.message.success(res['message']);
            this.shwSidebar = false;
            this.getGridData();
          }
        });



      }
    }



    else {
      Object.values(this.vendorStatusForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  confirmDelete() {
    this.shwLdng = true;

    // this.apiSrv.callStoredProcedureV2(params).subscribe((res: any) => {
    this.apiSrv.delete(`core/api/master/vendor-status/${this.delData.cmpny_sts_id}`).subscribe((res) => {

      this.shwLdng = false;
      if (res['status'] == 200) {
        this.message.success(res['message']);


        this.shwDeleteCnfrm = false;
        this.getGridData();
      }
    });
  }

  cancelDelete() {
    this.shwDeleteCnfrm = false;
  }
}
