import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';
import { AppsService } from 'src/app/shared/services/apps.service';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { Router } from '@angular/router';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
// dev ex export plugins 
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-state-level-dahboard',
  templateUrl: './state-level-dahboard.component.html',
  styleUrls: ['./state-level-dahboard.component.scss']
})
export class StateLevelDahboardComponent implements OnInit {
  slctdDt = new Date();
  today = new Date();
  dateFormat = 'dd/MM/yyyy';
  curDate;
  showLdr;
  clntDtls: any;
  userDtls: any;
  refresh_tm: string;
  liveId: NodeJS.Timer;
  tdyCoveredTrips: any = [];
  dbrd_ky;
  clap_in: any;
  ShowClap: boolean = false;
  ttlDstncCnt: any = [];
  ttlTrpsCnt: any = [];
  ulbTripsDta: any = [];
  columnDefs: any = [];
  ispagination: boolean = false;
  ttlColumnDefs: any = [];
  ulbVndrwseopratedTripsTble: any = [];
  ulbVndrwseopratedcolumnDefs: any = [];
  ispagination2: boolean;
  vndrttlColumnDefs: any[];
  dbrd_lvl = 0;
  fltrMd = 1;
  ulbTripsDupDta: any;
  rowselected: any;
  vehclesTble: any = [];
  vehclesTblecolumnDefs: any = [];
  vehttlColumnDefs: any[];
  ttl_vhcls;
  curYr: string;
  rowselected2: any;
  svmtdyCoveredTrips: any[];
  tblHdr: string;
  crdOne: boolean = true; crdTwo: boolean = false; crdThree: boolean = false; crdFour: boolean = false; crdFive: boolean = false; crdSix: boolean = false; crdSeven: boolean = false; crdEight: boolean = false;
  crdVal = 1;
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
  }

  ngOnInit(): void {
    this.curYr = moment(this.today).format('YYYY');
    this.dbrd_ky = 'state1';
    this.dbrd_lvl = 0;
    this.chckDbrdLvl();
    this.refresh();
    this.liveId = setInterval(() => {
      this.refresh();
    }, 15 * 60 * 1000);
  }
  chckDbrdLvl() {
    console.log(this.dbrd_lvl)
    if (this.dbrd_lvl == 0) {
      // this.onfltrSelect(0);
      this.get_ulbWsedta(this.slctdDt);
      this.getVehiclesOperatedcntsVndrULBwise(this.slctdDt);
      this.get_today_Ulb_CoveredTripsCount(this.slctdDt, 0);
      this.get_totalTripsCount(this.slctdDt, 0);
      this.get_totalDistanceCount(this.slctdDt, 0);
      this.get_lst7DaysDistanceDta(this.slctdDt, 0);
    } else if (this.dbrd_lvl == 1) {
      console.log('ulb dashboarddddddddddddddddddddddddddd')
      this.getVehicleswiseData(this.slctdDt, this.rowselected.tnt_nm);
      this.get_today_Ulb_CoveredTripsCount(this.slctdDt, this.rowselected.tnt_nm);
      this.get_totalTripsCount(this.slctdDt, this.rowselected.tnt_nm);
      this.get_totalDistanceCount(this.slctdDt, this.rowselected.tnt_nm);
      this.get_lst7DaysDistanceDta(this.slctdDt, this.rowselected.tnt_nm);

    } else if (this.dbrd_lvl == 2) {
      this.getVehicleswiseData(this.slctdDt, this.rowselected.tnt_id);
      this.getsvmCoveredTripsCount();
    }
  }
  refresh() {
    const date = new Date();

    this.refresh_tm = moment(date).format('DD-MM-YYYY HH:mm:ss');
    if (this.liveId) {
      clearInterval(this.liveId);

      this.liveId = setInterval(() => {
        this.refresh();
      }, 15 * 60 * 1000);

    } else {
      this.liveId = setInterval(() => {
        this.refresh();
      }, 15 * 60 * 1000);
    }
    if (this.dbrd_lvl == 0) {
      this.get_today_Ulb_CoveredTripsCount(this.slctdDt, 0);
      this.get_totalDistanceCount(this.slctdDt);
      this.get_totalTripsCount(this.slctdDt);
      this.get_ulbWsedta(this.slctdDt);
      this.getVehiclesOperatedcntsVndrULBwise(this.slctdDt);
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.get_today_Ulb_CoveredTripsCount(this.slctdDt, 0);
    this.get_totalDistanceCount(this.slctdDt);
    this.get_totalTripsCount(this.slctdDt);
    this.get_ulbWsedta(this.slctdDt);
    this.getVehiclesOperatedcntsVndrULBwise(this.slctdDt);
    this.get_lst7DaysDistanceDta(this.slctdDt, 0);
    if(this.dbrd_lvl > 0)
      this.get_lst7DaysDistanceDta(this.slctdDt, this.rowselected.tnt_nm);
    else{
      this.get_lst7DaysDistanceDta(this.slctdDt,0);
    }
  }
  get_today_Ulb_CoveredTripsCount(funDt?, slctTnt?): any {

    this.tdyCoveredTrips = [
      {
        urban_ttl: null,
        u_ttl_onl: null,
        u_operated: null,
        u_notoperated: null,

      }
    ];
    this.showLdr = true;

    this.slctdDt = funDt;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/get_today_CoveredTripsCount/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.tdyCoveredTrips = res['data'];
        this.showLdr = false;
      }
      console.log(this.tdyCoveredTrips, '-------tdyCoveredTrips---------')
    }, (err) => {
      // console.log(err);
    });
  }
  get_totalDistanceCount(funDt?, slctTnt?): any {
    this.ttlDstncCnt = [
      {
        urbn_tdydst_abv30: null,
        urbn_tdydst_blw30: null,
      }
    ];
    // this.showLdr = true;
    this.slctdDt = funDt;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    var postslctdDt = {
      dt: this.curDate,
      tnt_in: 0,
      slctd_tnt: slctTnt
    }
    const rte = `dashboard/enterprise/get_totalDistance/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.ttlDstncCnt = res['data'];
        console.log(this.ttlDstncCnt, '------this.ttlDstncCnt--------')
        // this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  get_totalTripsCount(funDt?, slctTnt?): any {
    this.ttlTrpsCnt = [
      {
        def_trps: null,
        tdy_trvl_trps: null,
        tdy_per: null
      }
    ];
    // this.showLdr = true;
    this.slctdDt = funDt;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      tnt_in: 0,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/get_totalTrips/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.ttlTrpsCnt = res['data'];
      }
      // this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  get_ulbWsedta(funDt?): any {
    this.ulbTripsDta = [];
    this.showLdr = true;
    this.slctdDt = funDt;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      tnt_in: 0
    }
    const rte = `dashboard/enterprise/get_ulbwiseDta/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        // this.ulbTripsDta = res['data'];
        this.ulbTripsDupDta = res['data'];
        this.onfltrSelect(1);
        this.showLdr = false;
      }
    }, (err) => {
      // console.log(err);
    });
  }
  devCellclk(devclmns, dvclkent) {
    if (dvclkent.columnIndex == 2) {
      if (dvclkent.data.tnt_dsply_nm != undefined) {
        this.rowselected = dvclkent.data;
        this.rowselected.slctdDt = this.slctdDt
        this.dbrd_ky = 'ulb';
        this.dbrd_lvl = 1;
        this.chckDbrdLvl();
      }
    }
  }
  onCellPrepared(colDef, e) {

    if (e.rowType === 'data' && e.row.data && e.column.dataField == 'tnt_dsply_nm') {
      e.cellElement.style.color = 'rgb(0 142 255)';
      e.cellElement.style.fontWeight = 500;
      e.cellElement.style.borderRadius = '10px';
      e.cellElement.style.cursor = 'pointer';
    }

  }
  getVehiclesOperatedcntsVndrULBwise(funDt?) {
    this.slctdDt = funDt;
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    let rte = `dashboard/enterprise/get_vndrwise_operated_Vehicles/${this.curDate}/${this.dbrd_ky}`;
    this.apiServ.get(rte).subscribe((res) => {
      let res_data = res['data'];
      console.log(res_data, "------res_data-------")
      if (res_data && res_data.length > 0) {
        this.ulbVndrwseopratedTripsTble = res['data'];
        this.ulbVndrwseopratedcolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, filter: false },
          { headerName: 'Zone', alignment: 'left', width: 80, field: 'zone_nm' },
          { headerName: 'Vendor Name', alignment: 'left', width: 100, field: 'vndr_nm' },
          // { headerName: 'ULB Name', alignment: 'left', width: 200, field: 'tnt_nm' },
          { headerName: 'No of Locations', alignment: 'center', field: 'ulb_ct' },
          { headerName: 'Urban', alignment: 'center', field: 'urban_ttl' },
          { headerName: 'Rural', alignment: 'center', field: 'rrl_ttl' },
          { headerName: 'Total Vehicles', field: 'ttl', alignment: 'center' },
          { headerName: 'Online Vehicles', field: 'online_ct', alignment: 'center' },
          { headerName: 'Offline Vehicles', field: 'offline_ct', alignment: 'center' },
          // { headerName: 'Operated Vehicles', field: 'operated',  alignment: 'center' },
          // { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
          // { headerName: 'Operated %', field: 'op_prct',  alignment: 'center' },
        ]
        this.vndrttlColumnDefs = [{ headerName: 'No of Locations', alignment: 'center', field: 'ulb_ct', summaryType: 'sum' },
        { headerName: 'Urban', field: 'urban_ttl', alignment: 'center', summaryType: 'sum' },
        { headerName: 'Rural', field: 'rrl_ttl', alignment: 'center', summaryType: 'sum' },
        { headerName: 'Total Vehicles', field: 'ttl', alignment: 'center', summaryType: 'sum' },
        { headerName: 'Online Vehicles', field: 'online_ct', alignment: 'Center', summaryType: 'sum' },
        { headerName: 'Offline Vehicles', field: 'offline_ct', alignment: 'Center', summaryType: 'sum' }]
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  onfltrSelect(evt) {
    this.ulbTripsDta = [];
    this.columnDefs = [];
    this.ttlColumnDefs = [];
    this.fltrMd = evt;
    if (this.fltrMd == 0) {
      this.tblHdr = `ULB/Mandal Wise Vehicles Summary`
      let cnt = 0;
      this.ulbTripsDupDta.filter((k) => {
        k['idx'] = ++cnt;
        this.ulbTripsDta.push(k)
      });
    } else if (this.fltrMd == 1) {
      this.tblHdr = `ULB Wise Vehicles Summary`
      let cnt1 = 0;
      this.ulbTripsDupDta.filter((k) => {
        if (k['urban_in'] == 1) {
          k['idx'] = ++cnt1;
          this.ulbTripsDta.push(k);
        }
      });
    } else if (this.fltrMd == 2) {
      this.tblHdr = `Mandal Wise Vehicles Summary`
      let cnt2 = 0;
      this.ulbTripsDupDta.filter((k) => {
        if (k['rural_in'] == 1) {
          k['idx'] = ++cnt2;
          this.ulbTripsDta.push(k);
        }
      });
    }
    if (this.crdOne == true) {
      this.columnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'ULB Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Total Vehicles', alignment: 'center', field: 'tot_veh', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
        { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
        { headerName: 'Operated %', field: 'opper', alignment: 'center' },
        { headerName: 'Total Trips', alignment: 'center', field: 'totl_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled Trips', alignment: 'center', field: 'trvld_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Trips %', alignment: 'center', field: 'trvl_prcntg', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Running', field: 'running', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        // { headerName: 'Closed', field: 'closed', width:60, alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Route Deviated', field: 'rd_cnt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' }

      ]
      this.ttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Total Trips', field: 'totl_trps', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Travelled Trips', field: 'trvld_trps', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Running', field: 'running', alignment: 'Center', summaryType: 'sum' },]
    } else if (this.crdTwo == true) {
      this.columnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'ULB Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Total Vehicles', alignment: 'center', field: 'tot_veh', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
        { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
        { headerName: 'Operated %', field: 'opper', alignment: 'center' },


      ]
      this.ttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },]
    } else if (this.crdThree == true) {
      this.columnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 150, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center' },
        { headerName: 'ULB Name', alignment: 'left', width: 250, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled above 30 Km', field: 'abv_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' }


      ]
      this.ttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      // { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      // { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', summaryType: 'sum' },
      { headerName: 'Travelled above 30 Km', field: 'abv_30_veh_ct', alignment: 'center', summaryType: 'sum' }]

    } else if (this.crdFour == true) {
      this.columnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'ULB Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Total Vehicles', alignment: 'center', field: 'tot_veh', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Total Trips', alignment: 'center', field: 'totl_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled Trips', alignment: 'center', field: 'trvld_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Trips %', alignment: 'center', field: 'trvl_prcntg', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Running', field: 'running', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        // { headerName: 'Closed', field: 'closed', width:60, alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Route Deviated', field: 'rd_cnt', alignment: 'center', cellClass: 'pm-grid-number-cell' },

      ]
      this.ttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Total Trips', field: 'totl_trps', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Travelled Trips', field: 'trvld_trps', alignment: 'center', summaryType: 'sum' },
      { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Running', field: 'running', alignment: 'Center', summaryType: 'sum' },]
    } else if (this.crdFive == true) {
      this.columnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'Mandal Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Total Vehicles', alignment: 'center', field: 'tot_veh', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
        { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
        { headerName: 'Operated %', field: 'opper', alignment: 'center' },
        { headerName: 'Total Trips', alignment: 'center', field: 'totl_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled Trips', alignment: 'center', field: 'trvld_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Trips %', alignment: 'center', field: 'trvl_prcntg', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Running', field: 'running', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        // { headerName: 'Closed', field: 'closed', width:60, alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Route Deviated', field: 'rd_cnt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' }

      ]
      this.ttlColumnDefs = [{ headerName: 'Mandal Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Total Trips', field: 'totl_trps', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Running', field: 'running', alignment: 'Center', summaryType: 'sum' },]
    } else if (this.crdSix == true) {
      this.columnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'Mandal Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Total Vehicles', alignment: 'center', field: 'tot_veh', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
        { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
        { headerName: 'Operated %', field: 'opper', alignment: 'center' },


      ]
      this.ttlColumnDefs = [{ headerName: 'Mandal Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },]
    } else if (this.crdSeven == true) {
      this.columnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 150, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'Mandal Name', alignment: 'left', width: 250, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled above 30 Km', field: 'abv_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' }


      ]
      this.ttlColumnDefs = [{ headerName: 'Mandal Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', summaryType: 'sum' },
      { headerName: 'Travelled above 30 Km', field: 'abv_30_veh_ct', alignment: 'center', summaryType: 'sum' }]

    }
    else if (this.crdEight == true) {
      this.columnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 150, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'Mandal Name', alignment: 'left', width: 250, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled Distance Km', field: 'dist_trvl', alignment: 'center', cellClass: 'pm-grid-number-cell' },
      ]
      this.ttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Travelled Distance Km', field: 'dist_trvl', summaryType: 'sum' },]
    }

  }
  ngOnDestroy() {
    clearInterval(this.liveId);
  }
  goBack() {
    this.dbrd_lvl = this.dbrd_lvl - 1;
    if (this.dbrd_lvl == 0) {
      this.dbrd_ky = 'state1';
      this.onfltrSelect(1);
      this.rowselected = null;
    } else if (this.dbrd_lvl == 1) {
      this.dbrd_ky = 'ulb';
      this.rowselected2 = null;
    }

    this.chckDbrdLvl();
  }
  getVehicleswiseData(funDt?, slctTnt?) {
    this.slctdDt = funDt;
    this.vehclesTble = [];
    this.vehclesTblecolumnDefs = [];
    this.vehttlColumnDefs = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    let svm_id;
    if (this.dbrd_lvl == 2) {
      svm_id = this.rowselected2.svm_id;
    } else {
      svm_id = 0;
    }
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: slctTnt,
      svm_id: svm_id
    }
    // let rte = `dashboard/enterprise/getVehicleSts`;
    let rte = `dashboard/enterprise/getSvmWiseDtls/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      this.showLdr = false;
      this.vehclesTble = res['data'];
      this.sortCards(this.crdVal);

      if (this.rowselected.urban_in == 1) {
        if (this.dbrd_lvl == 1) {

          // this.vehclesTblecolumnDefs = [
          //   { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false, fixed: true },
          //   { headerName: 'ULB', field: 'ZONE', alignment: 'left', fixed: true },
          //   { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', fixed: true },
          //   { headerName: 'Vehicles', field: 'VEHICLES', alignment: 'Center' },
          //   { headerName: 'Total Trips', field: 'TOTAL_TRIPS', alignment: 'Center' },
          //   { headerName: 'Completed Trips', field: 'OPERATED', alignment: 'Center' },
          //   // { headerName: 'Started Trips', field: 'STARTED_TRIP', alignment: 'Center' },
          //   { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED', alignment: 'Center', width: 150 },
          //   { headerName: 'Travle Distance(KM)', field: 'dist', alignment: 'Center' },
          //   // { headerName: 'Coverage %', field: 'coverage_per', alignment: 'Center' },
          //   // { headerName: 'Vehicle Status', field: 'asrt_sts_nm', alignment: 'Center' }
          // ];
          // this.sortCards(1);
          if (this.crdVal == 5 || this.crdVal == 6 || this.crdVal == 7 || this.crdVal == 8) {
            this.sortCards(1);
          } else {
            this.sortCards(this.crdVal);
          }
        } else if (this.dbrd_lvl == 2) {
          this.vehclesTble = [];
          this.vehclesTblecolumnDefs = [];
          this.vehttlColumnDefs = [];

          this.vehclesTble = res['data'];
          this.vehclesTblecolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false },
            { headerName: 'ULB', field: 'ZONE', alignment: 'left' },
            { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left' },
            { headerName: 'Vehicle', field: 'VEHICLE', alignment: 'Center' },
            { headerName: 'Trip Number', field: 'TRIP_NUM', alignment: 'Center' },
            { headerName: 'Source', field: 'SOURCE', alignment: 'left' },
            { headerName: 'Destination', field: 'DESTINATION', alignment: 'left' },
            { headerName: 'Start Time', field: 'ACTUAL_START', alignment: 'Center' },
            { headerName: 'End Time', field: 'ACTUAL_END', alignment: 'Center' },
            { headerName: 'Trip Status', field: 'TripStatus', alignment: 'left' },
            { headerName: 'Route Deviated', field: 'Deviation', alignment: 'Center' },
          ];
        }
        this.vehttlColumnDefs = [
          { headerName: 'Vehicles', field: 'TOT_VEHICLES', alignment: 'Center', summaryType: 'sum' },
          { headerName: 'Total Trips', field: 'TOTAL_TRIPS', alignment: 'Center', summaryType: 'sum' },
          { headerName: 'Completed Trips', field: 'OPERATED', alignment: 'Center', summaryType: 'sum' },
          // { headerName: 'Started Trips', field: 'STARTED_TRIP', alignment: 'Center', summaryType: 'sum' },

          { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED', alignment: 'Center', summaryType: 'sum' },
          { headerName: 'Travel Distance', field: 'dist', alignment: 'Center', summaryType: 'sum' },
          // { headerName: 'Coverage %', field: 'coverage_per', alignment: 'Center', summaryType: 'avg', format: { type: 'fixedPoint', precision: 2 } },

        ]
      } else {
        //   this.vehclesTblecolumnDefs = [
        //     { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false, fixed: true },
        //     { headerName: 'ULB', field: 'ZONE', alignment: 'left', fixed: true },
        //     { headerName: 'Vehicles', field: 'VEHICLES', alignment: 'Center' },
        //     { headerName: 'Total Trips', field: 'TOTAL_TRIPS', alignment: 'Center' },
        //     { headerName: 'Completed Trips', field: 'OPERATED', alignment: 'Center' },
        //     { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED', alignment: 'Center', width: 150 },
        //     { headerName: 'Travle Distance(KM)', field: 'dist', alignment: 'Center' },
        //   ];
        // }
        // this.vehttlColumnDefs = [
        //   { headerName: 'Vehicles', field: 'TOT_VEHICLES', alignment: 'Center', summaryType: 'sum' },
        //   { headerName: 'Total Trips', field: 'TOTAL_TRIPS', alignment: 'Center', summaryType: 'sum' },
        //   { headerName: 'Completed Trips', field: 'OPERATED', alignment: 'Center', summaryType: 'sum' },
        //   // { headerName: 'Started Trips', field: 'STARTED_TRIP', alignment: 'Center', summaryType: 'sum' },

        //   { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED', alignment: 'Center', summaryType: 'sum' },
        //   { headerName: 'Travel Distance', field: 'dist', alignment: 'Center', summaryType: 'sum' },
        //   // { headerName: 'Coverage %', field: 'coverage_per', alignment: 'Center', summaryType: 'avg', format: { type: 'fixedPoint', precision: 2 } },

        // ]

        // this.vehclesTblecolumnDefs = [
        //   { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false, fixed: true },
        //   { headerName: 'ULB', field: 'ZONE', alignment: 'left', fixed: true },
        //   { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', fixed: true },
        //   { headerName: 'Vehicles', field: 'VEHICLES', alignment: 'Center' },
        //   { headerName: 'Total Trips', field: 'TOTAL_TRIPS', alignment: 'Center' },
        //   { headerName: 'Completed Trips', field: 'OPERATED', alignment: 'Center' },
        //   // { headerName: 'Started Trips', field: 'STARTED_TRIP', alignment: 'Center' },
        //   { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED', alignment: 'Center', width: 150 },
        //   { headerName: 'Travle Distance(KM)', field: 'dist', alignment: 'Center' },
        //   // { headerName: 'Coverage %', field: 'coverage_per', alignment: 'Center' },
        //   // { headerName: 'Vehicle Status', field: 'asrt_sts_nm', alignment: 'Center' }
        // ];
        if (this.crdVal == 1 || this.crdVal == 2 || this.crdVal == 3 || this.crdVal == 4) {
          this.sortCards(5);
        } else {
          this.sortCards(this.crdVal);
        }
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  sortCards(val) {
    this.vehclesTblecolumnDefs = [];
    this.vehttlColumnDefs = [];
    if (val == 1) {

      this.vehclesTblecolumnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', },
        { headerName: 'ULB Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', },
        { headerName: 'Vehicles', field: 'VEHICLES', alignment: 'Center' },
        { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
        { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
        { headerName: 'Operated %', field: 'opper', alignment: 'center' },
        { headerName: 'Total Trips', alignment: 'center', field: 'totl_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled Trips', alignment: 'center', field: 'trvld_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Trips %', alignment: 'center', field: 'trvl_prcntg', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Running', field: 'running', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        // { headerName: 'Closed', field: 'closed', width:60, alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Route Deviated', field: 'rd_cnt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' }

      ]
      this.vehttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Total Trips', field: 'totl_trps', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Running', field: 'running', alignment: 'Center', summaryType: 'sum' },]
    } else if (val == 2) {
      this.vehclesTblecolumnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'ULB Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', },
        { headerName: 'Vehicles', field: 'VEHICLES', alignment: 'Center' },
        { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
        { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
        { headerName: 'Operated %', field: 'opper', alignment: 'center' },


      ]
      this.vehttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },]
    } else if (val == 3) {
      this.vehclesTblecolumnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 150, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'ULB Name', alignment: 'left', width: 250, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', },
        { headerName: 'Vehicles', field: 'VEHICLES', alignment: 'Center' },
        { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled above 30 Km', field: 'abv_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' }


      ]
      this.vehttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', summaryType: 'sum' },
      { headerName: 'Travelled above 30 Km', field: 'abv_30_veh_ct', alignment: 'center', summaryType: 'sum' }]

    } else if (val == 4) {
      this.vehclesTblecolumnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'ULB Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left', },
        { headerName: 'Vehicles', field: 'VEHICLES', alignment: 'Center' },
        { headerName: 'Total Trips', alignment: 'center', field: 'totl_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled Trips', alignment: 'center', field: 'trvld_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Trips %', alignment: 'center', field: 'trvl_prcntg', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Running', field: 'running', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        // { headerName: 'Closed', field: 'closed', width:60, alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Route Deviated', field: 'rd_cnt', alignment: 'center', cellClass: 'pm-grid-number-cell' },

      ]
      this.vehttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Trips', field: 'totl_trps', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Running', field: 'running', alignment: 'Center', summaryType: 'sum' },]
    } else if (val == 5) {
      this.vehclesTblecolumnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'Mandal Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Total Vehicles', alignment: 'center', field: 'tot_veh', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
        { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
        { headerName: 'Operated %', field: 'opper', alignment: 'center' },
        { headerName: 'Total Trips', alignment: 'center', field: 'totl_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled Trips', alignment: 'center', field: 'trvld_trps', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Trips %', alignment: 'center', field: 'trvl_prcntg', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Running', field: 'running', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        // { headerName: 'Closed', field: 'closed', width:60, alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Route Deviated', field: 'rd_cnt', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' }

      ]
      this.vehttlColumnDefs = [{ headerName: 'Mandal Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Total Trips', field: 'totl_trps', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Yet to start', field: 'yet_to_strt', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Running', field: 'running', alignment: 'Center', summaryType: 'sum' },]
    } else if (val == 6) {
      this.vehclesTblecolumnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 100, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'Mandal Name', alignment: 'left', width: 150, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Total Vehicles', alignment: 'center', field: 'tot_veh', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Operated Vehicles', field: 'operated', alignment: 'center' },
        { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'center' },
        { headerName: 'Operated %', field: 'opper', alignment: 'center' },


      ]
      this.vehttlColumnDefs = [{ headerName: 'Mandal Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },]
    } else if (val == 7) {
      this.vehclesTblecolumnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 150, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'Mandal Name', alignment: 'left', width: 250, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled above 30 Km', field: 'abv_30_veh_ct', alignment: 'center', cellClass: 'pm-grid-number-cell' }


      ]
      this.vehttlColumnDefs = [{ headerName: 'Mandal Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Travelled below 30 Km', field: 'blw_30_veh_ct', alignment: 'center', summaryType: 'sum' },
      { headerName: 'Travelled above 30 Km', field: 'abv_30_veh_ct', alignment: 'center', summaryType: 'sum' }]

    }
    else if (val == 8) {
      this.vehclesTblecolumnDefs = [
        { headerName: 'S.No', field: 'idx', alignment: 'center', width: 60, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'District', field: 'dstrct_nm', alignment: 'left', width: 150, filter: false, cellClass: 'pm-grid-number-cell', search: false },
        { headerName: 'Mandal Name', alignment: 'left', width: 250, field: 'tnt_dsply_nm', cellClass: 'pm-grid-number-cell' },
        { headerName: 'Travelled Distance Km', field: 'dist_trvl', alignment: 'center', cellClass: 'pm-grid-number-cell' },
      ]
      this.vehttlColumnDefs = [{ headerName: 'ULB Name', field: 'tnt_dsply_nm', alignment: 'Center', summaryType: 'count' },
      { headerName: 'Total Vehicles', field: 'tot_veh', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Operated Vehicles', field: 'operated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Non Operated Vehicles', field: 'notoperated', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Travelled Distance Km', field: 'dist_trvl', summaryType: 'sum' },]
    }
  }
  // lst7DayDstnc;
  get_lst7DaysDistanceDta(funDt?, slctTnt?): any {
    let chart = am4core.create("vehOperationalChart", am4charts.XYChart);
    chart.data = [];
    this.slctdDt = funDt;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      tnt_in: 1,
      slctd_tnt: slctTnt
    }
    let rte = `dashboard/enterprise/get_lst7DaysDistance/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      chart.data = res['data'];
      // this.lst7DayDstnc = this.chart.data;
      if(chart.data && chart.data[0])
      this.ttl_vhcls = chart.data[0].ttl;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "dt";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = ` ----- Date(${this.curYr})----- `;
      // categoryAxis.renderer.labels.template.rotation = -45;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.renderer.opposite = true;
      // valueAxis.strictMinMax = true;
      valueAxis.title.text = " ----- Operated % ----- ";
      chart.colors.list = [
        am4core.color("#f7babd"),
        // am4core.color("#91dae3"),
      ]
      chart.legend = new am4charts.Legend();

      // Create series


      function createSeries(field, name) {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "dt";
        series.name = name;
        series.columns.template.tooltipText = "{name} Vehicles in {categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;
        series.columns.template.height = am4core.percent(100);
        series.sequencedInterpolation = false;

        let columnTemplate1 = series.columns.template;
        columnTemplate1.strokeWidth = 1;
        let valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{valueY}";
        valueLabel.label.fontSize = 15;
        valueLabel.label.dy = 10;
        columnTemplate1.column.cornerRadiusTopLeft = 5;
        columnTemplate1.column.cornerRadiusTopRight = 5;
        columnTemplate1.strokeOpacity = 1;

        let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
      }

      createSeries("oprtd", "Operated");
      // createSeries("nt_optrd", "Not Operated");
    });
  }
  devCellclkL1(devclmns, dvclkent) {
    console.log(dvclkent)
    if (dvclkent.columnIndex == 3 && this.dbrd_lvl == 1) {
      if (dvclkent.data.svm_nm != undefined) {
        this.rowselected2 = dvclkent.data;
        this.rowselected2.slctdDt = this.slctdDt
        this.dbrd_ky = 'svm';
        this.dbrd_lvl = 2;
        this.chckDbrdLvl();
      }
    }
  }
  onCellPreparedL1(colDef, e) {

    if (e.rowType === 'data' && e.row.data && e.column.dataField == 'svm_nm' && this.dbrd_lvl == 1) {
      e.cellElement.style.color = 'rgb(0 142 255)';
      e.cellElement.style.fontWeight = 500;
      e.cellElement.style.borderRadius = '10px';
      e.cellElement.style.cursor = 'pointer';

    }

  }
  getsvmCoveredTripsCount(): any {
    this.svmtdyCoveredTrips = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      svm_id: this.rowselected2.svm_id
    }
    let rte = `dashboard/enterprise/getCoveredTripsCount/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.svmtdyCoveredTrips = res['data'];
      }
      console.log(this.svmtdyCoveredTrips, '-------svmtdyCoveredTrips---------')
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  cardOne() {
    this.crdOne = true; this.crdTwo = false; this.crdThree = false; this.crdFour = false;
    this.crdFive = false; this.crdSix = false; this.crdSeven = false; this.crdEight = false;
    this.crdVal = 1;

    if (this.dbrd_lvl == 0) {
      this.onfltrSelect(1);

    } else {
      this.sortCards(1);
    }

  }
  cardTwo() {
    this.crdOne = false; this.crdTwo = true; this.crdThree = false; this.crdFour = false;
    this.crdFive = false; this.crdSix = false; this.crdSeven = false; this.crdEight = false;
    this.crdVal = 2;

    if (this.dbrd_lvl == 0) {
      this.onfltrSelect(1);

    } else {
      this.sortCards(2);
    }
  }
  cardThree() {
    this.crdOne = false; this.crdTwo = false; this.crdThree = true; this.crdFour = false;
    this.crdFive = false; this.crdSix = false; this.crdSeven = false; this.crdEight = false;
    this.crdVal = 3;

    if (this.dbrd_lvl == 0) {
      this.onfltrSelect(1);

    } else {
      this.sortCards(3);
    }

  }
  cardFour() {
    this.crdOne = false; this.crdTwo = false; this.crdThree = false; this.crdFour = true;
    this.crdFive = false; this.crdSix = false; this.crdSeven = false; this.crdEight = false;
    this.crdVal = 4;

    if (this.dbrd_lvl == 0) {
      this.onfltrSelect(1);

    } else {
      this.sortCards(4);
    }
  }
  cardFive() {
    this.crdOne = false; this.crdTwo = false; this.crdThree = false; this.crdFour = false;
    this.crdFive = true; this.crdSix = false; this.crdSeven = false; this.crdEight = false;
    this.crdVal = 5;

    if (this.dbrd_lvl == 0) {
      this.onfltrSelect(2);
    } else {
      this.sortCards(5);
    }
  }
  cardSix() {
    this.crdOne = false; this.crdTwo = false; this.crdThree = false; this.crdFour = false;
    this.crdFive = false; this.crdSix = true; this.crdSeven = false; this.crdEight = false;
    this.crdVal = 6;

    if (this.dbrd_lvl == 0) {
      this.onfltrSelect(2);
    } else {
      this.sortCards(6);
    }

  }
  cardSeven() {
    this.crdOne = false; this.crdTwo = false; this.crdThree = false; this.crdFour = false;
    this.crdFive = false; this.crdSix = false; this.crdSeven = true; this.crdEight = false;
    this.crdVal = 7;

    if (this.dbrd_lvl == 0) {
      this.onfltrSelect(2);
    } else {
      this.sortCards(7);
    }

  }
  cardEight() {
    this.crdOne = false; this.crdTwo = false; this.crdThree = false; this.crdFour = false;
    this.crdFive = false; this.crdSix = false; this.crdSeven = false; this.crdEight = true;
    this.crdVal = 8;

    if (this.dbrd_lvl == 0) {
      this.onfltrSelect(2);
    } else {
      this.sortCards(8);
    }
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), ' Vehicles Summary.xlsx');
      });
    });
  }

  onRowPrepared(event) {
    // console.log(event)
    if (event.rowType === 'data') {
      const rowData = event.data;


      if (rowData) {
        event.rowElement.style.backgroundColor = '#b0d5d0';
        event.rowElement.style.color = 'white';
      }
    }
  }
}
