import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';

interface UploadFile {
  uid: string;
  name: string;
  status: string;
  url?: string;
  thumbUrl?: string;
  type?: string;
}

@Component({
  selector: 'app-create-purchase',
  templateUrl: './create-purchase.component.html',
  styleUrls: ['./create-purchase.component.scss']
})
export class CreatePurchaseComponent implements OnInit {
  purchaseForm: FormGroup;
  uploadedFiles: UploadFile[] = [];

  // Mock data for dropdowns
  branchList = [
    { value: 'branch1', label: 'Branch 1' },
    { value: 'branch2', label: 'Branch 2' }
  ];

  vendorList = [
    { value: 'vendor1', label: 'Vendor 1' },
    { value: 'vendor2', label: 'Vendor 2' }
  ];

  companyList = [
    { value: 'company1', label: 'Company 1' },
    { value: 'company2', label: 'Company 2' }
  ];

  categoryList = [
    { value: 'category1', label: 'Category 1' },
    { value: 'category2', label: 'Category 2' }
  ];

  inventoryList = [
    { value: 'inventory1', label: 'Inventory 1' },
    { value: 'inventory2', label: 'Inventory 2' }
  ];

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private message: NzMessageService
  ) {
    this.createForm();
  }

  ngOnInit(): void { }

  createForm(): void {
    this.purchaseForm = this.fb.group({
      branch: [null, Validators.required],
      vendorCompany: [null, Validators.required],
      vendorLocation: [null, Validators.required],
      company: [null, Validators.required],
      purchaseDate: [null, Validators.required],
      poNo: [null, Validators.required],
      deliveryAddress: [null, Validators.required],
      billingAddress: [null, Validators.required],
      items: this.fb.array([])
    });

    this.addItem();
  }

  get items(): FormArray {
    return this.purchaseForm.get('items') as FormArray;
  }

  createItem(): FormGroup {
    return this.fb.group({
      category: [null, Validators.required],
      inventoryName: [null, Validators.required],
      quantity: [1, [Validators.required, Validators.min(1)]],
      make: [null, Validators.required],
      model: [null, Validators.required],
      singlePcPrice: [0, [Validators.required, Validators.min(0)]],
      discount: [0, [Validators.required, Validators.min(0)]],
      gstIncluded: ['no', Validators.required],
      gstIn: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      totalPrice: [{ value: 0, disabled: true }],
      remarks: ['']
    });
  }

  addItem(): void {
    this.items.push(this.createItem());
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  // File handling methods
  beforeUpload = (file: File): boolean => {
    const isAllowed = file.type === 'application/pdf' ||
      file.type.startsWith('image/');
    if (!isAllowed) {
      this.message.error('You can only upload PDF or Image files!');
      return false;
    }

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      this.message.error('File must be smaller than 5MB!');
      return false;
    }

    // Create a preview URL for images
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadedFiles.push({
          uid: `${Date.now()}`,
          name: file.name,
          status: 'done',
          url: reader.result as string,
          thumbUrl: reader.result as string,
          type: file.type
        });
      };
    } else {
      // For PDF files
      this.uploadedFiles.push({
        uid: `${Date.now()}`,
        name: file.name,
        status: 'done',
        type: file.type
      });
    }

    return false; // Prevent actual upload since we're handling it manually
  };

  removeFile(index: number): void {
    this.uploadedFiles.splice(index, 1);
  }

  isImage(file: UploadFile): boolean {
    return file.type?.startsWith('image/') || false;
  }

  isPdf(file: UploadFile): boolean {
    return file.type === 'application/pdf';
  }

  getPreviewUrl(file: UploadFile): string {
    return file.thumbUrl || file.url || '';
  }

  goBack(): void {
    this.location.back();
  }

  attachments=[]
  onSubmit(): void {
    if (this.purchaseForm.valid) {
      console.log('Form submitted', {
        formValue: this.purchaseForm.value,
        attachments: this.uploadedFiles
      });
      // Add your API call here
    } else {
      Object.values(this.purchaseForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
        }
      });
    }
  }

  cancel(){
    this.purchaseForm.reset();
    this.uploadedFiles = [];

  }
}
