import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api.service';
import { animate } from '@angular/animations';
import { Location, DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { FormGroup, FormControl, Validator, Validators } from '@angular/forms';
import * as moment from 'moment';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { PointedShape } from '@amcharts/amcharts4/core';
import { Console } from 'console';
import 'leaflet-rotatedmarker';
// import { truncate } from 'fs';


@Component({
  selector: 'app-play-back',
  templateUrl: './play-back.component.html',
  styleUrls: ['./play-back.component.scss']
})
export class PlayBackComponent implements OnInit {
  points: any = [];
  plypoint: any = [];
  playbackmap;
  trackVehicleSpeed;
  trackVehicleTimestamp;
  init_lat = 17;
  init_lng = 81;
  checked: any = false;
  finalSpdMrkrs: any = [];
  plybckIcn;
  fencInOutDtls: any = [];
  plypoint1: any = [];
  endPBMarker;
  startPBMarker;
  slctdItm: any;
  marker;
  selectedvndr: any;
  rtplydata;
  line;
  index;
  stoppageTime;
  travelDist;
  timerId: any;
  pauseindex;
  animate;
  shwLoc: any = [];
  isCollapsed = false;
  opnSdebar: boolean;
  trpdate: boolean;
  trpdtl: boolean;
  strtdate: boolean;
  enddate: boolean;
  plyBckform;
  lctnsDta: any = [];
  vhclsDta: any = [];
  trpDta: any = [];
  fncDtls: any = [];
  pauseVal = true;
  min = 1;
  max = 150;
  asrtiD: any;
  showLdr = false;
  sliderValue = 100;
  speedval = 0;
  showTimeline = false;
  Route_collectPointArray: any = [];
  Route_collectPointArray1: any = [];
  usrDtls;
  pbMarkerEndPosition;
  activeGroups: any = [];
  pointMarker;
  locations: any = [];
  route_Polyline: any;
  allChecked = false;
  masterSelected: boolean;
  checklist: any = [];
  checkedList: any;
  shwSpdMtr = false;
  drvr_nm;
  drvr_mbl_nu;
  // @ViewChild('multiSelect') multiSelect;
  public loadContent: boolean = false;
  public name = 'Cricketers';
  public data = [];
  public settings = {};
  public selectedItems = [];
  _lastIndex: any = [];
  locModel: any = [];
  points1: any = [];
  date = new Date();
  sdate: Date = new Date();
  edate: Date = new Date();
  asrt_id: any;
  totalTravelTime;
  RoutePoints: any = [];
  strtTm = this.datepipe.transform(this.date, 'yyyy-MM-dd HH:mm');
  endTm = this.datepipe.transform(this.date, 'yyyy-MM-dd HH:mm');
  trpdt = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  // zeroDt=this.datepipe.transform('0000-00-00 00:00:00', 'yyyy-MM-dd HH:mm');
  polygon: L.Polygon<any>;
  polygon1: L.Polygon<any>;
  fncepolygon: L.Polygon<any>;
  playPolygonData: any = [];
  playPolygonData1: any = [];
  // indeterminate;
  plygnLatlngs: any;
  isCheckedButton: boolean;
  lctns: any = [];
  cntxt: any = [];
  // allChecked = false;
  indeterminate = true;
  clntDtls: any = [];
  tntLst: any = [];
  seletedIndex: any;
  selectedtenants: any = [];
  vendorList: any = [];
  vehdetails: boolean;
  showVndr: boolean = false;
  clap_in: number;
  ShowClap: boolean = false;
  userDtls: any;
  slctdDfltTnt: any; showAll: boolean = false;
  tripData: any;
  isUrban: boolean;
  lbl: string;
  grpLbl: string;
  fncsGrpId: any;
  fncsLst: any;
  fnclocModel: any = [];
  selectedWrd: any;
  wardLst: any;
  wrdfncepolygon: L.Polygon<any>;
  wrdplypoint: any = [];
  constructor(private apiS: ApiService, private router: Router, private ref: ChangeDetectorRef, public datepipe: DatePipe, private message: NzMessageService
    , public TransfereService: TransfereService) {
    this.plyBckform = new FormGroup({
      lctn: new FormControl(''),
      lctnFnce: new FormControl(''),
      vhcl: new FormControl(''),
      plybtype: new FormControl(''),
      trpdt: new FormControl(''),
      trp: new FormControl(''),
      strtTm: new FormControl(''),
      endTm: new FormControl(''),

    });

    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.clntDtls)
    this.tntLst = this.clntDtls[0].tnts;
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.userDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
    if (this.tntLst[0].urban_in == 1) {
      this.isUrban = true;
      this.lbl = 'ULB';
      this.grpLbl = 'Vehicle Group';

    } else {
      this.isUrban = false;
      this.lbl = 'Mandal';
      this.grpLbl = 'Panchayati';
    }
    // console.log('seletedIndex --------------- ', this.seletedIndex);
    console.log(this.tntLst.length)
    if (this.tntLst.length == 1) {
      this.selectedtenants = this.tntLst[0].tnt_id;
      this.slctdDfltTnt = this.selectedtenants;
      console.log(this.selectedtenants);
      this.showAll = false;
    } else {
      this.selectedtenants = 0;
      this.slctdDfltTnt = this.selectedtenants;
      console.log(this.selectedtenants);
      this.showAll = true;
    }
    this.selectedvndr = 0;

    // this.sliderValue = 100;

    this.tripData = this.TransfereService.getLoclData('plybckDta');
    console.log(this.tripData);
    this.TransfereService.ClearLocalData('plybckDta');

    if (this.tripData) {
      this.asrt_id = this.tripData.asrt_id;
      // this.plyBckform.value.vhcl
      this.plyBckform.get('vhcl').setValue(this.tripData.asrt_id);
      // this.plyBckform.get('strtTm').setValue(this.datepipe.transform(this.tripData.actl_strt_ts, 'yyyy-dd-MM'));
      // this.plyBckform.get('endTm').setValue(this.datepipe.transform(this.tripData.actl_end_ts, 'yyyy-dd-MM'));
      this.plyBckform.get('plybtype').setValue(1);
      this.plyBckform.value.plybtype = 1;
      this.getPlybackType(1);
      this.plyBckform.get('trpdt').setValue(this.tripData.actl_strt);
      // this.date = this.plyBckform.value.trpdt;
      this.getTrpDate(this.plyBckform.value.trpdt);
      // this.plyBckform.value.strtTm = this.datepipe.transform(this.tripData.actl_strt_ts, 'yyyy-dd-MM HH:mm');
      // this.plyBckform.value.endTm = this.datepipe.transform(this.tripData.actl_end_ts, 'yyyy-dd-MM HH:mm');
      // // this.getTrpDate(this.date)
    }
    console.log(this.date)
  }


  getvehiclesFunctn(value) {
    if (value == 0) {
      this.playbackmap.setView([15.912327, 79.739238], 7)

    } else {
      for (var i = 0; i < this.tntLst.length; i++) {
        if (this.tntLst[i].tnt_id == value) {
          if (this.tntLst[i].lat && this.tntLst[i].lng) {
            this.playbackmap.setView([this.tntLst[i].lat, this.tntLst[i].lng], 13);
          } else {
            this.playbackmap.setView([15.912327, 79.739238], 7)
          }

        }
      }
    }
    /************Get Current Vehicles List************************* */
    this.getVhcles();

    this.getVendors();
    // this.apiS.get(`billing/vehicle-wise/vendors/list/${this.selectedtenants}`).subscribe((res) => {
    //   console.log(res)
    //   if (res['status'] == 200) {
    //     this.vendorList = res['data'];
    //   }
    // }, (err) => {
    //   console.log("In errorr");
    // })

    this.apiS.get(`playback/landmark/groups/${this.selectedtenants}`).subscribe((res) => {
      console.log(res['data']);
      this.activeGroups = res['data'];
      // for (var m = 0; m < this.activeGroups.length; m++) {
      //   this.lctnsDta.push({
      //     'id': this.activeGroups[m].fnce_grp_id,
      //     'name': this.activeGroups[m].fnce_grp_nm
      //   })
      // }
      console.log(this.activeGroups);
      // this.updt();

    }, (err) => {
      console.log("In errorr");
    })

    this.apiS.get('playback/location/points')
      .subscribe((res) => {
        this.points1 = res['data'];
        console.log(this.points1);
      }, (err) => {
        console.log("In errorr");

      });

  }
  getVhcles() {
    this.apiS.get(`playback/web/vehicles/cur_Running/${this.selectedtenants}/${this.selectedvndr}`).subscribe((res) => {
      console.log(res)
      this.vhclsDta = res['data'];
      console.log(this.vhclsDta);
    }, (err) => {
      console.log("In errorr");
    })
  }
  getVendors() {
    if (this.userDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.userDtls.vndr_id;
      this.showVndr = true;
      this.apiS.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vendorList = res['data'];
        }
      }, (err) => {
        console.log("In errorr");
      })
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vendorList = [];
    } else {
      this.apiS.get(`billing/vehicle-wise/vendors/list/${this.selectedtenants}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
          // this.USER_PERMISSIONS = res['perm'][0];
          if (this.vendorList.length > 1) {
            this.selectedvndr = 0;
          } else {
            this.selectedvndr = this.vendorList[0].vndr_id;
          }
        }
      }, (err) => {
        console.log('In errorr');
      });
    }
  }
  onvndrSelect(value) {

    if (value == 0) {
      this.selectedvndr = 0;
    } else if (value == 1) {
      // this.tntLst = [];
    } else {
      this.selectedvndr = value;
    }
    this.getVhcles();

  }
  getTripsbyAsrt(asrt_id) {
    console.log(asrt_id);
    this.clearMarkersonMap();

    if (this.plypoint) {
      for (var k = 0; k < this.plypoint.length; k++) {
        this.playbackmap.removeLayer(this.plypoint[k].ply);
      }
    }


    this.asrt_id = asrt_id;
    // console.log(this.asrtiD);
    // /************Get TripsList By Asrt_id List************************* */
    // var preDtaObj = {

    //   "trpdate":this.datepipe.transform(this.plyBckform.value.trpdt, 'yyyy-MM-dd'),
    //   // "sdate": this.datepipe.transform(this.sdate, 'yyyy-MM-dd 00:00'),
    //   // "edate": this.datepipe.transform(this.edate, 'yyyy-MM-dd 23:59'),
    //   "asrt_id": this.asrt_id
    // };
    // console.log(preDtaObj)
    // this.apiS.post(preDtaObj, 'playback/vehicle/trips').subscribe((res) => {
    //   console.log(res['data']);
    //   this.trpDta = res['data'];
    // }, (err) => {
    //   console.log("In errorr");
    // })
    console.log(this.clntDtls[0].clnt_id)
    if (this.clntDtls[0].clnt_id != 7) {
      this.getWrdbyAsrt();
    }


  }
  getWrdbyAsrt() {
    if (this.wrdplypoint) {
      for (var k = 0; k < this.wrdplypoint.length; k++) {
        this.playbackmap.removeLayer(this.wrdplypoint[k].ply);
      }
    }
    this.apiS.get(`playback/wards/byVhcle/${this.asrt_id}`).subscribe((res) => {
      if (res['status'] === 200) {
        this.wardLst = res['data'];
        for (var l = 0; l < this.wardLst.length; l++)
          if (this.wardLst[l].fnce_arc_lne_tx) {
            this.wrdfncepolygon = L.polygon(JSON.parse(this.wardLst[l].fnce_arc_lne_tx) as []
              , { color: this.wardLst[l].fncs_clr_cd }).addTo(this.playbackmap).bindTooltip(this.wardLst[l].wrd_nm, { permanent: true, opacity: 0.7 }).openTooltip();
            this.wrdplypoint.push({ ply: this.wrdfncepolygon, fnce_id: this.wardLst[l].fnce_id });
            this.playbackmap.fitBounds(this.wrdfncepolygon.getBounds());
          }
      }
    }, (err) => {
      console.log('In errorr');
    });

  }
  getTrpDate(dt) {
    console.log(this.plyBckform.value.vhcl);
    console.log(dt)
    /************Get TripsList By Asrt_id List************************* */
    var preDtaObj = {

      "trpdate": this.datepipe.transform(dt, 'yyyy-MM-dd'),
      // "sdate": this.datepipe.transform(this.sdate, 'yyyy-MM-dd 00:00'),
      // "edate": this.datepipe.transform(this.edate, 'yyyy-MM-dd 23:59'),
      "asrt_id": this.plyBckform.value.vhcl
    };
    console.log(preDtaObj)
    this.apiS.post(preDtaObj, 'playback/vehicle/trips').subscribe((res) => {
      console.log('resssssssssssssssssssssssssssssssss', res['data']);
      this.trpDta = res['data'];
      if (this.tripData && this.tripData.trp_rn_id) {
        this.plyBckform.get('trp').setValue(this.tripData.trp_rn_id);
        this.getTripData(this.tripData.trp_rn_id);
      }
    }, (err) => {
      console.log("In errorr");
    })
  }
  onLocationSelect(evt) {
    this.fncsGrpId = evt;
    this.fncsByGrp(this.fncsGrpId);
  }
  fncsByGrp(grp_id) {
    this.apiS.get(`playback/landmark/bygroups/${grp_id}`).subscribe((res) => {
      console.log(res)
      this.fncsLst = res['data'];
      console.log(this.fncsLst);
    }, (err) => {
      console.log("In errorr");
    })
  }
  getLocOnMap() {
    this.locations = [];

    if (this.pointMarker) {
      for (var k = 0; k < this._lastIndex.length; k++) {
        this.playbackmap.removeLayer(this._lastIndex[k]);
      }
    }

    if (this.plypoint1) {
      for (var k = 0; k < this.plypoint1.length; k++) {
        this.playbackmap.removeLayer(this.plypoint1[k].ply);
      }
    }
    if (this.fnclocModel) {
      if (this.fnclocModel == 'select') {
        for (var j = 0; j < this.fncsLst.length; j++) {

          for (var k = 0; k < this.points1.length; k++) {
            if (this.fncsLst[j].fnce_grp_id == this.points1[k].fnce_grp_id) {
              console.log(this.fncsLst[j].fnce_grp_id == this.points1[k].fnce_grp_id);

              this.locations.push(this.points1[k]);
            }
          }

        }
      }
      else if (this.fnclocModel == 'deselect') {
        this.locations = [];
      } else {

        for (var j = 0; j < this.fncsLst.length; j++) {

          if (this.fnclocModel == this.fncsLst[j].fnce_id) {

            for (var k = 0; k < this.points1.length; k++) {
              if (this.fncsLst[j].fnce_id == this.points1[k].fnce_id) {
                console.log(this.fncsLst[j].fnce_id == this.points1[k].fnce_id);

                this.locations.push(this.points1[k]);
              }
            }

          }
        }
      }

      for (var l = 0; l < this.locations.length; l++) {
        // console.log("In location1");

        if (!this.locations[l].fncs_icn_pth) {
          // console.log("In location2");

          var thisIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/fenceIcon.png',
            iconSize: [32, 30],
            iconAnchor: [10, 39],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
        } else if (this.locations[l].fncs_icn_pth == 'dumperBin') {
          // console.log("In location3");

          if (this.locations[l].covr_status == 1) {
            // console.log("In location4");
            var icn = "_r_m";
          } else {
            // console.log("In location5");

            var icn = "_o_m";
          }
          var thisIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.locations[l].fncs_icn_pth + icn + '.png',
            iconSize: [32, 30],
            iconAnchor: [22, 45],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
        } else {
          // console.log("In location6");

          if (this.locations[l].covr_status == 1) {
            // console.log("In location7");

            var icn = "_r_m";
          } else {
            // console.log("In location8");

            var icn = "_o_m";
          }
          var thisIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.locations[l].fncs_icn_pth + icn + '.png',
            // iconSize: [32, 30],
            // // iconAnchor: [22, 45],
            // iconAnchor: [16, 28],
            // // popupAnchor: [1, -34],
            // shadowSize: [41, 41]
            iconSize: [33, 33],
            iconAnchor: [16, 28],
          });
        }

        if (this.locations[l].lat && this.locations[l].lng) {
          this.pointMarker = L.marker([this.locations[l].lat, this.locations[l].lng], {
            draggable: false, // Make the icon dragable
            // title: this.locations[l].fnce_nm, // Add a title
            opacity: 5,
            icon: thisIcon
          }).addTo(this.playbackmap).bindTooltip(this.locations[l].fnce_nm, { permanent: true, opacity: 0.7, offset: [0, -20] }).openTooltip();;

          var circleMarker = L.circle([this.locations[l].lat, this.locations[l].lng], { radius: this.locations[l].rds_ct }, {
            // stroke: false,
            // fillColor: '#f55555',
            // fillOpacity: 0.07,
          }).addTo(this.playbackmap);
          circleMarker.setStyle({ fillColor: '#f55555', stroke: false, fillOpacity: 0.07 })
          this._lastIndex.push(this.pointMarker);
          this._lastIndex.push(circleMarker);
          if (this.locations[l].fnce_arc_lne_tx) {
            this.fncepolygon = L.polygon(JSON.parse(this.locations[l].fnce_arc_lne_tx) as []
              , { color: this.locations[l].fncs_clr_cd }).addTo(this.playbackmap).bindTooltip(this.locations[l].fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
            this.plypoint1.push({ ply: this.fncepolygon, fnce_id: this.locations[l].fnce_id });
            this.playbackmap.fitBounds(this.fncepolygon.getBounds());
          }
        }
      }
    }
  }
  updt() {
    // this.locModel=locModel;
    console.log(this.locModel)    // mcg.clearLayers();
    this.locations = [];
    if (this.pointMarker) {
      for (var k = 0; k < this._lastIndex.length; k++) {
        this.playbackmap.removeLayer(this._lastIndex[k]);
      }
    }

    if (this.plypoint1) {
      for (var k = 0; k < this.plypoint1.length; k++) {
        this.playbackmap.removeLayer(this.plypoint1[k].ply);
      }
    }
    if (this.locModel) {

      //   this.locModel.map((val) => {
      if (this.locModel == 'select') {
        for (var j = 0; j < this.activeGroups.length; j++) {

          for (var k = 0; k < this.points1.length; k++) {
            if (this.activeGroups[j].fnce_grp_id == this.points1[k].fnce_grp_id) {
              console.log(this.activeGroups[j].fnce_grp_id == this.points1[k].fnce_grp_id);

              this.locations.push(this.points1[k]);
            }
          }

        }
      }
      else if (this.locModel == 'deselect') {
        this.locations = [];
      }
      else {

        for (var j = 0; j < this.activeGroups.length; j++) {

          if (this.locModel == this.activeGroups[j].fnce_grp_id) {
            // console.log("In iff2");
            // console.log(this.locModel[i], this.activeGroups[j].fnce_grp_id);

            for (var k = 0; k < this.points1.length; k++) {
              if (this.activeGroups[j].fnce_grp_id == this.points1[k].fnce_grp_id) {
                console.log(this.activeGroups[j].fnce_grp_id == this.points1[k].fnce_grp_id);

                this.locations.push(this.points1[k]);
              }
            }

          }
        }
      }
      // }
      // console.log(this.locations);

      for (var l = 0; l < this.locations.length; l++) {
        // console.log("In location1");

        if (!this.locations[l].fncs_icn_pth) {
          // console.log("In location2");

          var thisIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/fenceIcon.png',
            iconSize: [32, 30],
            iconAnchor: [10, 39],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
        } else if (this.locations[l].fncs_icn_pth == 'dumperBin') {
          // console.log("In location3");

          if (this.locations[l].covr_status == 1) {
            // console.log("In location4");
            var icn = "_r_m";
          } else {
            // console.log("In location5");

            var icn = "_o_m";
          }
          var thisIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.locations[l].fncs_icn_pth + icn + '.png',
            iconSize: [32, 30],
            iconAnchor: [22, 45],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
        } else {
          // console.log("In location6");

          if (this.locations[l].covr_status == 1) {
            // console.log("In location7");

            var icn = "_r_m";
          } else {
            // console.log("In location8");

            var icn = "_o_m";
          }
          var thisIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.locations[l].fncs_icn_pth + icn + '.png',
            // iconSize: [32, 30],
            // // iconAnchor: [22, 45],
            // iconAnchor: [16, 28],
            // // popupAnchor: [1, -34],
            // shadowSize: [41, 41]
            iconSize: [33, 33],
            iconAnchor: [16, 28],
          });
        }

        if (this.locations[l].lat && this.locations[l].lng) {
          this.pointMarker = L.marker([this.locations[l].lat, this.locations[l].lng], {
            draggable: false, // Make the icon dragable
            // title: this.locations[l].fnce_nm, // Add a title
            opacity: 5,
            icon: thisIcon
          }).addTo(this.playbackmap).bindTooltip(this.locations[l].fnce_nm, { permanent: true, opacity: 0.7, offset: [0, -20] }).openTooltip();;

          var circleMarker = L.circle([this.locations[l].lat, this.locations[l].lng], this.locations[l].rds_ct * 10, {
            stroke: false,
            fillColor: '#f55',
            fillOpacity: 0.07,
          }).addTo(this.playbackmap);
          this._lastIndex.push(this.pointMarker);
          this._lastIndex.push(circleMarker);
          if (this.locations[l].fnce_arc_lne_tx) {
            this.fncepolygon = L.polygon(JSON.parse(this.locations[l].fnce_arc_lne_tx) as []
              , { color: this.locations[l].fncs_clr_cd }).addTo(this.playbackmap).bindTooltip(this.locations[l].fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
            this.plypoint1.push({ ply: this.fncepolygon, fnce_id: this.locations[l].fnce_id });
            this.playbackmap.fitBounds(this.fncepolygon.getBounds());
          }
        }
      }
    }
  }
  closeSidebar() {
    this.isCollapsed = true;
    this.opnSdebar = false;
  }
  openSidebar() {
    this.isCollapsed = false;
    this.opnSdebar = true;
  }
  initialize = function () {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.usrDtls);
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.playbackmap = L.map('playbackmap', {
        center: [this.usrDtls[0].lat, this.usrDtls[0].lng],
        zoom: 3
      });

      // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   maxZoom: 18,
      //   minZoom: 3,
      //   attribution: ''
      // });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: ''
      });

      tiles.addTo(this.playbackmap);
      this.playbackmap.setView([this.usrDtls[0].lat, this.usrDtls[0].lng], 15)

    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }

  };


  ngOnInit() {

    this.initialize();
    this.opnSdebar = true;
    if (this.slctdDfltTnt) {
      this.getvehiclesFunctn(this.slctdDfltTnt);
    }
  }



  //----------Pause Button -- Leaflet OS Playback----------//

  pause() {
    console.log('pause_____________________________');
    this.pauseVal = false;
    console.log(this.timerId);
    // if (this.timerId) {
    clearTimeout(this.timerId);
    // }
  }
  play() {
    console.log('play_____________________________');
    this.pauseVal = true;
    this.timerId = this.update();

  }



  trackVehicle = function () {
    console.log(this.plyBckform.value.strtTm)
    console.log(this.plyBckform.value.endTm)

    // Need to update the code 
    var srtrt_time = this.datepipe.transform(this.plyBckform.value.strtTm, 'yyyy-MM-dd');
    console.log(srtrt_time)
    var startDate = new Date(srtrt_time);
    var currentDate = new Date();
    var timeDifference = currentDate.getTime() - startDate.getTime();
    var timeDifferenceInDays = timeDifference / (1000 * 60 * 60 * 24);
    console.log(typeof timeDifferenceInDays) 
    if(timeDifferenceInDays < 90){
      console.log("In trackkkkkkk");
      this.shwSpdMtr = true;
      this.clearMarkersonMap();
      //   if (this.pauseVal == true) {
      //     this.pauseVal = false;
      //     this.timerId = this.update();
      // }
      // console.log(this.rtplydata.slice(1,-1));
      if (this.rtplydata != null) {
        this.route_Polyline = L.polyline(JSON.parse(this.rtplydata), {
          color: '#008000',
          weight: 5,
          lineCap: 'butt',
          opacity: 0.5,
          smoothFactor: 10.0,
          lineJoin: 'round'
        }).addTo(this.playbackmap);
      }
  
      if (this.plyBckform.value.trp == undefined) {
        this.trp_rn_id = 0;
      } else {
        this.trp_rn_id = this.plyBckform.value.trp;
      }
      console.log(this.plyBckform);
      this.showLdr = true;
      // console.log(this.zeroDt)
      if (this.plyBckform.value.strtTm != undefined && this.plyBckform.value.strtTm.toString() != '0000-00-00 00:00' && this.plyBckform.value.strtTm != null && this.plyBckform.value.strtTm != ''
        && this.plyBckform.value.endTm != undefined && this.plyBckform.value.endTm.toString() != '0000-00-00 00:00' && this.plyBckform.value.endTm != null && this.plyBckform.value.endTm != '') {
        var data = {
          "checkbit": "byVeh",
          "sdate": this.datepipe.transform(this.plyBckform.value.strtTm, 'yyyy-MM-dd HH:mm'),
          "edate": this.datepipe.transform(this.plyBckform.value.endTm, 'yyyy-MM-dd HH:mm'),
          "asrt_id": this.asrt_id,
          "trp_rn_id": this.trp_rn_id
        };
        console.log(data);
        // $http.post($rootScope.BaseUrl + "/vehicle/playback", data)
        this.apiS.post(data, 'playback/vehicle/playback').subscribe((res) => {
          // console.log(res);
          this.showLdr = false;
          // if (res && res['status'] == 200) {
          // console.log(res['data']);
  
          // .success(function(resdata, status) {
  
          var playData = res['data'];
          console.log(playData)
          //         // console.log(playData.mapPoints[0].icon_path);
          // this.plybckIcn = 'https://unpkg.com/leaflet@1.0.3/dist/images/marker-icon.png';
          // if (playData.length == 0) {
          //     // this.showTimeline=false;
          // } else {
          //     // this.showTimeline = true;
          // }
          // if(playData.fncDtls == undefined){
          //   this.showTimeline=false;
          // }else{
          //   this.showTimeline=true;
  
          // }
          console.log(playData.fncDtls);
          if (playData.fncDtls) {
            for (var i = 0; i < playData.fncDtls.length; i++) {
  
              this.fencInOutDtls.push({
                SNo: playData.fncDtls[i].SNo,
                fnce_id: playData.fncDtls[i].fnce_id,
                fnce_nm: playData.fncDtls[i].fnce_nm,
                fnc_in: playData.fncDtls[i].actl_arvl_ts,
                fnc_ut: playData.fncDtls[i].actl_dprte_ts,
                // asrt_nm: playData.fncDtls[i].asrt_nm,
                fnce_cvr_sts: playData.fncDtls[i].fnce_cvr_sts,
                stoppage: playData.fncDtls[i].stoppage,
                lati: playData.fncDtls[i].lat,
                longi: playData.fncDtls[i].lng,
                icn: playData.fncDtls[i].fncs_icn_pth,
                cvr_sts: playData.fncDtls[i].cvr_sts,
              });
  
  
              if (this.fencInOutDtls.length == 0) {
                this.showTimeline = false;
              } else {
                this.showTimeline = true;
              }
  
              if (playData.fncDtls[i].fnce_cvr_sts == 1) {
                var thisIcon = new L.Icon({
                  iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + playData.fncDtls[i].fncs_icn_pth + '_r_m.png',
                  iconSize: [32, 30],
                  iconAnchor: [22, 45],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41]
                });
  
                this.RoutecollectPointMarker = L.marker([playData.fncDtls[i].lat, playData.fncDtls[i].lng], {
                  draggable: false, // Make the icon dragable
                  title: playData.fncDtls[i].fnce_nm, // Add a title
                  opacity: 5,
                  icon: thisIcon
                }).addTo(this.playbackmap)
                  .bindPopup("<div style='color: #000;'><b style='line-height: 21px; color: #1a56a2;'>Fence Name:  </b>" + playData.fncDtls[i].fnce_nm + "</div>")
                  .closePopup();
                this.RoutecollectPointCircle = L.circle([playData.fncDtls[i].lat, playData.fncDtls[i].lng], 50, {
                  stroke: false,
                  fillColor: '#ffde00',
                  fillOpacity: 0.5,
                });
                this.Route_collectPointArray.push(this.RoutecollectPointMarker, this.RoutecollectPointCircle);
              }
              if (playData.fncDtls[i].fnce_cvr_sts == 0) {
                var thisIcon = new L.Icon({
                  iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + playData.fncDtls[i].fncs_icn_pth + '_o_m.png',
                  iconSize: [32, 30],
                  iconAnchor: [22, 45],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41]
                });
                this.RoutecollectPointMarker1 = L.marker([playData.fncDtls[i].lat, playData.fncDtls[i].lng], {
                  draggable: false, // Make the icon dragable
                  title: playData.fncDtls[i].fnce_nm, // Add a title
                  opacity: 5,
                  icon: thisIcon
                }).addTo(this.playbackmap)
                  .bindPopup("<div style='color: #000;'><b style='line-height: 21px; color: #1a56a2;'>Fence Name:  </b>" + playData.fncDtls[i].fnce_nm + "</div>")
                  .closePopup();
                this.RoutecollectPointCircle1 = L.circle([playData.fncDtls[i].lat, playData.fncDtls[i].lng], 50, {
  
                  stroke: false,
                  fillColor: '#ffde00',
                  fillOpacity: 0.5,
                });
                this.Route_collectPointArray1.push(this.RoutecollectPointMarker1, this.RoutecollectPointCircle1);
              }
            }
          }
          console.log(playData);
          if (playData !== '' && playData.lat.length > 0) {
  
            console.log("InnnnplayData");
            // To avoid duplicates for start & end points
            // this.points.push({
            //   lat: playData.lat[0],
            //   lng: playData.lng[0],
            //   speed_ct: playData.mapPoints[0].speed_ct,
            //   Signal: playData.mapPoints[0].Signall,
            //   asrt_nm: playData.mapPoints[0].asrt_nm,
            //   drcn_ct: playData.mapPoints[0].drcn_ct,
            // });
            var strt = playData.mapPoints[0].Signall;
            var end = playData.mapPoints[playData.mapPoints.length - 1].Signall;
            this.totalTravelTime = moment.utc(moment(end, "DD-MM-YYYY HH:mm").diff(moment(strt, "DD-MM-YYYY HH:mm"))).format("HH:mm:ss");
            // console.log(this.totalTravelTime);
  
  
  
            for (var c = 0; c < playData.lat.length; c++) {
              // console.log(playData.lat[c],playData.lat[playData.lat.length - 1])
              if (playData.lat[c] != playData.lat[0] && playData.lng[c] != playData.lng[0]) {
                // if (playData.lat[c] != playData.lat[playData.lat.length - 1] && playData.lng[c] != playData.lng[playData.lat.length - 1]) {
                this.points.push({
                  lat: playData.lat[c],
                  lng: playData.lng[c],
                  speed_ct: playData.mapPoints[c].speed_ct,
                  Signal: playData.mapPoints[c].Signall,
                  asrt_nm: playData.mapPoints[c].asrt_nm,
                  drcn_ct: playData.mapPoints[c].drcn_ct
                });
                // }
              }
            }
  
            this.points.push({
              lat: playData.lat[playData.lat.length - 1],
              lng: playData.lng[playData.lat.length - 1],
              // speed_ct: playData.mapPoints[playData.lat.length - 1].speed_ct,
              Signal: playData.mapPoints[playData.lat.length - 1].Signall != undefined ? playData.mapPoints[playData.lat.length - 1].Signall : '',
              asrt_nm: playData.mapPoints[playData.lat.length - 1].asrt_nm,
              // drcn_ct: playData.mapPoints[playData.lat.length - 1].drcn_ct,
            });
            this.RoutePoints = this.points;
  
            var trvlDstnce = playData.mapPoints[playData.mapPoints.length - 1].odmtr_ct - playData.mapPoints[0].odmtr_ct;
            var totDtnce = Math.ceil(trvlDstnce);
            this.travelDist = totDtnce + ' ' + 'Kms';
            var stoppageCounter = 0;
            var stoppageStartTimeIndex = 0;
            // var stoppageTime = 0;
            var diffTime;
            var pstrt;
            var pend;
            var sno = 0;
            console.log(this.points)
            for (var m = 0; m < this.points.length; m++) {
              var startTime;
              var endTime;
  
              if (this.points[m].speed_ct == 0) {
                console.log("In speed");
  
                // if (this.points[m].lat == this.points[m + 1].lat &&
                //   this.points[m].lng == this.points[m + 1].lng) {
                //     // console.log("In lattttt")
                //   if (stoppageStartTimeIndex === 0) {
                //     stoppageStartTimeIndex = m;
                //     stoppageCounter = m;
                //   } else {
                //     stoppageCounter++;
                //   }
                // } else {
                // console.log("In else");
                // console.log(this.points[stoppageStartTimeIndex].Signal);
                // console.log(this.points[stoppageCounter].Signal);
  
                // **************************** commented bcz of not getting playback due to datepipe error***********//
                // startTime = this.points[stoppageStartTimeIndex].Signal;
                // endTime = this.points[stoppageCounter].Signal;
                // console.log(this.points[m].Signal);
                if (this.points[m].Signal == undefined) {
                  // startTime = new Date(this.points[m + 1].Signal);
                  // startTime = new Date(this.datepipe.transform(this.points[m+1].Signal, "dd.MM.yyyy HH:mm"));
                  pstrt = this.points[m + 1].Signal;
  
                } else {
                  // startTime = new Date(this.points[m + 1].Signal);
                  pstrt = this.points[m].Signal;
  
                  // startTime = new Date(this.datepipe.transform(this.points[m].Signal, "dd.MM.yyyy HH:mm"));
  
                }
                // endTime = new Date(this.points[m + 1].Signal);
  
                pend = this.points[m + 1].Signal;
  
                this.stoppageTime = moment(pend, "DD-MM-YYYY HH:mm").diff(moment(pstrt, "DD-MM-YYYY HH:mm"));
  
                // endTime = new Date(this.datepipe.transform(this.points[m+1].Signal, "dd.MM.yyyy HH:mm"));
  
                // startTime=moment(this.points[stoppageStartTimeIndex].Signal).format('dd.MM.yyyy HH:mm');
                // endTime=moment(this.points[stoppageCounter].Signal).format('dd.MM.yyyy HH:mm')
  
                // console.log(this.points[m].Signal);
                // console.log(endTime);
                // console.log(endTime - startTime);
                // diffTime = Math.abs(endTime - startTime);
                // stoppageTime = Math.ceil(diffTime / 60000);
                // stoppageTime = ( this.points[stoppageCounter].Signal-this.points[stoppageStartTimeIndex].Signal) / 60000;
                // console.log(pend);
                // console.log(pstrt);
  
                // console.log(this.stoppageTime);
                this.points[m]["stoppageTime"] = this.stoppageTime / 60000;
                this.points[m]["lastTime"] = this.points[m + 1].Signal;
                // console.log(this.stoppageTime)
                if (this.stoppageTime != 0) {
  
                  this.points[m]["Sno"] = ++sno;
                  console.log("sno", sno)
                }
  
                // stoppageStartTimeIndex = 0;
                // stoppageCounter = 0;
                // }
              }
            }
  
            for (var k in this.points) { }
            // var k=this.points.length-1;
            var endIcon = new L.Icon({
              iconUrl: '/assets/images/markers/playback/endPoint.png',
              iconSize: [42, 45],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41]
            });
  
            this.endPBMarker = L.marker([this.points[k].lat, this.points[k].lng], {
              icon: endIcon
            }).addTo(this.playbackmap);
            // console.log(this.points[this.points.length-])
            this.endPBMarker.bindPopup("<b>Vehicle No : " + this.points[k].asrt_nm + "<br>Ending Time : " + this.points[k].Signal + "</b>")
              .addTo(this.playbackmap);
  
            //             // ----------Fence Start Area (flag position) -- Leaflet OS Playback----------//
            var startIcon = new L.Icon({
              iconUrl: '/assets/images/markers/playback/startPoint.png',
              iconSize: [42, 45],
              iconAnchor: [22, 45],
              popupAnchor: [1, -34],
              shadowSize: [41, 41]
            });
            console.log(this.points[0])
  
            this.startPBMarker = L.marker([this.points[0].lat, this.points[0].lng], {
              icon: startIcon
            }).addTo(this.playbackmap);
            this.startPBMarker.bindPopup("<b>Vehicle No : " + this.points[0].asrt_nm + "<br>Starting Time : " + this.points[0].Signal + "</b>")
              .addTo(this.playbackmap);
            // this.playbackmap.fitBounds(this.startPBMarker.getBounds());
  
  
            //             /*you can place either below "class name" or style options in polyline decoratr --- Leaflet Playback
            //             { className: 'my_polyline' }
            //             */
            this.marker = null;
            this.line = L.polyline([],
              {
                color: 'blue',
                weight: 3,
                opacity: 0.5,
                smoothFactor: 1,
                lineJoin: 'round',
  
              }
            )
              .on('mouseover', function (e) {
                e.target.setStyle({
                  color: 'blue'
                });
              })
              .on('mouseout', function (e) {
                e.target.setStyle({
                  color: 'blue'
                });
              }).addTo(this.playbackmap);
            this.index = 0;
  
            this.timerId = this.update();
  
          } else {
            console.log("No Vehicle Operated");
          }
          // })
          // .error(function(status, data) {
          //     console.log('Not Successfully' + data);
          // });
          // var duration = performance.now() - startTime;
          // // console.log('performance of trackvehicle() for playaback ' + duration);\
          // }
        }, (err) => {
  
          // this.forgotForm['captcha'] = '';
          // this.loader = false;
          // this.errmsg = true;
          // this.err_msg = "Something went wrong. Please contact the administrator.";
        })
  
      } else {
        this.showLdr = false;
        this.createMessage('Warning', 'Please check the details');
      }
    }
    else{
      this.createMessage('Warning', 'Playback data only available for last 3 months');
    }
  };

  update() {
    // console.log();
    // console.log(this.points);
    return setTimeout(() => {
      this.pauseindex = this.index;
      // if (this.isTripCompleted) {
      //     return;
      // }
      // console.log("In points");
      this.speedval = 350 - this.sliderValue;
      // console.log(this.sliderValue,this.speedval)
      if (this.points.length) {
        // console.log(this.points.shift());
        this.redraw(this.points.shift());
        this.timerId = this.update();
      }
    }, this.speedval);
  }
  redraw(point) {

    // this.ref.reattach();
    // this.$apply();
    // console.log("In point");
    // console.log(point);
    if (point.speed_ct) {
      var vehicleSpeed = point.speed_ct;
      this.trackVehicleSpeed = vehicleSpeed + ' Kms';
    }
    if (point.Signal) {
      this.trackVehicleTimestamp = point.Signal;
      // console.log( this.trackVehicleTimestamp)
    }
    // this.trackLat = point.lat;
    // this.trackLng = point.lng;
    // this.$apply();

    console.log(point.stoppageTime)
    if (point.stoppageTime !== undefined && point.stoppageTime !== 0 && point.stoppageTime < 10) {
      // console.log(point.stoppageTime);
      var asrt_ParkIcon = new L.Icon({
        iconUrl: '/assets/images/markers/playback/parking.png',
        iconSize: [42, 45],
        iconAnchor: [12, 31],
        popupAnchor: [0, -14],
        shadowSize: [41, 41]
      });
      // console.log(point.stoppageTime);
      if (point.stoppageTime >= 0) {
        this.finalSpdMrkrs.push(L.marker([point.lat, point.lng], {
          icon: asrt_ParkIcon
        })
          .bindPopup("<b>Vehicle No : " + point.asrt_nm + "<br>Parking No : " + point.Sno + "<br>From : " + point.Signal + "<br>To :  " + point.lastTime + "<br>STOPPAGE : " + point.stoppageTime + ' Mins' + "</b>")
          .addTo(this.playbackmap));
        console.log(this.finalSpdMrkrs)
        // $.ajax({
        //     url: 'http://maps.googleapis.com/maps/api/geocode/json?latlng=' + point.lat + ',' + point.lng + '&sensor=true',
        //     success: function(data) {
        //         // _parkingAddress = data.results[0].formatted_address;


        //     }
        // });
      }
    }else if(point.stoppageTime !== undefined && point.stoppageTime !== 0 && point.stoppageTime >= 10){
      var asrt_ParkIcon2 = new L.Icon({
        iconUrl: '/assets/images/markers/playback/parkingRed.png',
        iconSize: [42, 45],
        iconAnchor: [12, 31],
        popupAnchor: [0, -14],
        shadowSize: [41, 41]
      });
      // console.log(point.stoppageTime);
      if (point.stoppageTime >= 0) {
        this.finalSpdMrkrs.push(L.marker([point.lat, point.lng], {
          icon: asrt_ParkIcon2
        })
          .bindPopup("<b>Vehicle No : " + point.asrt_nm + "<br>Parking No : " + point.Sno + "<br>From : " + point.Signal + "<br>To :  " + point.lastTime + "<br>STOPPAGE : " + point.stoppageTime + ' Mins' + "</b>")
          .addTo(this.playbackmap));
        console.log(this.finalSpdMrkrs)
       
      }
    }

    var asrtIcon = new L.Icon({
      // iconUrl: iconBase + 'plybckRngVeh.png',
      iconUrl: '/assets/images/markers/playback/startPoint2.png',
      iconSize: [41, 25],
      iconAnchor: [22, 25],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    // var asrtIcon = new L.Icon({
    //     iconUrl: 'http://localhost:4801/client/public/assets/images/markers/mapIcons/m/' +this.plybckIcn+ '_r_m.png',
    //     iconSize: [35, null],
    //     iconAnchor: [18, 10],
    //     popupAnchor: [1, -34],
    //     shadowSize: [41, 41]
    // });
    //    console.log(point);
    //    console.log(point.drcn_ct);
    
      //  console.log("In markerrr");
      
     
      if(this.marker ){ 
        this.playbackmap.removeLayer(this.marker)
      }
      // if (!this.marker) {
        this.marker = L.marker(point, {
          icon: asrtIcon,
          // rotationAngle: point.drcn_ct
        }).addTo(this.playbackmap).setRotationAngle(point.drcn_ct);
      // }
      


    this.line.addLatLng(point);
    this.marker.setLatLng(point);
    // console.log(this.line);

    // console.log(this.marker);
    // this.marker.setRotationAngle(point.drcn_ct + 90);

    this.index++;

    //----------fitBounds -- Leaflet OS Playback----------//
    // this.playbackmap.fitBounds(bounds);
    //----------panTo -- Leaflet OS Playback----------//
    this.playbackmap.panTo(point, this.animate = false);

    //----------Fence End Area (flag position) -- Leaflet OS Playback----------//


    try {
      // console.log(this.points);
      // console.log(this.points[this.points.length - 1]);
      // console.log(this.points.length,this.index);
      if (this.points.length == 0) {
        console.log("In completedddd");
        this.message.success('TRIP COMPLETED', {
          nzDuration: 2000
        });
        // this.pbMarkerEndPosition = {
        //   lat: this.points[this.points.length - 1].lat,
        //   lng: this.points[this.points.length - 1].lng
        // };
        // this.endPBMarker.setLatLng(this.pbMarkerEndPosition);
      }
    } catch (e) {
      console.log(e);
    }

    // this.checkTripCompleted(point.lat, point.lng);


  }
  //----------Check Trips, And -- Leaflet OS Playback----------//
  // checkTripCompleted(lat, lon) {
  //     // console.log(lat, lon);
  //   try {
  //     if (lat == this.pbMarkerEndPosition.lat && lon == this.pbMarkerEndPosition.lng) {
  //       console.log("In trrrrrrrrrrrrrrp");
  //       // console.log(lat == this.pbMarkerEndPosition.lat && lon == this.pbMarkerEndPosition.lng);
  //       // $timeout(function() {
  //       //     swal("TRIP COMPLETED");
  //       //     this.isTripCompleted = true;
  //       //     this.isDisabled = true; //false
  //       // }, 100);
  //       setTimeout(() => {
  //         this.createMessage('success', 'TRIP COMPLETED');
  //       }, 100);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };


  //----------Clear Button -- Leaflet OS Playback----------//
  clearMarkersonMap() {
    for (var k = 0; k < this.finalSpdMrkrs.length; k++) {
      this.playbackmap.removeLayer(this.finalSpdMrkrs[k]);
    }
    // this.isTripCompleted = true;
    // this.isDisabled = false;
    this.points = [];
    for (var k = 0; k < this.Route_collectPointArray.length; k++) {
      this.playbackmap.removeLayer(this.Route_collectPointArray[k]);
    }
    for (var k = 0; k < this.Route_collectPointArray1.length; k++) {
      this.playbackmap.removeLayer(this.Route_collectPointArray1[k]);
    }
    clearTimeout(this.timerId);
    if (this.marker) {
      this.playbackmap.removeLayer(this.marker);
    }
    if (this.line) {
      this.playbackmap.removeLayer(this.line);
    }
    if (this.startPBMarker) {
      this.playbackmap.removeLayer(this.startPBMarker);
    }
    if (this.endPBMarker) {
      this.playbackmap.removeLayer(this.endPBMarker);
    }
    if (this.route_Polyline) {
      this.playbackmap.removeLayer(this.route_Polyline);

    }
    // if (this.route_Polyline) {
    //     $rootScope.playbackmap.removeLayer(route_Polyline);
    // }
    // if (measurePolyline) {
    //     $rootScope.playbackmap.remove(measurePolyline);
    // }
    // if (scaleHeight) {
    //     $rootScope.playbackmap.remove(scaleHeight);
    // }

  };
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }


  getTripData(trpId) {

    if (trpId) {
      // if (this.plypoint) {
      //   for (var k = 0; k < this.plypoint.length; k++) {
      //     this.playbackmap.removeLayer(this.plypoint[k].ply);

      //   }

      // }
      this.getCheckedItemList()
      console.log(trpId);
      for (var l = 0; l < this.trpDta.length; l++) {
        if (trpId == this.trpDta[l].trp_rn_id) {
          // console.log('valliiiiiiiiiiiiiiii')
          this.drvr_nm = this.trpDta[l].drvr_nm;
          this.drvr_mbl_nu = this.trpDta[l].drvr_phne_nu;
          this.rtplydata = this.trpDta[l].rte_arc_lne_tx;
          this.sdate = this.trpDta[l].actl_strt_ts;
          this.edate = this.trpDta[l].actl_end_ts;
          this.plyBckform.get('strtTm').setValue(this.sdate);
          this.plyBckform.get('endTm').setValue(this.edate);
          this.plyBckform.value.strtTm = this.sdate;
          this.plyBckform.value.endTm = this.edate;
          // if (this.trpDta[l].fnce_arc_lne_tx) {
          //   this.fncepolygon = L.polygon(JSON.parse(this.locations[l].fnce_arc_lne_tx) as []
          //     , { color: this.locations[l].fncs_clr_cd }).addTo(this.playbackmap).bindTooltip(this.locations[l].fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
          //   this.plypoint1.push({ ply: this.fncepolygon, fnce_id: this.locations[l].fnce_id });
          //   this.playbackmap.fitBounds(this.fncepolygon.getBounds());
          // }  
        }
      }
      var trpobj = {
        "trprnid": trpId

      };

      if (this.rtplydata == null) {
        this.cntxt.length = 0;
        // this.masterSelected = false;
      } else {
        // console.log('hiiiiiiiiii')
        this.apiS.post(trpobj, 'playback/cntxtPlgns').subscribe((res) => {
          console.log(res['data']);
          this.cntxt = res['data'];
          // console.log(this.cntxt);


          for (var k = 0; k < res['data'].length; k++) {
            this.checklist.push(
              { id: res['data'][k].fnce_id, value: res['data'][k].fnce_nm, plyline: res['data'][k].fnce_arc_lne_tx, fncs_clr_cd: res['data'][k].fncs_clr_cd, isSelected: true }
            );
          }
          // this.masterSelected = true;

          this.getCheckedItemList();



          // for (var i = 0; i < this.cntxt.length; i++) {

          //   if (this.cntxt[i].fnce_arc_lne_tx) {

          //     this.polygon = L.polygon(JSON.parse(this.cntxt[i].fnce_arc_lne_tx) as []
          //       , { color: this.cntxt[i].fncs_clr_cd }).addTo(this.playbackmap).bindTooltip(this.cntxt[i].fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
          //       this.playbackmap.fitBounds(this.polygon.getBounds());

          //   }

          // if(this.cntxt[0].fnce_arc_lne_tx){

          // }
          // }
          // console.log(this.checklist);
        }, (err) => {
          console.log("In errorr");
        })

        // this.sdate= this.datepipe.transform(this.sdate, 'yyyy-MM-dd 00:00'),
        // this.edate= this.datepipe.transform(this.edate, 'yyyy-MM-dd 23:59')

        // console.sdate);
        // }



        //  console.log(this.rtplydata);
        //  if(this.rtplydata){

        // this.route_Polyline = L.polyline(this.rtplydata, {
        //    color: '#008000',
        //   weight: 5,
        //   lineCap: 'butt',
        //   opacity: 0.5,
        //   smoothFactor: 10.0,
        //   lineJoin: 'round'
        // }).addTo(this.playbackmap);
        // }
        // this.playPolygonData = [{
        //   mcrpt_id: 1,
        //   mcrpt_nm: 'MP-1',
        //   plgn_area: [[17.000045, 81.798557], [17.001451, 81.802967], [17.001985, 81.807849],
        //   [16.999133, 81.801809], [17.000045, 81.798557]]
        // },
        // {
        //   mcrpt_id: 2,
        //   mcrpt_nm: 'MP-2',
        //   plgn_area: [[17.000045, 81.798557], [17.001481, 81.802987], [17.002005, 81.807849],
        //   [16.999313, 81.802309], [17.002045, 81.798550], [17.000045, 81.798557]]
        // },
        // {
        //   mcrpt_id: 3,
        //   mcrpt_nm: 'MP-3',
        //   plgn_area: [[17.001461, 81.802967], [17.001785, 81.807859],
        //   [16.990133, 81.801819], [17.000145, 81.798857], [17.002345, 81.745857], [17.006845, 81.797957], [17.001461, 81.802967]]
        // }]
      }
    }

  }

  // chckFun(slctd) {
  //   console.log(slctd)
  //   if (slctd == true) {
  //     if (slctd.fnce_arc_lne_tx) {

  //       this.polygon = L.polygon(JSON.parse(slctd.fnce_arc_lne_tx) as []
  //         , { color: slctd.fncs_clr_cd }).addTo(this.playbackmap).bindTooltip(slctd.fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();

  //     }
  //   } else if (slctd == false) {
  //     this.playbackmap.removeLayer(this.polygon);
  //   }
  // }



  // updateAllChecked(plyVal): void {
  //   console.log(this.allChecked);
  //   this.indeterminate = false;
  //   if (this.allChecked) {
  //     this.cntxt = this.cntxt.map(item => {
  //       console.log(item);
  //       for (var i = 0; i < this.cntxt.length; i++) {
  //         if (this.cntxt[i].fnce_arc_lne_tx) {
  //           this.polygon = L.polygon(JSON.parse(this.cntxt[i].fnce_arc_lne_tx) as []
  //             , { color: this.cntxt[i].fncs_clr_cd }).addTo(this.playbackmap).bindTooltip(this.cntxt[i].fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
  //         }
  //       }
  //       this.playbackmap.fitBounds(this.polygon.getBounds());
  //       return {
  //         ...item,
  //         checked: true
  //       };
  //     });
  //   } else {
  //     this.playbackmap.removeLayer(this.polygon);

  //     this.cntxt = this.cntxt.map(item => {
  //       console.log(item);
  //       return {
  //         ...item,
  //         checked: false

  //       };
  //     });
  //   }
  // }

  // updateSingleChecked(plyVal): void {

  //   this.cntxt = this.cntxt.map(item => {
  //     console.log(item);

  //   });
  //   if (this.cntxt.every(item => !item.checked)) {
  //     // console.log(item.checked);
  //     console.log(this.cntxt.every(item => !item.checked));
  //     // console.log(item);
  //     this.allChecked = false;
  //     this.indeterminate = false;
  //     // this.cntxt.map(item => {
  //     //   console.log(item);
  //     // });
  //   } else if (this.cntxt.every(item => item.checked)) {
  //     console.log(this.cntxt.every(item => item.checked));
  //     this.allChecked = true;
  //     this.indeterminate = false;
  //     // this.cntxt.map(item => {
  //     //   console.log(item);
  //     // });
  //   } else {
  //     // console.log(item);
  //     this.indeterminate = true;
  //   }
  // }



  // this.checklist = [
  //   {id:1,value:'Elenor Anderson',isSelected:false},
  //   {id:2,value:'Caden Kunze',isSelected:true},
  //   {id:3,value:'Ms. Hortense Zulauf',isSelected:true},
  //   {id:4,value:'Grady Reichert',isSelected:false},
  //   {id:5,value:'Dejon Olson',isSelected:false},
  //   {id:6,value:'Jamir Pfannerstill',isSelected:false},
  //   {id:7,value:'Aracely Renner DVM',isSelected:false},
  //   {id:8,value:'Genoveva Luettgen',isSelected:false}
  // ];

  // }

  checkUncheckAll() {
    console.log("un heck all");
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();

  }
  isAllSelected() {
    console.log("check all");
    // this.checklist=[];
    this.masterSelected = this.checklist.every(function (item: any) {
      console.log(item);
      // this.checklist.push(item);
      // if(item.isSelected == false){
      //   for (var k = 0; k < this.plypoint.length; k++) {
      //     this.playbackmap.removeLayer(this.plypoint[k]);

      //   }
      // }
      // this.slctdItm = item;
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  getPlybackType(value) {
    console.log(value);
    this.plyBckform.get('endTm').setValue('');
    this.plyBckform.get('strtTm').setValue('');
    // this.plyBckform.get('vhcl').setValue('');
    // this.plyBckform.get('trpdt').setValue('');
    this.clearMarkersonMap();
    this.trpdate = false;
    this.strtdate = false;
    this.enddate = false;
    this.trpdtl = false;
    this.vehdetails = false;
    if (value == 1) {
      this.trpdate = true;
      this.strtdate = true;
      this.enddate = true;
      this.trpdtl = true;
      this.vehdetails = true;

    } else {
      this.plyBckform.get('strtTm').setValue(this.datepipe.transform(this.date, 'yyyy-MM-dd 00:00'));
      this.plyBckform.get('endTm').setValue(this.datepipe.transform(this.date, 'yyyy-MM-dd 23:59'));

      this.trpdate = false;
      this.trpdtl = false;
      this.strtdate = true;
      this.enddate = true;
      this.vehdetails = true;
    }
  }

  getCheckedItemList() {
    console.log(this.masterSelected);
    console.log(this.checklist);
    // this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected == true) {
        console.log("in true");
        if (this.plypoint) {
          for (var k = 0; k < this.plypoint.length; k++) {
            if (this.checklist[i].id == this.plypoint[k].fnce_id) {
              this.playbackmap.removeLayer(this.plypoint[k].ply);

            }

          }

        }
        this.polygon = L.polygon(JSON.parse(this.checklist[i].plyline) as []
          , { color: this.checklist[i].fncs_clr_cd }).addTo(this.playbackmap).bindTooltip(this.checklist[i].value, { permanent: true, opacity: 0.7 }).openTooltip();
        this.plypoint.push({ ply: this.polygon, fnce_id: this.checklist[i].id });
        this.playbackmap.fitBounds(this.polygon.getBounds());
      }
      console.log(this.plypoint)
      if (this.checklist[i].isSelected == false) {

        if (this.plypoint) {
          for (var k = 0; k < this.plypoint.length; k++) {
            if (this.checklist[i].id == this.plypoint[k].fnce_id) {
              this.playbackmap.removeLayer(this.plypoint[k].ply);

            }

          }

        }
      }
      // console.log(this.polygon);

      // else{
      // console.log(this.polygon)
      // if(this.polygon){
      // this.playbackmap.removeLayer(this.polygon[i]);
      // }
      // }

      // this.checkedList.push(this.checklist[i]);
      // this.playbackmap.removeLayer(this.polygon);
    }



    // this.checkedList = JSON.stringify(this.checkedList);
    // console.log(this.slctdItm);
    // console.log(this.checkedList.length);
    // if (this.checkedList.length!=0) {
    // console.log("hhhhhhhhh")

    // for (var i = 0; i < this.checkedList.length; i++) {
    //   if (this.checkedList[i].fnce_arc_lne_tx) {


    //     }

    // }

  }
  closedrvrlst() {
    this.opnSdebar = false
  }
}

