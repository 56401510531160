<div class="complaint-form-wrapper">
  <form nz-form [formGroup]="mainForm" class="complaint-form" nzLayout="vertical">
    <div class="form-content" nz-row [nzGutter]="24">


      <div nz-col [nzSpan]="12" class="form-col">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="complaint_type">Complaint Type</nz-form-label>
          <nz-form-control nzErrorTip="Please select a complaint type">
            <nz-select id="complaint_type" formControlName="complaint_type" nzPlaceHolder="Select Complaint Type"
              (ngModelChange)="loadComplaintCategories($event)" [nzAllowClear]="false" nzShowSearch>
              <nz-option *ngFor="let type of complaintTypes" [nzValue]="type.wrk_grp_id"
                [nzLabel]="type.wrk_grp_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>





      <!-- complent id - 1 (enterprice) -->

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==1">
        <nz-form-item>
          <nz-form-label [nzRequired]="false" nzFor="caf_id">CAF ID / Username</nz-form-label>
          <nz-form-control nzErrorTip="Please enter CAF ID">
            <input nz-input id="caf_id" formControlName="caf_id" placeholder="Enter CAF ID or Username" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==1">
        <nz-form-item>
          <nz-form-label [nzRequired]="false" nzFor="caf_count">No Of CAF's Affected</nz-form-label>
          <nz-form-control nzErrorTip="Please enter a valid number">
            <nz-input-number id="caf_count" formControlName="caf_count" [nzMin]="1" [nzStep]="1"
              [nzPlaceHolder]="'Enter Number of CAF Affected'" class="w-full"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==1">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="complaint_category">Call Type</nz-form-label>
          <nz-form-control nzErrorTip="Please select a Call Type">
            <nz-select id="call_type_id" formControlName="call_type_id"
              nzPlaceHolder="Select Call Type" nzShowSearch>
              <nz-option *ngFor="let cl of call_types_lst" [nzValue]="cl.cl_typ_id"
                [nzLabel]="cl.cl_typ_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==1">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="priority">Priority</nz-form-label>
          <nz-form-control nzErrorTip="Please select a priority">
            <nz-select id="priority" formControlName="priority" nzPlaceHolder="Select Priority" nzShowSearch>
              <nz-option nzValue="2" nzLabel="High" class="priority-high"></nz-option>
              <nz-option nzValue="3" nzLabel="Medium" class="priority-medium"></nz-option>
              <nz-option nzValue="4" nzLabel="Low" class="priority-low"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

    

   
      <!-- complent id - 2 (NOC) -->

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==2">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="Network">Select Network</nz-form-label>
          <nz-form-control nzErrorTip="Please select Network">
            <nz-select id="Network" formControlName="Network_id"
              nzPlaceHolder="Select Network" nzShowSearch>
              <nz-option *ngFor="let n of Network_lst" [nzValue]="n.ntwrk_id"
                [nzLabel]="n.ntwrk_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==2">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="ticket_type">Ticket Type</nz-form-label>
          <nz-form-control nzErrorTip="Please select a ticket type">
            <nz-select id="ticket_type" formControlName="ticket_type" nzPlaceHolder="Select Ticket Type" nzShowSearch>
              <nz-option *ngFor="let type of ticketTypes" [nzValue]="type.tckt_typ_id"
                [nzLabel]="type.tckt_typ_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==2">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="Technology">Technology</nz-form-label>
          <nz-form-control nzErrorTip="Please select a Technology">
            <nz-select id="Technology_id" formControlName="Technology_id" nzPlaceHolder="Select Technology"
              (ngModelChange)="loadSubTechnologies($event);loadtcnlgyCategory($event)" [nzAllowClear]="false" nzShowSearch>
              <nz-option *ngFor="let t of Technology_lst" [nzValue]="t.tcnlgy_id"
                [nzLabel]="t.tcnlgy_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>


      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==2">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="Sub_Technology">Sub Technology</nz-form-label>
          <nz-form-control nzErrorTip="Please select aSub Technology">
            <nz-select id="Sub_Technology_id" formControlName="Sub_Technology_id" nzPlaceHolder="Select Sub Technology"
              [nzAllowClear]="false" nzShowSearch>
              <nz-option *ngFor="let st of Sub_Technology_lst" [nzValue]="st.sb_tcnlgy_id"
                [nzLabel]="st.sb_tcnlgy_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==2">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="complaint_category">Complaint Category</nz-form-label>
          <nz-form-control nzErrorTip="Please select a complaint category">
            <nz-select id="tech_complaint_category" formControlName="tech_complaint_category"
              nzPlaceHolder="Select Complaint Category" nzShowSearch>
              <nz-option *ngFor="let category of complaintCategories" [nzValue]="category.tech_wrk_typ_id"
                [nzLabel]="category.tech_wrk_typ_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>


      <!-- complent id - 3,4,5,6 (DHQ,ZHQ,MDQ,SS) -->

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==3 || cmplnt_id==4 || cmplnt_id==5 || cmplnt_id==6">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="ticket_type">Ticket Type</nz-form-label>
          <nz-form-control nzErrorTip="Please select a ticket type">
            <nz-select id="ticket_type" formControlName="ticket_type" nzPlaceHolder="Select Ticket Type" nzShowSearch>
              <nz-option *ngFor="let type of ticketTypes" [nzValue]="type.tckt_typ_id"
                [nzLabel]="type.tckt_typ_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==3 || cmplnt_id==4 || cmplnt_id==5 || cmplnt_id==6">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="dstrct_id">District</nz-form-label>
          <nz-form-control nzErrorTip="Please select a district">
            <nz-select id="dstrct_id" formControlName="dstrct_id" nzPlaceHolder="Select District"
              (ngModelChange)="loadlinks($event)" nzShowSearch>
              <nz-option *ngFor="let d of districtLst" [nzValue]="d.dstrt_id" [nzLabel]="d.dstrt_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==3 || cmplnt_id==4 || cmplnt_id==5 || cmplnt_id==6">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="Link_Name">Link Name</nz-form-label>
          <nz-form-control nzErrorTip="Please select a Link Name">
            <nz-select id="Link_id" formControlName="Link_id" nzPlaceHolder="Select Link Name"
              (ngModelChange)="getMandals($event)" nzShowSearch>
              <nz-option *ngFor="let l of Links_lst" [nzValue]="l.lnk_id" [nzLabel]="l.lnk_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>


      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==3 || cmplnt_id==4 || cmplnt_id==5 || cmplnt_id==6">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="Electronics">Electronics</nz-form-label>
          <nz-form-control nzErrorTip="Please select a Electronics">
            <nz-select id="Electronics_id" formControlName="Electronics_id" nzPlaceHolder="Select Electronics"
              (ngModelChange)="loadElctronicsCategory($event)" nzShowSearch>
              <nz-option *ngFor="let d of Electronics_lst" [nzValue]="d.elctrnc_id" [nzLabel]="d.elctrnc_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==3 || cmplnt_id==4 || cmplnt_id==5 || cmplnt_id==6">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="complaint_category">Complaint Category</nz-form-label>
          <nz-form-control nzErrorTip="Please select a complaint category">
            <nz-select id="elct_complaint_category" formControlName="elct_complaint_category"
              nzPlaceHolder="Select Complaint Category" nzShowSearch>
              <nz-option *ngFor="let category of complaintCategories" [nzValue]="category.elctrnc_wrk_typ_id"
                [nzLabel]="category.elctrnc_wrk_typ_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>



       

      <!-- complent id - 7,8,9,10 (OLT-Operator,OLT,Fiber,Power Infra) -->

      

      
      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==8">
        <nz-form-item>
          <nz-form-label [nzRequired]="false" nzFor="OLT_IP_Node">OLT IP/Node</nz-form-label>
          <nz-form-control nzErrorTip="Please enter a OLT IP/Node">
            <nz-input-number id="olt_ip" formControlName="olt_ip" [nzMin]="1" [nzStep]="1"
              [nzPlaceHolder]="'Enter OLT IP/Node'" class="w-full"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>



      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==7 || cmplnt_id==8 || cmplnt_id==9 || cmplnt_id==10">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="ticket_type">Ticket Type</nz-form-label>
          <nz-form-control nzErrorTip="Please select a ticket type">
            <nz-select id="ticket_type" formControlName="ticket_type" nzPlaceHolder="Select Ticket Type" nzShowSearch>
              <nz-option *ngFor="let type of ticketTypes" [nzValue]="type.tckt_typ_id"
                [nzLabel]="type.tckt_typ_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <!-- Common Fields -->

      <div nz-col [nzSpan]="12" class="form-col" *ngIf="cmplnt_id==1 ||cmplnt_id==7 || cmplnt_id==8 || cmplnt_id==9 || cmplnt_id==10">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="complaint_category">Complaint Category</nz-form-label>
          <nz-form-control nzErrorTip="Please select a complaint category">
            <nz-select id="complaint_category" formControlName="complaint_category"
              nzPlaceHolder="Select Complaint Category" nzShowSearch>
              <nz-option *ngFor="let category of complaintCategories" [nzValue]="category.wrk_typ_id"
                [nzLabel]="category.wrk_typ_nm"></nz-option>
            </nz-select>
          </nz-form-control>

        </nz-form-item>
      </div>


      <div nz-col [nzSpan]="12" class="form-col">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="emple_dsgns_id">Designation</nz-form-label>
          <nz-form-control nzErrorTip="Please select a designation">
            <nz-select id="emple_dsgns_id" formControlName="emple_dsgns_id" nzPlaceHolder="Select Designation"
              (ngModelChange)="loadEmployees($event)" nzShowSearch>
              <nz-option *ngFor="let desg of designation" [nzValue]="desg.emple_dsgns_id"
                [nzLabel]="desg.emple_dsgns_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" class="form-col">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="assigned_employee">Employee</nz-form-label>
          <nz-form-control nzErrorTip="Please select an employee">
            <nz-select id="assigned_employee" formControlName="assigned_employee" nzPlaceHolder="Select Employee" nzShowSearch>
              <nz-option *ngFor="let emp of employees" [nzValue]="emp.emple_id" [nzLabel]="emp.emple_nm"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      

      <div nz-col [nzSpan]="24" class="form-col">
        <nz-form-item>
          <nz-form-label nzFor="file_upload">File Attachment</nz-form-label>
          <div class="file-upload-container">
            <input (change)="handleInputChange($event)" type="file" accept=".png,.jpg,.jpeg" #fileInput />
            <small class="file-hint">Accepted formats: PNG, JPG, JPEG</small>
          </div>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="24" class="form-col">
        <nz-form-item *ngIf="attachments && attachments.length > 0">
          <nz-form-label>Attachments Preview</nz-form-label>
          <div class="attachment-preview">
            <div class="image-container" *ngFor="let attachment of attachments; let i = index">
              <img class="w-15" [src]="attachment.base64" alt="Attachment Preview" />
              <button class="remove-button" nz-button nzType="text" nzDanger (click)="removeAttachment(i)">
                <i nz-icon nzType="close-circle" nzTheme="fill"></i>
              </button>
            </div>
          </div>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="24" class="form-col">
        <nz-form-item>
          <nz-form-label [nzRequired]="true" nzFor="description">Description</nz-form-label>
          <nz-form-control nzErrorTip="Please enter a description (max 500 characters)">
            <textarea nz-input id="description" formControlName="description" 
              placeholder="Enter complaint description" [nzAutosize]="{ minRows: 3, maxRows: 5 }">
            </textarea>
          </nz-form-control>
        </nz-form-item>
      </div>



      <!-- [disabled]="!mainForm.valid || settings.isBtnLoading" -->


    </div>
    <div class="form-actions">
      <button nz-button nzType="default" (click)="closeForm()">Cancel</button>
      <button nz-button nzType="primary" [disabled]="!mainForm.valid || settings.isBtnLoading" (click)="submitComplaint()"
        [nzLoading]="settings.isBtnLoading">
        <span nz-icon nzType="send" nzTheme="outline"></span>
        Submit
      </button>
    </div>
  </form>
</div>