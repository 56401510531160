import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Location } from '@angular/common';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit {
  shwDeleteCnfrm: boolean;
  constructor(
    private fb: FormBuilder,
    private themeService: ThemeConstantService,
    public apiSrv: AppsService,
    public atmSrv: AtomServiceService,
    private message: NzMessageService,
    private modal: NzModalService,
    private location: Location,
    private nzMessageService: NzMessageService
  ) { }

  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  columnDefs;
  mainMessage;
  loader;
  gridData;
  shwSidebar;
  sidebarHeading;
  countryForm: FormGroup;
  shwLdng;
  editdata;
  delData;
  delete: boolean = false;

  ngOnInit() {
    this.countryForm = new FormGroup({
      cntry_nm: new FormControl('')
    });
    this.getGridData();
  }

  getGridData() {
    this.mainMessage = null;
    this.loader = true;
    this.usrAcsErrMsg = false;


    this.apiSrv.get('/core/api/master/countries/list').subscribe((res) => {
      this.loader = false;
      if (res['status'] == 200) {
        this.gridData = res['data'];
      } else {
        // this.mainMessage = { msg: res.message, type: 'error' };


      }
    }, error => {
      this.loader = false;
      this.mainMessage = { msg: error.message, type: 'error' };
    });
  }


  editRecord(data) {
    this.editdata = data;
    this.shwSidebar = true;
    this.sidebarHeading = 'Edit Country';
    this.shwDeleteCnfrm = false;

    this.countryForm.patchValue({
      cntry_nm: data.cntry_nm
    });
  }

  deleteRecord(data) {
    this.delData = data;
    // this.delete = true;
    this.shwSidebar = true;
    
    this.countryForm.patchValue({
      cntry_nm: data.cntry_nm
    });
    this.shwDeleteCnfrm = true;
  }

  confirmDelete() {
    this.shwLdng = true;
    this.apiSrv.delete(`core/api/master/countries/${this.delData.cntry_id}`).subscribe((res) => {
      this.shwLdng = false;
      if (res['status'] == 200) {

        this.nzMessageService.success('Country deleted successfully');
        this.getGridData();
        this.delete = false;
      } else {
        this.nzMessageService.error(res['message']);
      }
    }, error => {
      this.shwLdng = false;
      this.nzMessageService.error(error.message);
    });
  }

  submitForm() {
    if (this.countryForm.valid) {
      this.shwLdng = true;
      if (this.editdata) {
        console.log(this.editdata)

        this.apiSrv.put(this.countryForm.value, `core/api/master/countries/${this.editdata.cntry_id}`).subscribe((res) => {
          this.shwLdng = false;

          if (res['status'] == 200) {

            this.nzMessageService.success('Country updated successfully');
            this.getGridData();
            this.closeSidebar();
          } else {
            this.nzMessageService.error(res['message']);
          }
        }, error => {
          this.shwLdng = false;
          this.nzMessageService.error(error.message);
        });
      } else {
        this.apiSrv.post( this.countryForm.value, `core/api/master/countries`).subscribe((res) => {
          this.shwLdng = false;
          if (res['status'] == 200) {

            this.nzMessageService.success('Country added successfully');
            this.getGridData();
            this.closeSidebar();
          } else {
            this.nzMessageService.error(res['message']);
          }
        }, error => {
          this.shwLdng = false;
          this.nzMessageService.error(error.message);
        });
      }
    }
  }

  closeSidebar() {
    this.shwSidebar = false;
    this.editdata = null;
    this.countryForm.reset();
  }


  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add Country',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }


  openSideBar(opt) {
    console.log(opt)
    this.shwDeleteCnfrm = false
    console.log(this.shwSidebar)
    this.countryForm.reset();
   
    this.shwSidebar = true;

    }
  
}
