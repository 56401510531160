<nz-modal
  [(nzVisible)]="visible"
  [nzTitle]="modalTitle"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  [nzWidth]="520"
  [nzCentered]="true"
  [nzBodyStyle]="{ padding: '24px' }"
  [nzOkText]="'Apply Filters'"
>
  <ng-template #modalTitle>
    <div class="modal-title">
      <span class="title-text">Filter Tickets</span>
      <button nz-button nzType="link" class="reset-button" (click)="resetFilters()">
        <i nz-icon nzType="undo" nzTheme="outline"></i>
        Reset
      </button>
    </div>
  </ng-template>

  <div *nzModalContent class="filter-content">
    <div class="filter-section">
      <h4 class="section-title">
        <i nz-icon nzType="flag" nzTheme="outline"></i>
        Status
      </h4>
      <nz-select
        [(ngModel)]="selectedStatus"
        [nzMode]="'multiple'"
        nzPlaceHolder="Select statuses"
        class="w-full custom-select"
      >
        <nz-option
          *ngFor="let status of statuses"
          [nzLabel]="status"
          [nzValue]="status"
          class="status-option"
        >
          <span class="status-label">{{ status }}</span>
        </nz-option>
      </nz-select>
    </div>

    <div class="filter-section">
      <h4 class="section-title">
        <i nz-icon nzType="folder" nzTheme="outline"></i>
        Complaint Type
      </h4>
      <nz-select
        [(ngModel)]="selectedTypes"
        [nzMode]="'multiple'"
        nzPlaceHolder="Select complaint types"
        class="w-full custom-select"
      >
        <nz-option
          *ngFor="let type of complaintTypes"
          [nzLabel]="type"
          [nzValue]="type"
          class="type-option"
        >
          <span class="type-label">{{ type }}</span>
        </nz-option>
      </nz-select>
    </div>
  </div>
</nz-modal>
