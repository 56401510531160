import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Location } from '@angular/common';

@Component({
  selector: 'app-vndr-services',
  templateUrl: './vndr-services.component.html',
  styleUrls: ['./vndr-services.component.scss']
})
export class VndrServicesComponent implements OnInit {
  shwDeleteCnfrm: boolean;
  constructor(
    private fb: FormBuilder,
    private themeService: ThemeConstantService,
    public apiSrv: AppsService,
    public atmSrv: AtomServiceService,
    private message: NzMessageService,
    private modal: NzModalService,
    private location: Location,
    private nzMessageService: NzMessageService
  ) { }

  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  columnDefs;
  mainMessage;
  loader;
  gridData;
  shwSidebar;
  sidebarHeading;
  serviceForm: FormGroup;
  shwLdng;
  editdata;
  delData;
  delete: boolean = false;

  ngOnInit() {
    this.serviceForm = new FormGroup({
      srvce_nm: new FormControl('')
    });
    this.getGridData();
  }

  getGridData() {
    this.mainMessage = null;
    this.loader = true;
    this.apiSrv.get('/core/api/master/vendor-services/list').subscribe((res) => {
      this.loader = false;
      if (res['status'] == 200) {
        this.gridData = res['data'];
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add Service',
        onClick: this.addNew.bind(this)
      }
    });
  }

  addNew() {
    this.editdata = null;
    this.shwDeleteCnfrm = false;
    this.shwSidebar = true;
    this.sidebarHeading = "Add Service";
    this.serviceForm.reset();
  }

  editRecord(data) {
    this.editdata = data;
    this.shwDeleteCnfrm = false;

    this.shwSidebar = true;


    this.sidebarHeading = "Edit Service";
    this.serviceForm.patchValue({
      srvce_nm: data.srvce_nm
    });
  }

  deleteRecord(data) {
    this.delData = data;
    this.shwDeleteCnfrm = true;
  }

  closeSidebar() {
    this.shwSidebar = false;
  }

  submitForm() {
    if (this.serviceForm.valid) {
      this.shwLdng = true;
      let params: any = {
        srvce_nm: this.serviceForm.value.srvce_nm
      };

      if (this.editdata) {
        params.srvce_id = this.editdata.srvce_id;
        this.apiSrv.put(this.serviceForm.value, `core/api/master/vendor-services/${this.editdata.srvce_id}`).subscribe((res) => {
          this.shwLdng = false;
          if (res['status'] == 200) {
            this.message.success(res['message']);
            this.shwSidebar = false;
            this.getGridData();
          }
        });
      } else {
        this.apiSrv.post(this.serviceForm.value, 'core/api/master/vendor-services').subscribe((res) => {
          this.shwLdng = false;
          if (res['status'] == 200) {
            this.message.success(res['message']);
            this.shwSidebar = false;
            this.getGridData();
          }
        });
      }
    } else {
      Object.values(this.serviceForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  confirmDelete() {
    this.shwLdng = true;
    this.apiSrv.delete(`core/api/master/vendor-services/${this.delData.srvce_id}`).subscribe((res) => {
      this.shwLdng = false;
      if (res['status'] == 200) {
        this.message.success(res['message']);
        this.shwDeleteCnfrm = false;
        this.getGridData();
      }
    });
  }

  cancelDelete() {
    this.shwDeleteCnfrm = false;
  }
}
