import { Injectable } from "@angular/core";
import { Socket } from 'ngx-socket-io';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class CstmSocketIOService {
    socket_id;

    constructor(private socket: Socket, private notificationService: NzNotificationService, private router: Router,private http: HttpClient,) {
        this.initSocketIOCnct();
        this.listenForNotifications();
    }

    // initSocketIOCnct() {
    //     this.socket.on('connect', () => {
    //         console.log(this.socket.ioSocket);
    //         if (this.socket.ioSocket.id) {
    //             this.socket_id = this.socket.ioSocket.id;
    //             console.log(this.socket_id)
    //         }
    //     });

    //     if (this.socket.ioSocket.id) {
    //         this.socket_id = this.socket.ioSocket.id;
    //         console.log(this.socket_id)
    //     }
    // }

    // getSocketIOSessionID() {
    //     return this.socket_id;
    // }
    initSocketIOCnct() {
        this.socket.on('connect', () => {
            this.socket_id = this.socket.ioSocket.id;
            console.log('Socket connected with ID:', this.socket_id);
            this.saveScktIdToUser();

        });
    }
    
    getSocketIOSessionID(): Observable<string> {
        return new Observable(observer => {
            if (this.socket.ioSocket.id) {
                observer.next(this.socket.ioSocket.id);
            } else {
                this.socket.on('connect', () => {
                    observer.next(this.socket.ioSocket.id);
                });
            }
        });
    }
    onConnect(callback: () => void) {
        this.socket.on('connect', callback);
    }

    listenForNotifications() {
        return new Observable(observer => {
            this.socket.on('vehicleStopped', (data) => {
                observer.next(data);
    
                // Show browser notification
                // this.notificationService.blank(
                //     'Vehicle Stopped',
                //     `${data.alert_tx}`
                // ).onClick.subscribe(() => {
                //     this.router.navigate(['/internal/notifications/list']);
                // });
    
                // Uncomment if navigation is required
                // .onclick = () => {
                //     this.router.navigate(['/internal/notifications/list']);
                // };
            });
    
            // Cleanup on unsubscribe
            return () => this.socket.off('vehicleStopped');
        });
    }
    saveScktIdToUser() {
        console.log(this.socket_id);
        if (this.socket_id != undefined) {
            this.http.post('/alerts/user/save-socket-id', { sckt_id:this.socket_id }).subscribe(response => {
                console.log('Socket ID saved:', response);
            });
        }
    }
}