<div class="flex flex-col flex-auto w-full">
  <div class="container">
    <div class="top-header">
      <div class="page-title">OutWard Branch Inventory List</div>
      <div class="header-right">
        <div class="breadcrumb">
          <span>Home</span>
          <span class="separator">/</span>
          <span>Inventory</span>
          <span class="separator">/</span>
          <span>OutWard Branch Inventory List</span>
        </div>
        <button class="go-back-btn" (click)="goBack()">Go Back</button>
      </div>
    </div>

    <div class="sub-header">
      <div class="label">OutWard Branch Inventory List</div>
      <button class="export-btn" (click)="exportToExcel()">Export to Excel</button>
    </div>

    <div class="table-container">
      <div class="table-controls">
        <div class="entries-select">
          Show
          <dx-select-box 
            [items]="[10, 20, 50]"
            [(value)]="pageSize"
            (onValueChanged)="onPageSizeChange($event)">
          </dx-select-box>
          entries
        </div>
       
      </div>

      <dx-data-grid
        [dataSource]="listOfData"
        [showBorders]="true"
        [paging]="{ pageSize: pageSize }"
        [pager]="{ showPageSizeSelector: true, allowedPageSizes: [10, 20, 50], showInfo: true }"
        [columnAutoWidth]="true"
        [searchPanel]="{ visible: true, placeholder: 'Search...' }"
        [sorting]="{ mode: 'multiple' }"
        [showColumnLines]="true"
        [showRowLines]="true">
        
        <dxo-paging [enabled]="true"></dxo-paging>
        <dxo-pager 
          [showInfo]="true" 
          [showPageSizeSelector]="true" 
          [allowedPageSizes]="[10, 20, 50]">
        </dxo-pager>

        <dxi-column dataField="sno" caption="S.No" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="fromBranch" caption="From Branch" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="toBranch" caption="To Branch" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="delivery" caption="Delivery" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="detail" caption="Detail" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="challanNo" caption="Challan No" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="category" caption="Category" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="inventory" caption="Inventory" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="make" caption="Make" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="model" caption="Model" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="serial" caption="Serial" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="qty" caption="Qty" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="macAddress" caption="MAC Address" [allowSorting]="true"></dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>
