import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-complaint-filter-modal',
  templateUrl: './complaint-filter-modal.component.html',
  styleUrls: ['./complaint-filter-modal.component.scss']
})
export class ComplaintFilterModalComponent {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() filterApplied = new EventEmitter<any>();

  selectedStatus: string[] = [];
  selectedTypes: string[] = [];

  complaintTypes = [
    'OLT-Operator',
    'MHQ',
    'OLT',
    'NOC',
    'ZHQ',
    'Power Infra',
    'DHQ',
    'Enterprise',
    'SS',
    'Fiber'
  ];

  statuses = [
   'Closed',
   'Open',
  ];

  handleCancel(): void {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  handleOk(): void {
    this.filterApplied.emit({
      status: this.selectedStatus,
      types: this.selectedTypes
    });
    this.handleCancel();
  }

  resetFilters(): void {
    this.selectedStatus = [];
    this.selectedTypes = [];
  }
}
