import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuardService } from './apps/auth/auth-guard.service';
import { FullscreenAppComponent } from './layouts/fullscreen-app/fullscreen-app.component';
import { LayoutAppComponent } from './layouts/layout-app/layout-app.component';
import { TimelineModule } from './apps/timeline/timeline.module';



const appRoutes: Routes = [
    { path: 'internal', component: FullscreenAppComponent, loadChildren: () => import('./apps/auth/auth.module').then(m => m.AuthModule), data: { full_screen: true } },
    { path: 'internal/reports', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/reports/reports.module').then(m => m.ReportsModule) },
    { path: 'internal/crm', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/crm/crm.module').then(m => m.CrmModule) },
    { path: 'internal/support', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/support/support.module').then(m => m.SupportModule) },
    { path: 'internal/clients', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/clients/clients.module').then(m => m.ClientsModule) },
    // { path: 'internal/map', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/maps/maps.module').then(m => m.MapsModule) },
    // { path: 'internal/map2', canActivate: [AuthGuardService], loadChildren: () => import('./apps/maps/maps.module').then(m => m.MapsModule) },
    { path: 'internal/setup', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/setup/setup.module').then(m => m.SetupModule) },
    { path: 'internal/timeline', component: TimelineModule, canActivate: [AuthGuardService], loadChildren: () => import('./apps/timeline/timeline.module').then(m => m.TimelineModule) },
    // { path: 'internal/owms', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/dashboards/dashboard.module').then(m => m.DashboardModule) },
    // { path: 'internal/sachivalayam', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/dashboards/dashboard.module').then(m => m.DashboardModule) },
    // { path: 'internal/wardsachivalayam', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/dashboards/dashboard.module').then(m => m.DashboardModule) },
    { path: 'internal/dashboard', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/dashboards/dashboard.module').then(m => m.DashboardModule) },
    { path: 'internal/help', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/help-docs/help-docs.module').then(m => m.HelpDocsModule) },
    { path: 'internal/settings', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/settings/settings/settings.module').then(m => m.SettingsModule) },
    { path: 'internal/pages', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
    // { path: 'internal/app-store', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/app-store/app-store.module').then(m => m.AppStoreModule) },
    { path: 'internal/play-back', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/play-back/play-back.module').then(m => m.PlayBackModule) },
    { path: 'internal/live-tracking', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/live-tracking/live-tracking.module').then(m => m.LiveTrackingModule) },
    { path: 'internal/schedule-trips', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/schedule-trips/schedule-trips.module').then(m => m.ScheduleTripsModule) },
    { path: 'internal/ams_vehicles', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/ams-vehicles/ams-vehicles.module').then(m => m.AmsVehiclesModule) },
    { path: 'internal/drivers', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/drivers/drivers.module').then(m => m.DriversModule) },
    { path: 'internal/billing', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/billing/billing.module').then(m => m.BillingModule) },
    { path: 'internal/heatmap', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/heatmap/heatmap.module').then(m => m.HeatmapModule) },
    { path: 'internal/fuellog', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/fuellog/fuellog.module').then(m => m.FuellogModule) },
    { path: 'internal/logbk', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/logbook/logbook.module').then(m => m.LogbookModule) },
    { path: 'internal/vms', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/vms-monitoring/vms-monitoring.module').then(m => m.VmsMonitoringModule) },
    { path: 'internal/owms', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/owms/owms.module').then(m => m.OwmsModule) },
    { path: 'internal/locations', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/location/location.module').then(m => m.LocationModule) },
    { path: 'internal/bulk-upload', component: LayoutAppComponent, loadChildren: () => import('./apps/bulk-upload/bulk-upload.module').then(m => m.BulkUploadModule) },
    { path: 'internal/weighment', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/weighment/weighment.module').then(m => m.WeighmentModule) },
    // { path: 'internal/maps', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/map-editor/map-editor.module').then(m => m.MapEditorModule) },
    { path: 'internal/asset-tracking', component: LayoutAppComponent,  loadChildren: () => import('./apps/asset-tracking/asset-tracking.module').then(m => m.AssetTrackingModule) },
    // { path: 'internal/election', component: LayoutAppComponent,  loadChildren: () => import('./apps/election/election.module').then(m => m.ElectionModule) },
    // { path: 'internal/paidnews', component: LayoutAppComponent,  loadChildren: () => import('./apps/paid-news/paid-news.module').then(m => m.PaidNewsModule) },
    { path: 'internal/alerts', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/alerts/alerts.module').then(m => m.AlertsModule) },
    { path: 'internal/hrms', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/dashboards/apsfl/payroll/payroll.module').then(m => m.PayrollModule) },
    { path: 'internal/inventory-management', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/inventory-management/inventory-management.module').then(m => m.InventoryManagementModule) },
    { path: 'internal/notifications', component: LayoutAppComponent, canActivate: [AuthGuardService], loadChildren: () => import('./apps/push-notifications/push-notifications.module').then(m => m.PushNotificationsModule) },

    
    // Complaints Routes
    // {
    //   path: 'internal/complaints',
    //   component: LayoutAppComponent,
    //   canActivate: [AuthGuardService],
    //   loadChildren: () => import('./apps/dashboards/apsfl/complaint-management/complaint-management.module').then(m => m.ComplaintsManagementModule)
    // },

   {
      path: 'internal/complaints',
      component: LayoutAppComponent,
      canActivate: [AuthGuardService],
      loadChildren: () => import('./apps/Complaint-Management/complaint-management.module').then(m => m.ComplaintManagementModule)
    },




    //    {
    //   path: 'internal/complaints',
    //   component: LayoutAppComponent,
    //   canActivate: [AuthGuardService],
    //   loadChildren: () => import('./apps/dashboards/apsfl/complaints-management/complaints-management.module').then(m => m.complaintsManagementModule)
    // },
    { path: '**', redirectTo: 'internal' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            useHash: false,
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
