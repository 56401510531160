<div class="relative flex flex-col w-full min-w-0">
  <!-- Top bar with title -->
  <div class="flex-0">
    <div class="flex items-center">
      <div class="font-semibold">Complaint Tickets</div>
    </div>
  </div>

  <div class="main-container">
    <div class="complaint-container">
      <!-- Tabs -->
      <nz-tabset class="custom-tabs" [(nzSelectedIndex)]="selectedTabIndex"
        (nzSelectedIndexChange)="onTabChange($event)">
        <!-- Assigned Tickets Tab -->
        <nz-tab [nzTitle]="assignedTitleTpl">
          <ng-template #assignedTitleTpl>
            <div class="tab-title">Assigned Tickets({{assignedTickets.length}})</div>
          </ng-template>

          <div class="tab-content">
            <div class="search-container">
              <nz-input-group [nzSuffix]="suffixTemplate">
                <input type="text" nz-input [ngModel]="searchText" (ngModelChange)="onSearchTextChange($event)"
                  placeholder="Search by ticket ID, type, status..." />
              </nz-input-group>
              <ng-template #suffixTemplate>
                <div class="suffix-icons">
                  <i nz-icon nzType="filter" class="filter-icon" (click)="showFilterModal()"></i>
                  <i nz-icon nzType="search"></i>
                </div>
              </ng-template>
            </div>

            <div class="status-notations">
              <div class="status-item" [class.active]="assignedFilter === 'all'" (click)="onAssignedFilterChange('all')">
                <span class="color-box all"></span>
                <span class="status-text">All ({{getStatusCount('all')}}) </span>
              </div>
              <div class="status-item" [class.active]="assignedFilter === 'pending'" (click)="onAssignedFilterChange('pending')">
                <span class="color-box pending"></span>
                <span class="status-text">Pending ({{getStatusCount('pending')}}) </span>
              </div>
              <div class="status-item" [class.active]="assignedFilter === 'completed'" (click)="onAssignedFilterChange('completed')">
                <span class="color-box completed"></span>
                <span class="status-text">Completed ({{getStatusCount('completed')}}) </span>
              </div>
            </div>

            <div class="tickets-container">
              <div *ngIf="loading" class="loading-spinner">
                <nz-spin nzSize="large"></nz-spin>
              </div>

              <div *ngIf="error" class="error-message">
                {{ error }}
                <button nz-button nzType="primary" (click)="getComplaintList()">Retry</button>
              </div>

              <div *ngIf="!loading && !error" class="scrollable-tickets-list">
                <nz-list [nzDataSource]="getPaginatedTicketsAssigned()">
                  <ng-container *ngFor="let ticket of getPaginatedTicketsAssigned()">
                    <nz-list-item class="" [ngClass]="{'selected': selectedTicket?.tckt_id === ticket.tckt_id}"
                      (click)="selectTicket(ticket)">
                      <div class="ticket-card relative overflow-hidden">
                        <div class="absolute" [ngClass]="ticket.assgn_sts=='pending'? 'strip-pndg' : 'strip-cmpltd'">

                        </div>
                        <div class="ticket-header">
                          <span class="ticket-id">
                            <ng-container *ngIf="ticket.tckt_id; else pendingTicket">
                              Ticket #{{ ticket.tckt_id }}
                            </ng-container>
                            <ng-template #pendingTicket>
                              <span class="pending">Ticket Pending</span>
                            </ng-template>
                          </span>
                          <nz-tag [class]="'status-' + (ticket.wrk_sts_nm || 'pending').toLowerCase()">
                            {{ ticket.wrk_sts_nm || 'Pending' }}
                          </nz-tag>
                        </div>
                        <div class="ticket-details">
                          <!-- <div class="detail-row">
                            <span class="label">Priority:</span>
                            <span class="value">{{ ticket.priority }}</span>
                          </div> -->
                          <div class="detail-row">
                            <span class="label">Complaint Type:</span>
                            <span class="value">{{ ticket.wrk_grp_nm }}</span>
                          </div>
                          <div class="detail-row">
                            <span class="label">Complaint Category:</span>
                            <span class="value">{{ ticket.wrk_typ_nm }}</span>
                          </div>
                          <div class="detail-row" *ngIf="ticket.currentemp">
                            <span class="label">Assign To:</span>
                            <span class="value">{{ ticket.currentemp }}</span>
                          </div>
                        </div>
                      </div>
                    </nz-list-item>
                  </ng-container>
                </nz-list>

                <nz-pagination *ngIf="getTotalPagesAssigned() > 1" [nzPageIndex]="getCurrentPageAssigned()"
                  [nzTotal]="getFilteredTicketsLengthAssigned()" [nzPageSize]="pageSize"
                  (nzPageIndexChange)="onPageChangeAssigned($event)"></nz-pagination>
              </div>
            </div>
          </div>
        </nz-tab>

        <!-- Created Tickets Tab -->
        <nz-tab [nzTitle]="createdTitleTpl">
          <ng-template #createdTitleTpl>
            <div class="tab-title">Created Tickets({{createdTickets.length}})</div>
          </ng-template>

          <div class="tab-content">
            <div class="search-container">
              <nz-input-group [nzSuffix]="suffixTemplate2">
                <input type="text" nz-input [ngModel]="searchText" (ngModelChange)="onSearchTextChange($event)"
                  placeholder="Search by ticket ID, type, status..." />
              </nz-input-group>
              <ng-template #suffixTemplate2>
                <div class="suffix-icons">
                  <i nz-icon nzType="filter" class="filter-icon" (click)="showFilterModal()"></i>
                  <i nz-icon nzType="search"></i>
                </div>
              </ng-template>
            </div>

            <div class="tickets-container">
              <div *ngIf="loading" class="loading-spinner">
                <nz-spin nzSize="large"></nz-spin>
              </div>

              <div *ngIf="error" class="error-message">
                {{ error }}
                <button nz-button nzType="primary" (click)="getComplaintList()">Retry</button>
              </div>

              <div *ngIf="!loading && !error" class="scrollable-tickets-list">
                <nz-list [nzDataSource]="getPaginatedTicketsCreated()">
                  <ng-container *ngFor="let ticket of getPaginatedTicketsCreated()">
                    <nz-list-item [ngClass]="{'selected': selectedTicket?.tckt_id === ticket.tckt_id}"
                      (click)="selectTicket(ticket)">
                      <div class="ticket-card">
                        <div class="ticket-header">
                          <span class="ticket-id">
                            <ng-container *ngIf="ticket.tckt_id; else pendingTicket">
                              Ticket #{{ ticket.tckt_id }}
                            </ng-container>
                            <ng-template #pendingTicket>
                              <span class="pending">Ticket Pending</span>
                            </ng-template>
                          </span>
                          <nz-tag [class]="'status-' + (ticket.wrk_sts_nm || 'pending').toLowerCase()">
                            {{ ticket.wrk_sts_nm || 'Pending' }}
                          </nz-tag>
                        </div>
                        <div class="ticket-details">
                          <!-- <div class="detail-row">
                            <span class="label">Priority:</span>
                            <span class="value">{{ ticket.priority }}</span>
                          </div> -->
                          <div class="detail-row">
                            <span class="label">Complaint Type:</span>
                            <span class="value">{{ ticket.wrk_grp_nm }}</span>
                          </div>
                          <div class="detail-row">
                            <span class="label">Complaint Category:</span>
                            <span class="value">{{ ticket.wrk_typ_nm }}</span>
                          </div>
                          <div class="detail-row" *ngIf="ticket.currentemp">
                            <span class="label">Assign To:</span>
                            <span class="value">{{ ticket.currentemp }}</span>
                          </div>
                          <!-- <div class="detail-row">
                            <span class="label">Ticket Type:</span>
                            <span class="value">{{ ticket.tckt_typ_nm }}</span>
                          </div> -->
                        </div>
                      </div>
                    </nz-list-item>
                  </ng-container>
                </nz-list>

                <nz-pagination *ngIf="getTotalPagesCreated() > 1" [nzPageIndex]="getCurrentPageCreated()"
                  [nzTotal]="getFilteredTicketsLengthCreated()" [nzPageSize]="pageSize"
                  (nzPageIndexChange)="onPageChangeCreated($event)"></nz-pagination>
              </div>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>

      <button nz-button nzType="primary" class="fab-button" (click)="newComplaintClicked.emit()">
        <i nz-icon nzType="plus"></i>
        <span class="fab-label">New Complaint</span>
      </button>
    </div>
  </div>
</div>

<app-complaint-filter-modal
  [(visible)]="isFilterModalVisible"
  (filterApplied)="applyFilters($event)">
</app-complaint-filter-modal>