import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserOutline, CalendarOutline, MessageOutline, SwapRightOutline, ClockCircleOutline } from '@ant-design/icons-angular/icons';
import { NzIconService } from 'ng-zorro-antd/icon';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { set } from 'date-fns';

@Component({
  selector: 'app-complaint-details',
  templateUrl: './complaint-details.component.html',
  styleUrls: ['./complaint-details.component.scss']
})
export class ComplaintDetailsComponent implements OnChanges, OnInit {
  @Input() selectedTicket: any;
  @Output() complaintSubmitted = new EventEmitter<void>();
  ticketDtls: any[] = [];
  loading = false;
  error: string | null = null;
  timelineData: any[] = [];
  attachments: any[] = [];
  settings = {
    entity: "Complaint",
    sidebarHeading: "",
    shwLdg: false,
    shwerrmsg: false,
    showAlert: false,
    shwMasterEditDiv: false,
    shwSidebar: false,
    shwMasterDelDiv: false,
    in_delete_mode: false,
    usrAcsErrMsg: false,
    errMsg: null,
    isBtnLoading: false,
    shwGrd: false,
  }

  // timelineData = [
  //   {
  //     assgn_empl_id: 373,
  //     frwd_empl_id: "Tariki suresh kumar",
  //     assgn_empl_nm: "Bonda Prasad",
  //     assgn_ts: "13-12-24y",
  //     frwd_ts: "13-12-24y",
  //     stg_cmnt_tx: "Initial complaint registration and assignment",
  //     avatar: "assets/images/avatars/avatar-1.jpg",
  //     frwd_avatar: "assets/images/avatars/avatar-2.jpg",
  //     status_color: "#1890ff"
  //   },
  //   {
  //     assgn_empl_id: 374,
  //     frwd_empl_id: "Ravi Kumar",
  //     assgn_empl_nm: "Tariki suresh kumar",
  //     assgn_ts: "13-12-24y",
  //     frwd_ts: "13-12-24y",
  //     stg_cmnt_tx: "Investigation in progress - Found network connectivity issues",
  //     avatar: "assets/images/avatars/avatar-2.jpg",
  //     frwd_avatar: "assets/images/avatars/avatar-3.jpg",
  //     status_color: "#52c41a"
  //   },
  //   {
  //     assgn_empl_id: 375,
  //     frwd_empl_id: "Technical Team",
  //     assgn_empl_nm: "Ravi Kumar",
  //     assgn_ts: "13-12-24y",
  //     frwd_ts: "13-12-24y",
  //     stg_cmnt_tx: "Escalated to technical team for further analysis",
  //     avatar: "assets/images/avatars/avatar-3.jpg",
  //     frwd_avatar: "assets/images/avatars/avatar-4.jpg",
  //     status_color: "#52c41a"
  //   }
  // ];
  isDrawerVisible: boolean;
  assgnForm: FormGroup;
  fnlImgs: any;
  districtLst: any;
  designation: any;
  employees: any;
  workStatus: any;
  isCompletedStatus = false;
  isStateAdmin = false;
  fl_type: any;
  fl_nm: any;
  assgbBtnEnbled: any;

  constructor(private _formBuilder: FormBuilder, private nzMessageService: NzMessageService,
    private iconService: NzIconService,
    private apiSrvc: AppsService,
    private message: NzMessageService
  ) {
    this.iconService.addIcon(
      UserOutline,
      CalendarOutline,
      MessageOutline,
      SwapRightOutline,
      ClockCircleOutline
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('nextttttttttttttttttttttt',this.selectedTicket);
    if (changes['selectedTicket'] && changes['selectedTicket'].currentValue) {
      this.assgbBtnEnbled =this.selectedTicket?.isAssignedTab ;
      this.getticketdetails() ;
    }
  }

  ngOnInit(): void {
    this.loadWorkStatus();
    this.loadDesignations();

    this.assgnForm = this._formBuilder.group({
      wrk_sts: ['', Validators.required],
      emple_dsgns_id: ['', Validators.required],
      // dstrct_id: ['', Validators.required],
      assigned_employee: ['', Validators.required],
      description: ['', Validators.required],
      attachments: [null]
    });
  }

  getticketdetails() {
    this.loading = true;
    console.log('Fetching details for ticket ID:', this.selectedTicket.tckt_id);
    this.apiSrvc.get('complaint/ticket/' + this.selectedTicket.tckt_id).subscribe(
      (res: any) => {
        this.loading = false;
        if (res.status === 200) {
          // Ensure ticketDtls is properly assigned
          if (res.data && res.data[0] && Array.isArray(res.data[0])) {
            this.ticketDtls = res.data[0];
          } else {
            this.ticketDtls = [res.data[0]];
          }
          
          // Log the description for debugging
          console.log('Ticket Description:', this.ticketDtls[0]?.oms_wrm_dsc_tx);
          
          // Assign timeline data
          this.timelineData = Array.isArray(res.data[1]) ? res.data[1] : [];
        } else {
          this.error = res.message || 'Error fetching ticket details';
          this.message.error(this.error);
        }
      },
      (error) => {
        this.loading = false;
        this.error = 'API error';
        this.message.error(this.error);
        console.error('API error:', error);
      }
    );
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    const isImage = file.type?.startsWith('image/');
    if (!isImage) {
      this.nzMessageService.error('You can only upload image files!');
      return false;
    }
    const isLt2M = file.size! / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.nzMessageService.error('Image must be smaller than 2MB!');
      return false;
    }
    this.fnlImgs.push(file);
    return false;
  };

  getDistricts(): void {
    this.apiSrvc.get('complaint/districts').subscribe(
      (res: any) => {
        this.districtLst = res['data'] || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load districts');
      }
    );
  }

  loadDesignations(): void {
    this.apiSrvc.get('complaint/designations').subscribe(
      (res: any) => {
        this.designation = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load designations');
      }
    );
  }

  loadEmployees(designationId: string): void {
    if (!designationId) return;

    // // Check if state admin is selected (ID: 4)
    // this.isStateAdmin = designationId == '4';

    // if (this.isStateAdmin) {
    //   // For state admin, disable and hide district field
    //   this.assgnForm.get('dstrct_id').disable();
    //   this.assgnForm.get('dstrct_id').setValue(null);

      // Load employees directly for state admin without district
      this.apiSrvc.get(`complaint/employees/${designationId}`).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.employees = res.data;
          } else {
            this.message.error(res.message || 'Error loading employees');
          }
        },
        (error) => {
          this.message.error('Failed to load employees');
        }
      );
  //   } else {
  //     setTimeout(() => {
  //       this.getDistricts();
  //     }, 1000);
  //     // For other roles, enable district field
  //     this.assgnForm.get('dstrct_id').enable();
  //     this.assgnForm.get('dstrct_id').setValidators([Validators.required]);
  //     this.assgnForm.get('dstrct_id').updateValueAndValidity();

  //     // Only load employees if district is selected
  //     if (this.assgnForm.value.dstrct_id) {
  //       this.apiSrvc.get(`complaint/employees/${this.assgnForm.value.dstrct_id}/${this.assgnForm.value.emple_dsgns_id}`).subscribe(
  //         (res: any) => {
  //           if (res.status === 200) {
  //             this.employees = res.data;
  //           } else {
  //             this.message.error(res.message || 'Error loading employees');
  //           }
  //         },
  //         (error) => {
  //           this.message.error('Failed to load employees');
  //         }
  //       );
  //     }
  //   }
  }

  loadWorkStatus(): void {
    this.apiSrvc.get(`complaint/work-status`).subscribe(
      (res: any) => {
        this.workStatus = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load work statuses');
      }
    );
  }

  statusAction(event: any) {
    // Check if the selected status is "Completed" (status ID 6)
    this.isCompletedStatus = event === 12;

    if (this.isCompletedStatus) {
      // Disable and clear other fields when status is Completed
      this.assgnForm.get('emple_dsgns_id').disable();
      // this.assgnForm.get('dstrct_id').disable();
      this.assgnForm.get('assigned_employee').disable();

      // Clear the values
      this.assgnForm.patchValue({
        emple_dsgns_id: null,
        // dstrct_id: null,
        assigned_employee: null
      });
    } else {
      // Enable fields for other statuses
      this.assgnForm.get('emple_dsgns_id').enable();
      // this.assgnForm.get('dstrct_id').enable();
      this.assgnForm.get('assigned_employee').enable();
    }
  }

  private resetComponent(): void {
    // this.selectedTicket = null;
    // this.timelineData = [];
    this.attachments = [];
    this.assgnForm.reset();
    this.isDrawerVisible = false;
    this.settings.isBtnLoading = false;
  }

  submitComplaint(): void {

    this.assgnForm.get('attachments').setValue(this.attachments);

    console.log(this.assgnForm, 'satatataatatat');
    if (this.assgnForm.valid) {

      console.log(this.assgnForm, 'lllllllllllllllllllllll');
      this.settings.isBtnLoading = true;

      let postdata = {
        data: this.assgnForm.value,
      }
      postdata.data['tckt_id'] = this.selectedTicket.tckt_id

      this.apiSrvc.post(postdata, 'complaint/complaint/assign').subscribe(
        (res: any) => {
          if (res['status'] == 200) {
            this.nzMessageService.success('Complaint assigned successfully');
      
            
            this.assgbBtnEnbled=false
            this.complaintSubmitted.emit(); // Emit event to refresh tickets list

            setTimeout(() => {
              this.resetComponent(); // Reset component state
            this.getticketdetails()
            }, 1000);

          }

          this.settings.isBtnLoading = false;
        },
        (error) => {
          this.nzMessageService.error('Failed to assign complaint');
          this.settings.isBtnLoading = false;
        }
      );

    }
  }

  assignFunc() {
    this.isDrawerVisible = true
  }

  closeDrawer(): void {
    this.isDrawerVisible = false;
    this.assgnForm.reset();
  }

  handleInputChange(e) {

    console.log(e);
    let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

    console.log(files);
    for (var i = 0; i < files.length; i++) {
      console.log(files, "filesssssssssssssssssssssssssssssssss")
      let rd_file_type = files[i].type;

      if (rd_file_type == 'image/jpeg' || rd_file_type == 'image/png' || rd_file_type == 'image/jpg') {
        let file = files[i];
        this.fl_type = rd_file_type;
        this.fl_nm = file.name;
        let reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
      }
      else {

        this.nzMessageService.error('Please Upload Valid File');

      }

    }
  }
  _handleReaderLoaded(e) {
    console.log(e);
    var reader = e.target;
    this.attachments.push({ fl_type: this.fl_type, fl_name: this.fl_nm, base64: reader.result });
    console.log(this.attachments);

  }

  
  removeAttachment(index: number): void {
    this.attachments.splice(index, 1);
    // If this was the last attachment, clear the file input
    if (this.attachments.length === 0) {
      const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
    }
  }

  refreshDetails() {
    if (this.selectedTicket) {
      this.loading = true;
      this.error = null;
      const route = `complaint/ticket/${this.selectedTicket.tckt_id}`;
      this.apiSrvc.get(route).subscribe({
        next: (response: any) => {
          if (response && response.data) {
            this.ticketDtls = response.data;
            this.timelineData = response.timeline || [];
            this.attachments = response.attachments || [];
          }
          this.loading = false;
        },
        error: (error) => {
          console.error('Error fetching complaint details:', error);
          this.error = 'Failed to load complaint details. Please try again.';
          this.loading = false;
          this.message.error('Failed to load complaint details. Please try again.');
        }
      });
    }
  }
}
