import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreatePurchaseComponent } from './create-purchase/create-purchase.component';
import { AddInwardComponent } from './add-inward/add-inward.component';
import { InwardStocklistComponent } from './inward-stocklist/inward-stocklist.component';
import { OutwardBrnchInvntrylstComponent } from './outward-brnch-invntrylst/outward-brnch-invntrylst.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [

    { path: 'create-purchase', component: CreatePurchaseComponent, pathMatch: 'full' },
    { path: 'add-inward', component: AddInwardComponent, pathMatch: 'full' },
    { path: 'inward-stocklist', component: InwardStocklistComponent, pathMatch: 'full' },
    { path: 'outward-brnch-invntrylst', component: OutwardBrnchInvntrylstComponent, pathMatch: 'full' },
    { path: 'dashboard', component: DashboardComponent, pathMatch: 'full' },


];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InventoryManagementRoutingModule { }
