import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderDetails'
})
export class OrderDetailsPipe implements PipeTransform {
  private desiredOrder = [
    'Working', 
    'Not-Working', 
    'Spare', 
    'Damaged', 
    'AMC Completed'
  ];

  transform(details: {[key: string]: string}): {key: string, value: string}[] {
    // Convert object to array of key-value pairs
    const detailArray = Object.entries(details).map(([key, value]) => ({ key, value }));

    // Sort the array based on the desired order
    return detailArray.sort((a, b) => {
      const indexA = this.desiredOrder.indexOf(a.key);
      const indexB = this.desiredOrder.indexOf(b.key);
      
      // If both keys are in the desired order, use their indices
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
      
      // If one key is not in the desired order, push it to the end
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;
      
      // If neither key is in the desired order, maintain original order
      return 0;
    });
  }
}
