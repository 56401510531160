<ds-loading *ngIf="showLdr"></ds-loading>


<div class="page-header">
    <div class=" align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-6 col-md-6 mg-a">
                <h1 *ngIf="clntDtls && clntDtls.length > 0">
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
            <div class="col-lg-6 col-md-6 " style="text-align: right;">
                <span style="font-weight: 500">Select Date:</span>



        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)" style="width: auto; margin-right: 10px;    margin-left: 10px;"></nz-date-picker>
            <button (click)="refresh()" nz-button nzType="default" style="    box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
            color: #ffffff;
            font-weight: 500;
            background: #03A9F4;
            float: right;
            border-radius: 4px;"><span nz-icon nzType="reload" nzTheme="outline" style="color:#fff"></span><span
                    style="color:#fff"> Refresh</span></button>
            </div>
        </div>

    </div>
</div>


<div class="row hdrBar">
    <div class="col-lg-4 col-md-4 mg-a">
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
       
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;    margin-top: -25px;">
        <div class="Lst_rfrshd">Last Refreshed on: <span style="color: #ab5d4a;">{{refresh_tm}} </span>
           
        </div>
    </div>
</div>






<!-- <app-common-header [header_name] = "header_label"></app-common-header>
<div class="row hdrBar">
    <div class="col-lg-4 col-md-4 mg-a">
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
        <span>Select Date:</span>
        <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
            (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
    <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
        <div class="Lst_rfrshd" >Last Refreshed on: <span style="color: #ab5d4a;">{{refresh_tm}} </span>
            <button (click)="refresh()" nz-button nzType="default" style="    box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
            color: #ffffff;
            font-weight: 500;
            background: #03A9F4;
            float: right;
            border-radius: 4px;"><span nz-icon nzType="reload" nzTheme="outline" style="color:#fff"></span><span style="color:#fff"> Refresh</span></button></div>
    </div>
</div> -->
<div class="main">
    <div class="row">
        <ul class="cards">
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #5b4785 0%,#3a347a 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Total Vehicles</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.TOT_VEHICLES === null ? "0" : d.TOT_VEHICLES}}
                                </h1>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/pick-up-truck.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #443a7e;"></div>
                                <div class="cvrg_crd_cnt">{{d.TOT_VEHICLES === null ? "0" : d.TOT_VEHICLES}}</div>
                                <div class="cvrg_crd_lbl">Total Vehicles</div>
                            </div>
                            <!-- <div class="col-md-3 rgtpdng">
                                <div class="cvrg_crd_img">
                                    <img src="/assets/images/vehicle.png">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="    background: linear-gradient(135deg, #6b3a0b 0%,#ab5d4a 100%);">
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Operated Trips/Total Trips</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">{{d.OPERATED === null ? "0" : d.OPERATED}} / {{d.TOTAL_TRIPS === null ? "0" : d.TOTAL_TRIPS}}
                                </h1>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/routes.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #8e4d2d;"></div>
                                <div class="cvrg_crd_cnt">{{d.OPERATED === null ? "0" : d.OPERATED}} / {{d.TOTAL_TRIPS === null ? "0" : d.TOTAL_TRIPS}}<span style="font-size: 12px;"> ({{d.OPERATED*100/d.TOTAL_TRIPS | number:'1.2-4' }} %)</span></div>
                                <div class="cvrg_crd_lbl">Completed Trips/Total Trips</div>
                            </div>
                            <!-- <div class="col-md-3 rgtpdng">
                                <div class="cvrg_crd_img">
                                    <img src="/assets/images/trip.png">
                                </div>
                            </div> -->
                        </div>


                    </div>
                </div>
            </li>

            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="   background: linear-gradient(135deg, #665f03 0%,#817e26 100%);">
                    <div class="card_content">
                     

                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/rout.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #6c660b;"></div>
                                <!-- <div class="cvrg_crd_cnt">{{d.ROUTE_DEVIATED === null ? "0" : d.ROUTE_DEVIATED}} </div>
                                <div class="cvrg_crd_lbl">Route Deviated</div> -->
                                <div class="cvrg_crd_cnt">{{d.TOT_SVM === null ? "0" : d.TOT_SVM}}</div>
                                <div class="cvrg_crd_lbl">Total Wards</div>   
                            </div>
                            
                        </div>


                    </div>
                </div>
            </li>


            <li class="cards_item" *ngFor="let d of tdyCoveredTrips">
                <div class="card_g" style="background:linear-gradient(135deg, #288a63 0%,#267377 100%);">          
                    <div class="card_content">
                        <!-- <div class="row">
                            <h1 class="crdHdr">Coverage %</h1>
                        </div>
                        <div class="row">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm">
                                    {{d.coverage_per === null ? 0 : d.coverage_per}}
                                </h1>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class=""><img src="/assets/images/discount.png" style="    margin-top: -20px;
                                    height: 70px;
                                    background: white;
                                    padding: 10px;
                                    width: 70px;
                                    border-radius: 100px;
                                    border: 2px solid #28816b;"></div>
                                <div class="cvrg_crd_cnt"> {{avg_cvrg === null ? 0 : avg_cvrg}}</div>
                                <div class="cvrg_crd_lbl">Coverage %</div>
                            </div>
                            <!-- <div class="col-md-3 rgtpdng">
                                <div class="cvrg_crd_img">
                                    <img src="/assets/images/percentage.png" style="padding:20px">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div>
<div class="dxgridpdng cvrgrdsbrd" style="padding-top:0px">
    <h1 class="bdycntnthdr">ULB Coverage Status</h1>

        <dx-data-grid id="gridContainer" class="p-8" [dataSource]="tripsData" [remoteOperations]="false" 
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
            [wordWrapEnabled]="false" (onCellClick)="devCellclk(columnDefs,$event)"   
            (onToolbarPreparing)="true" (onCellPrepared)="onCellPrepared(columnDefs,$event)" 
            [columnAutoWidth]="true"  (onExporting)="onExporting1($event)">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-pager [visible]="tripsData.length>10" [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" [highlightSearchText]="true">
            </dxo-search-panel>
            <dxo-filter-panel [visible]="false"></dxo-filter-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select">
            </dxo-column-chooser>
            <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="Zone wise Trip Coverage Status">
            </dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field"  [fixed]="f.fixed"
                [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true" [calculateCellValue]="calculateCellValue">
            </dxi-column>
<dxo-summary>
    <dxi-total-item *ngFor="let g of ttlColumnDefs " [column]="g.field"
            [summaryType]="g.summaryType ? g.summaryType: ''"
            [displayFormat]="g.crncy_in == 1 ? currencyINRFormater:roundingFormater" [alignment]="g.algnmnt"  [valueFormat]="g.format ? g.format : '' ">
          </dxi-total-item>
</dxo-summary>
        </dx-data-grid>
    <!-- </div> -->
    <!-- <dx-data-grid
    id="gridContainer"
    [dataSource]="tripsData"
    keyExpr="ID"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
    [showBorders]="true"
  >
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
  <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field"
  [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true" [calculateCellValue]="calculateCellValue">
</dxi-column>
</dx-data-grid> -->
</div>
<div class="row ">
    
    <div class="col-lg-6 col-md-6">
        <div class="dxgridpdng">
        <h1 class="bdycntnthdr">Coverage in Last 7 Days </h1>
        <div id="coverageChart"></div>
       
    </div>
    </div>
    <div class="col-lg-6 col-md-6">
        <div class="dxgridpdng znewsecvrges">
        <h1 class="bdycntnthdr"> Zone wise Top Coverages</h1>
    
            <dx-data-grid id="gridContainer" class="p-8" [dataSource]="toptripsData" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                [wordWrapEnabled]="false"  [columnAutoWidth]="true" (onExporting)="onExporting2($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="false" [showPageSizeSelector]="false" [showInfo]="false"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" [highlightSearchText]="true">
                </dxo-search-panel>
                <dxo-filter-panel [visible]="false"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                <dxo-column-chooser [enabled]="true" mode="select">
                </dxo-column-chooser>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="Zone wise Top 10 Coverages">
                </dxo-export>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxi-column *ngFor="let f of topTripscolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                    [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                </dxi-column>
    
            </dx-data-grid>
        <!-- </div> -->
    </div>
    </div>
</div>
