<div class="container-fluid">
    <nz-card>
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="float-right">
                    <button nz-button nzType="primary" (click)="addNew()">
                        <i nz-icon nzType="plus"></i>Add Country
                    </button>
                </div>
            </div>
        </div> -->
        <div class="row mt-3">
            <div class="col-md-12">


                <dx-data-grid id="gridContainer" [dataSource]="gridData" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
                    [showRowLines]="true" [showBorders]="true" style="width:100%" [allowColumnResizing]="true"
                    (onToolbarPreparing)="onToolbarPreparing($event)">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                        [showInfo]="true"></dxo-pager>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                    <dxo-group-panel [visible]="true"></dxo-group-panel>
                    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
                    <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true"
                        fileName="LMO Details"></dxo-export>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>





                    <dxi-column caption="S.No" cellTemplate="serialNumberTemplate" [width]="70"></dxi-column>
                    <dxi-column dataField="cntry_nm" caption="Country Name"></dxi-column>
                    <dxi-column caption="Actions" cellTemplate="actionTemplate" [width]="100"></dxi-column>
                    <div *dxTemplate="let data of 'actionTemplate'">
                        <button nz-button nzType="primary" nzShape="circle" (click)="editRecord(data.data)">
                            <i nz-icon nzType="edit"></i>
                        </button>
                        <button nz-button nzType="danger" nzShape="circle" class="ml-2"
                            (click)="deleteRecord(data.data)">
                            <i nz-icon nzType="delete"></i>
                        </button>
                    </div>

                    <div *dxTemplate="let data of 'serialNumberTemplate'">
                        {{data.rowIndex + 1}}
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </nz-card>
</div>

<nz-drawer [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading" (nzOnClose)="closeSidebar()" [nzWidth]="500">
    <ng-container *nzDrawerContent>
        <div *ngIf="shwDeleteCnfrm == false">

            <form nz-form [formGroup]="countryForm" (ngSubmit)="submitForm()">


                <div class="row">
                    <div class="col-md-12">
                        <nz-form-item>
                            <nz-form-label>Country Name</nz-form-label>
                            <nz-form-control>
                                <input nz-input formControlName="cntry_nm" placeholder="Enter country name" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <button nz-button nzType="primary" [nzLoading]="shwLdng" type="submit">
                            {{ editdata ? 'Update' : 'Save' }}
                        </button>
                        <button nz-button class="ml-2" type="button" (click)="closeSidebar()">Cancel</button>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="shwDeleteCnfrm == true">
            <nz-alert nzType="warning" nzMessage="Warning" nzDescription="You are about to delete the following record."
                nzShowIcon></nz-alert>
            <div>



                <div>
                    <table class="del-confirm-table">
                        <tr class="row">
                            <td class="col">Country</td>
                            <td class="col">:{{ countryForm.value.cntry_nm }}</td>
                        </tr>

                    </table>
                </div>


                <div></div>
            </div>
            <div class="row">
                <button nz-button nzType="default" class="col m-5" (click)="shwDeleteCnfrm= false">Cancel</button>
                <button nz-button nzType="primary" class=" col m-5" nz-popconfirm
                    nzPopconfirmTitle="Are you sure to delete country ?" nzPopconfirmPlacement="bottom"
                    (nzOnConfirm)="confirmDelete()" (nzOnCancel)="shwDeleteCnfrm= false">Delete</button>


            </div>
        </div>

    </ng-container>
</nz-drawer>