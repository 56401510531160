import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartOptions, ChartType, registerables } from 'chart.js';
import { th } from 'date-fns/locale';
import { ApiService } from 'src/app/providers/api.service';

// Register all chart.js components
Chart.register(...registerables);

interface ChartData {
  label: string;
  value: number;
  color: string;
}

interface LegendItem {
  label: string;
  color: string;
}

interface CityChartData {
  [city: string]: ChartData[];
}

interface AssetCard {
  icon: string;
  type: string;
  name: string;
  details: {
    [key: string]: string;
  };
}

@Component({
  selector: 'app-sample',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  // public chart: Chart | undefined;
  @ViewChild("chart") chart: Chart;
  public chartOptions: Partial<ChartOptions>;

  public cityChartData: CityChartData = {};
  public chartData: ChartData[] = [];
  public pieChartLabels: string[];
  public pieChartDatasets: ChartConfiguration['data']['datasets']

  public firstColumnLegendItems = [];
  public secondColumnLegendItems = [];
  dshbrd_data = []
  cities = require('./cities.json');
  searchQuery: string = '';
  showSearch = false;
  assets: AssetCard[] = [];

  // Total Assets
  totalAssets: number = 0;

  // Dynamically extract city names from the loaded JSON
  andhrapradeshCities: string[] = this.cities.map(city => city.city);

  // Set the first city as the default selected city
  selectedCity = null

  // Dynamically split assets
  leftAssets: AssetCard[] = [];
  rightAssets: AssetCard[] = [];

  constructor(private apiSrvc: ApiService) {
    this.prepareCityChartData();

    this.getDistrcts();
  }

  prepareCityChartData() {
    this.cities.forEach(cityData => {
      this.cityChartData[cityData.city] = cityData.categories.map(category => {
        // Sum all status values for the category
        const totalCategoryValue = Object.values(category.status).reduce((a, b) => Number(a) + Number(b), 0);

        return {
          label: category.category,
          value: totalCategoryValue,
          color: category.color
        };
      });
    });
  }

  // Prepare assets based on selected city
  prepareAssets(city: string) {
    const cityData = this.cities.find(data => data.city === city);

    if (cityData) {
      // Transform city categories into assets
      this.assets = cityData.categories.map(category => ({
        name: category.category,
        icon: category.imageUrl,
        details: {
          'Working': category.status['Working'] || '0',
          'Not-Working': category.status['Not-Working'] || '0',
          'Spare': category.status['Spare'] || '0',
          'Damaged': category.status['Damaged'] || '0',
          'AMC Completed': category.status['AMC Completed'] || '0'
        }
      }));

      // Split assets into left and right sections
      this.splitAssets();
    } else {
      // Fallback to empty array if city not found
      this.assets = [];
      this.leftAssets = [];
      this.rightAssets = [];
    }
  }

  splitAssets() {
    const totalAssets = this.assets.length;
    const leftCount = Math.ceil(totalAssets / 2);

    this.leftAssets = this.assets.slice(0, leftCount);
    this.rightAssets = this.assets.slice(leftCount);
  }

  ngOnInit() {
   this. createChart() 

  }

  private updateChartData(city: string) {
    // // Ensure we have data for the selected city
    // const cityData = this.cityChartData[city] || this.cityChartData[this.andhrapradeshCities[0]];

    // this.chartData = cityData;

    // this.pieChartLabels = this.chartData.map(item => item.label);
    // this.pieChartDatasets = [{
    //   data: this.chartData.map(item => item.value),
    //   backgroundColor: this.chartData.map(item => item.color)
    // }];

    // // Prepare assets for the selected city
    // this.prepareAssets(city);

    // this.splitLegendItems();

    // if (this.chart) {
    //   this.chart.destroy();
    //   this.createChart();
    // }
  }

  private splitLegendItems() {
    // const totalItems = this.chartData.length;
    // const midPoint = Math.ceil(totalItems / 2);

    // this.firstColumnLegendItems = this.chartData
    //   .slice(0, midPoint)
    //   .map(item => ({ label: item.label, color: item.color }));

    // this.secondColumnLegendItems = this.chartData
    //   .slice(midPoint)
    //   .map(item => ({ label: item.label, color: item.color }));
  }

  updateTotalAssets(city: string) {
    const cityData = this.cities.find(data => data.city === city);

    if (cityData) {
      // Count total number of categories for the selected city
      // this.totalAssets = cityData.categories.length;
    } else {
      // Fallback to 0 if city not found
      this.totalAssets = 0;
    }
  }

  onCityChange(city: string): void {
    this.selectedCity = city;
    this.selectedDstct();
    this.getDshbrdCnts();
  }

  slct_dst_nm = ''
  selectedDstct() {
    this.slct_dst_nm = '';
    for (let index = 0; index < this.dstcts.length; index++) {
      const element = this.dstcts[index];
      if (element['dstrt_id'] == this.selectedCity) {
        this.slct_dst_nm = element['tnt_nm'];
        break;
      }

    }

  }

  shwEmptyPieChart = false;
  createChart() {




    this.shwEmptyPieChart = false;
    setTimeout(() => {
      const ctx = document.getElementById('pieChart') as HTMLCanvasElement;

      if (ctx) {
        const chartConfig: ChartConfiguration = {
          type: 'pie' as ChartType,
          data: {
            labels: this.pieChartLabels,
            datasets: this.pieChartDatasets
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              }
            },
            animation: {
              duration: 500
            }
          }
        };
        this.chart = new Chart(ctx, chartConfig);
      }
    }, 5);




  }

  leftAssts = [];
  rightAssts = [];
  dstcts = [];

  getDistrcts() {
    this.apiSrvc.get(`apsfl/data/districts`).subscribe((res) => {
      if (res['status'] == 200) {
        this.dstcts = res['data'];
        if (this.dstcts && this.dstcts.length == 1) {
          this.selectedCity = this.dstcts[0]['dstrt_id']
        }

        this.selectedDstct();
        this.getDshbrdCnts();
      } else {

      }
    }, (err) => {

    })
  }

  getDshbrdCnts() {
    // Reset data
    this.dshbrd_data = [];
    this.leftAssts = [];
    this.rightAssts = [];
    this.totalAssets = 0;
    this.pieChartLabels = [];
    this.pieChartDatasets = [];
    
    // Clear existing chart
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }

    this.apiSrvc.get(`apsfl/inventory/mngmnt/dashboard/${this.selectedCity}`).subscribe((res) => {
      console.log(res, 'trinadh_sai');
      if (res['status'] == 200 && res['data'] && Array.isArray(res['data'])) {
        this.dshbrd_data = res['data'];
        
        // Calculate total assets
        let ttl_ast = 0;
        this.dshbrd_data.forEach((f) => {
          f['ttl_ast'] = 0;
          f.ast_sts_lst.forEach((a) => {
            a.sub_sts_lst.forEach((b) => {
              f['ttl_ast'] += b['ct'] || 0;
            });
          });
          ttl_ast += f['ttl_ast'];
        });

        // Always process data for cards and legends
        const splt = Math.ceil(this.dshbrd_data.length / 2);
        this.leftAssts = this.dshbrd_data.slice(0, splt);
        this.rightAssts = this.dshbrd_data.slice(splt);

        const midPoint = Math.ceil(this.dshbrd_data.length / 2);
        this.firstColumnLegendItems = this.dshbrd_data
          .slice(0, midPoint)
          .map(item => ({ label: item.ast_typ_nm, color: item.ctgry_clr }));

        this.secondColumnLegendItems = this.dshbrd_data
          .slice(midPoint)
          .map(item => ({ label: item.ast_typ_nm, color: item.ctgry_clr }));

        // Only show chart if there's actual data
        if (ttl_ast > 0) {
          this.shwEmptyPieChart = false;
          this.totalAssets = ttl_ast;

          // Prepare chart data
          this.pieChartLabels = this.dshbrd_data.map(item => item.ast_typ_nm);
          this.pieChartDatasets = [{
            data: this.dshbrd_data.map(item => item.ttl_ast),
            backgroundColor: this.dshbrd_data.map(item => item.ctgry_clr)
          }];

          // Create chart
          setTimeout(() => {
            this.createChart();
          }, 0);
        } else {
          // Show empty chart message but keep cards and legends
          this.shwEmptyPieChart = true;
          this.totalAssets = 0;
        }
      } else {
        // Handle error response but keep UI elements
        this.shwEmptyPieChart = true;
        this.totalAssets = 0;
      }
    }, (err) => {
      // Handle API error but keep UI elements
      this.shwEmptyPieChart = true;
      this.totalAssets = 0;
    });
  }



  toggleSearch() {
    this.showSearch = !this.showSearch;
    if (!this.showSearch) {
      this.searchQuery = '';
      this.onSearch('');
    } else {
      setTimeout(() => {
        const input = document.querySelector('.search-input') as HTMLInputElement;
        if (input) {
          input.focus();
        }
      }, 10);
    }
  }

  onSearch(query: string) {
    this.searchQuery = query;
    const searchTerm = query.toLowerCase().trim();
    
    // Function to check if asset name matches search criteria
    const matchesSearch = (assetName: string) => {
      if (searchTerm === '') return false;
      return assetName.toLowerCase().includes(searchTerm);
    };
    
    // Apply highlighting to left assets
    if (this.leftAssts) {
      this.leftAssts.forEach(a => {
        a['hglt_cls'] = matchesSearch(a.ast_typ_nm) ? 'higltt-border' : '';
      });
    }
    
    // Apply highlighting to right assets
    if (this.rightAssts) {
      this.rightAssts.forEach(a => {
        a['hglt_cls'] = matchesSearch(a.ast_typ_nm) ? 'higltt-border' : '';
      });
    }
  }

  clearSearch() {
    this.searchQuery = '';
    this.onSearch('');
  }

  // New methods to calculate Working and Not Working total counts
  working(asset: any): number {
    if (!asset || !asset.ast_sts_lst || !asset.ast_sts_lst[0] || !asset.ast_sts_lst[0].sub_sts_lst) {
      return 0;
    }

    // Sum all sub-stages in the Working category
    return asset.ast_sts_lst[0].sub_sts_lst.reduce((total, status) => {
      return total + (Number(status.ct) || 0);
    }, 0);
  }

  notWorking(asset: any): number {
    if (!asset || !asset.ast_sts_lst || !asset.ast_sts_lst[1] || !asset.ast_sts_lst[1].sub_sts_lst) {
      return 0;
    }

    // Sum all sub-stages in the Not Working category
    return asset.ast_sts_lst[1].sub_sts_lst.reduce((total, status) => {
      return total + (Number(status.ct) || 0);
    }, 0);
  }

  onAndhrapradeshClick() {
    // Reset the selected city to null
    this.selectedCity = null;

    // Call the existing method to reset and fetch dashboard data
    this.onCityChange(null);
  }
}
