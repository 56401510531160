import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
// import { HttpService } from 'src/app/services/http.service';
import { AppsService } from 'src/app/shared/services/apps.service';


@Component({
  selector: 'app-account-type',
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.scss']
})
export class AccountTypeComponent implements OnInit {
  gridData: any = [];
  shwSidebar = false;
  shwLdng = false;
  accountTypeForm: FormGroup;
  editdata: any;
  shwDeleteCnfrm = false;

  constructor(
    private http: AppsService,
    private message: NzMessageService,
    private fb: FormBuilder
  ) {
    this.accountTypeForm = this.fb.group({
      acnt_nm: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.getGridData();
  }

  getGridData() {
    this.http.get('core/api/master/account-type/list').subscribe(
      (res: any) => {

        if (res['status'] == 200) {
          this.gridData = res['data'];
        // this.gridData = res.results;
      }else{

      }
    },
      (err) => {
        console.error('Error fetching account types:', err);
      }
    );
  }

  onToolbarPreparing(e: any) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add Account Type',
        onClick: this.showAddSidebar.bind(this)
      }
    });
  }

  showAddSidebar() {
    this.editdata = null;
    this.accountTypeForm.reset();
    this.shwSidebar = true;
    this.shwDeleteCnfrm = false;
  }

  editRecord(data: any) {
    this.editdata = data;
    this.accountTypeForm.patchValue({
      acnt_nm: data.acnt_nm
    });
    this.shwDeleteCnfrm = false;
    this.shwSidebar = true;
  }

  deleteRecord(data: any) {
    this.editdata = data;
    this.accountTypeForm.patchValue({
      acnt_nm: data.acnt_nm
    });
    this.shwDeleteCnfrm = true;
    this.shwSidebar = true;
  }

  confirmDelete() {
    this.shwLdng = true;
    this.http.delete(`core/api/master/account-type/${this.editdata.acnt_id}`).subscribe(
      (res: any) => {
        this.message.success(res.success_msg || 'Account Type deleted successfully');
        this.getGridData();
        this.closeSidebar();
      },
      (err) => {
        this.message.error(err.error.err_message || 'Failed to delete Account Type');
      }
    ).add(() => {
      this.shwLdng = false;
    });
  }

  submitForm() {
    if (this.accountTypeForm.valid) {
      this.shwLdng = true;
      if (this.editdata) {
        this.http.put( this.accountTypeForm.value ,`core/api/master/account-type/${this.editdata.acnt_id}`).subscribe(
          (res: any) => {
            this.message.success(res.success_msg || 'Account Type updated successfully');
            this.getGridData();
            this.closeSidebar();
          },
          (err) => {
            this.message.error(err.error.err_message || 'Failed to update Account Type');
          }
        ).add(() => {
          this.shwLdng = false;
        });
      } else {
        this.http.post( this.accountTypeForm.value ,'core/api/master/account-type').subscribe(
          (res: any) => {
            this.message.success(res.success_msg || 'Account Type added successfully');
            this.getGridData();
            this.closeSidebar();
          },
          (err) => {
            this.message.error(err.error.err_message || 'Failed to add Account Type');
          }
        ).add(() => {
          this.shwLdng = false;
        });
      }
    } else {
      Object.values(this.accountTypeForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  closeSidebar() {
    this.shwSidebar = false;
    this.editdata = null;
    this.accountTypeForm.reset();
    this.shwDeleteCnfrm = false;
  }
}
