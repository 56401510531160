<div class="flex flex-col flex-auto w-full">
  <div class="container">
    <div class="top-header">
      <div class="page-title">Add Product Qty/Stock Inward</div>
      <div class="header-right">
        <span>Create Product Qty/Stock Inward</span>
        <button nz-button nzType="default" class="go-back-btn" (click)="goBack()">Go Back</button>
      </div>
    </div>

    <div class="sub-header">
      <div class="label">Product Qty/Stock Inward</div>
      <button nz-button nzType="primary" class="bulk-inv-btn" (click)="bulkInvStockInward()">
        Bulk Inv Stock Inward
      </button>
    </div>

    <form nz-form [formGroup]="inwardForm" class="inward-form" (ngSubmit)="submitStockInward()">
      <div class="form-row">
        <div class="form-group">
          <label>PO No:</label>
          <nz-select formControlName="poNo" nzPlaceHolder="Select">
            <nz-option *ngFor="let option of poNoOptions" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
          </nz-select>
        </div>

        <div class="form-group">
          <label>Company Name:</label>
          <input nz-input formControlName="companyName" />
        </div>

        <div class="form-group">
          <label>Branch:</label>
          <input nz-input formControlName="branch" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label>Inventory Name:</label>
          <nz-select formControlName="inventoryName" nzPlaceHolder="Select">
            <nz-option *ngFor="let option of inventoryNameOptions" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
          </nz-select>
        </div>

        <div class="form-group">
          <label>Make:</label>
          <input nz-input formControlName="make" />
        </div>

        <div class="form-group">
          <label>Model:</label>
          <input nz-input formControlName="model" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label>MAC Address:</label>
          <input nz-input formControlName="macAddress" />
        </div>

        <div class="form-group">
          <label>Serial No:</label>
          <input nz-input formControlName="serialNo" placeholder="N/A" />
        </div>

        <div class="form-group">
          <label>Quantity:</label>
          <nz-input-number formControlName="quantity" [nzMin]="1" [nzMax]="999999"></nz-input-number>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label>Inward Type:</label>
          <nz-select formControlName="inwardType" nzPlaceHolder="Select">
            <nz-option *ngFor="let option of inwardTypeOptions" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
          </nz-select>
        </div>

        <div class="form-group">
          <label>Warranty Period (yrs):</label>
          <input nz-input formControlName="warrantyPeriod" type="number" />
        </div>

        <div class="form-group">
          <label>Purchase Date:</label>
          <nz-date-picker formControlName="purchaseDate"></nz-date-picker>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label>Warranty Up To:</label>
          <nz-date-picker formControlName="warrantyUpTo"></nz-date-picker>
        </div>
        
        <div class="form-group">
          <label>Delivery Details:</label>
          <input nz-input formControlName="deliveryDetails" placeholder="Enter delivery details" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group remarks">
          <label>Remarks:</label>
          <textarea nz-input formControlName="remarks" rows="2"></textarea>
        </div>
      </div>

      <div class="form-actions">
        <button nz-button nzType="primary" type="submit">Submit Stock Inward</button>
        <button nz-button nzType="default" type="button">Cancel</button>
      </div>
    </form>
  </div>
</div>