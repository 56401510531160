<div class="page-header">
    <div class="align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-12 col-md-12">
                <h1>
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
        </div>
    </div>
</div>

<div class="complaint-dashboard">
    <!-- Complaint Type Cards -->
    <div class="row mb-4">
        <div class="col-md-3" *ngFor="let card of complaintCards">
            <div class="card complaint-card" 
                [ngClass]="{'active': selectedComplaintType === card.wrk_grp_id}"
                (click)="onCardSelect(card)"
                [ngStyle]="{ 'border-color': card.color }">
                <div class="card-body">
                    <div class="card-title" [ngStyle]="{'color': card.color}">
                        {{card.wrk_grp_nm}}
                        <span class="total-count">({{card.total}})</span>
                    </div>
                    <div class="row stats">
                        <div class="col-6">
                            <div class="stat-value pending">{{card.pending}}</div>
                            <div class="stat-label">Pending</div>
                        </div>
                        <div class="col-6">
                            <div class="stat-value completed">{{card.completed}}</div>
                            <div class="stat-label">Completed</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Tickets Grid -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title" *ngIf="selectedCard">
                        {{selectedCard.wrk_grp_nm}} Tickets
                    </h5>
                    <dx-data-grid
                        [dataSource]="ticketsData"
                        [showBorders]="true"
                        [rowAlternationEnabled]="true"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        (onRowClick)="onRowClick($event)">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager
                            [showPageSizeSelector]="true"
                            [allowedPageSizes]="[10, 25, 50]">
                        </dxo-pager>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxo-header-filter [visible]="true"></dxo-header-filter>
                        <dxo-search-panel [visible]="true"></dxo-search-panel>
                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
                        <dxo-export [enabled]="true"></dxo-export>
                        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field"
                        [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                    </dxi-column>
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>
