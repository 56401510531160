
<perfect-scrollbar class="side-nav" sideNav [ngClass]="isFolded == true ? 'w-80' : 'w-280'">
    <div *ngIf="loader">
        <nz-space nzSize="middle" class="p-20" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9]">
            <nz-space-item>
                <nz-skeleton-element class="m-r-10" nzType="avatar" [nzActive]="true" [nzSize]="'small'"
                    [nzShape]="'square'"></nz-skeleton-element>
            </nz-space-item>
            <nz-space-item>
                <nz-skeleton-element nzType="input" [nzActive]="true" [nzSize]="'small'" style="width:120px">
                </nz-skeleton-element>
            </nz-space-item>
        </nz-space>
    </div>
    <div class="row">
        <div class="row sidescrl">

            <div class="hdr_profile" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
            [nzPlacement]="'bottomRight'"> 
                <nz-avatar nzSize="large" nzSrc="assets/images/avatars/profile.jpg"></nz-avatar>
                <div *ngIf="isFolded == false">
                    <span style="float: left;    width: 85%;" >
                        <h5 class="hdr_txt" title="{{usrDtls.lst_lgn_ts}}">Last Login: {{usrDtls.lst_lgn_ts}}</h5>
                        <p class="hdr_txt" title="{{usrDtls.fst_nm}} {{usrDtls.lst_nm}}">{{usrDtls.fst_nm}} {{usrDtls.lst_nm}} </p>
                    </span>
                    <span style="float: left;
                    width: 10%;
                    margin: 8px 0px;">
                        <i class="font-size-10 dwnarw" nz-icon nzType="right" theme="outline"></i>
                    </span>
                </div>
            </div>
            <nz-dropdown-menu #profile="nzDropdownMenu">
                <ul nz-menu class="p-b-15 p-t-20" style="margin: 20px;
                margin-top: -10px;
                padding: 10px;
                padding-top: 10px !important;">
                    <li nz-menu-item>
                        <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="editprfl()">
                            <div>
                                <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                <span class="m-l-10">Edit Profile</span>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </div>
                            
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                            <div>
                                <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                <span class="m-l-10">Logout</span>
                            <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </nz-dropdown-menu>
            <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu" [ngClass]="{
            'ant-menu-inline-collapsed': isFolded,
            'ant-menu-dark' : isSideNavDark
        }" style="     background: #f8f9fa00;margin-bottom: 0px !important; width: 100%;">
        <!-- background: #f8f9fa; -->
                <!-- <li class='ant-menu-submenu ant-menu-submenu-inline divider'>
        <span>Collect</span>
    </li>
    <li class='ant-menu-submenu ant-menu-submenu-inline divider'>
        <span>Pay</span>
    </li>
    <li class='ant-menu-submenu ant-menu-submenu-inline divider'>
        <span>Analyze</span>
    </li> -->
    <li [ngClass]="[item.children.length > 0 ? 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open' : 'ant-menu-item',
        item.hdr_in == 1 ? 'sdeMnu-grp-lbl' : '']" *ngFor="let item of menuItems"
[routerLinkActive]="item.children.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
[routerLinkActiveOptions]="{exact: false}">

<a href="javascript:void(0);" class="ant-menu-submenu-title" *ngIf="item.children.length > 0"
style="margin-top: 0px !important; padding: 0px !important;">
<span *ngIf="isFolded">
    <i class="m-r-10 sdeMnuIcn" nz-icon [nzType]="item.prnt_mnu_icn_tx" nzTheme="outline" style="margin-top: 6px;"></i>
    <span class="sdeMnuLbl" title="{{item.prnt_mnu_itm_nm}}">{{item.prnt_mnu_itm_nm}}</span>
</span>
<span *ngIf="isFolded == false">
    <i class="m-r-10 sdeMnuIcn" nz-icon [nzType]="item.prnt_mnu_icn_tx" nzTheme="outline" style="margin-top: 6px;"></i>
    <span class="sdeMnuLbl" title="{{item.prnt_mnu_itm_nm}}">{{item.prnt_mnu_itm_nm}}</span>
</span>
<i class="ant-menu-submenu-arrow"></i>
</a>
<a [routerLink]="item.mnu_itm_url_tx" *ngIf="item.children.length === 0 && item.hdr_in == 0">
<i class="m-r-10 sdeMnuIcn" nz-icon [nzType]="item.mnu_itm_icn_tx" nzTheme="outline"></i>
<span class="sdeMnuLbl" title="{{item.mnu_itm_nm}}">{{item.mnu_itm_nm}}</span>
</a>
<div *ngIf="item.hdr_in == 1" title="{{item.mnu_itm_nm}}">{{item.mnu_itm_nm}}</div>

<!-- Submenu -->
<ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="item.children.length > 0">
<li [ngClass]="subItem.submenu.length > 0 ? 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open' : 'ant-menu-item'"
    *ngFor="let subItem of item.children"
    [routerLinkActive]="subItem.submenu.length > 0 ? 'ant-menu-submenu-open ant-menu-item-selected' : 'ant-menu-item-selected'">
    <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" *ngIf="subItem.submenu.length > 0">
        <i class="m-r-10 sdeMnuIcn" nz-icon [nzType]="subItem.mnu_itm_icn_tx" style="margin-top: 6px;"></i>
        <i class="ant-menu-submenu-arrow"></i>
    </a>
    <a [routerLink]="subItem.mnu_itm_url_tx" class="p-l-16" *ngIf="subItem.submenu.length === 0">
        <i class="m-r-10 sdeMnuIcn" nz-icon [nzType]="subItem.mnu_itm_icn_tx"></i>
        <span title="{{subItem.mnu_itm_nm}}" style="margin-left: 0px!important;">{{subItem.mnu_itm_nm}}</span>
    </a>

    <!-- Third Level -->
    <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="subItem.submenu.length > 0">
        <li [ngClass]="subItem.submenu.length > 0 ? 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open' : 'ant-menu-item'"
            *ngFor="let subItem of subItem.submenu" routerLinkActive="ant-menu-item-selected">
            <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" *ngIf="subItem.submenu.length > 0">
                <i nz-icon class="sdeMnuIcn" [nzType]="subItem.prnt_mnu_icn_tx" style="margin-top: 6px;"></i>
                <span title="{{subItem.title}}">{{subItem.title}}</span>
                <i class="ant-menu-submenu-arrow"></i>
            </a>
            <a [routerLink]="subItem.mnu_itm_url_tx" class="p-l-50" *ngIf="subItem.submenu.length === 0">
                <i nz-icon class="sdeMnuIcn" [nzType]="subItem.mnu_itm_icn_tx"></i>
                <span title="{{subItem.title}}">{{subItem.title}}</span>
            </a>
        </li>
    </ul>
</li>
</ul>
</li>


            </ul>
        </div>
        <div class="row ftrDvlpd">
            <div class="logo logo-dark">
                <a href="">
                    <p class="main-header-logo-txt" *ngIf="isFolded == false">Powered &amp; Developed By</p>
                    <p style="margin: 0px;text-align: center;">
                        <img *ngIf="isFolded == false" src="assets/images/logo/D._logo1.png" alt="Logo"
                        class="main-header-logo">
                    <img *ngIf="isFolded == true" class="logo-fold" src="assets/images/logo/dreamstep_D.png" alt="Logo">
                    </p>
                </a>
            </div>
        </div>
    </div>
    <style>
        li.ant-menu-submenu.ant-menu-submenu-inline.divider {
            text-align: center;
            background: #f7f9f7;
            padding: 18px;
            margin: 10px;
            padding: 1rem;
            border-bottom: 3px solid;
            border-style: solid;
            border-image: linear-gradient(to bottom, #118791, rgb(251 205 22)) 1 100%;
        }

        /* .sdeMnuLbl {
   
    color: #252f40;
    font-size: 12px;
} */

        .sidescrl {
            height: 85vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0px;
    padding-bottom: 5%;
    background-color: #34425a
        }

        .sidescrl::-webkit-scrollbar {
            width: 3px;
        }

        .sidescrl::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgb(0 0 0 / 14%);
        }

        .sidescrl::-webkit-scrollbar-thumb {
            background-color: darkgrey;
        }

        .sdeMnuIcn {
            border-color: transparent;
    text-align: center;
    /* float: left; */
    /* width: 28px;
    height: 28px; */
    line-height: 35px !important;
    margin-top: 4px;
    font-size: 15px;
    border-radius: 0.5rem;
    margin-left: 0px ;
        }

.ant-menu-inline-collapsed> li a i{

 margin-left: 4px !important;
  }

        .ant-menu-inline-collapsed>.ant-menu-item,
        .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
        .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
        .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
            /* margin-left: 2px !important; */
            text-align: center;
            /* padding: 0 30px !important; */
            background: transparent !important;
        }
        /* .ant-menu-inline-collapsed>.ant-menu-inline .ant-menu-submenu {
            padding-left: 0px;
        } */
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .sdeMnuIcn {

            /* color: #3189cf !important; */
    margin-top: 2px !important;
        }

        /* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .sdeMnuLbl {
    color: #ffffff !important;
} */



        .ant-menu-inline .ant-menu-submenu-title {
            height: 50px !important;
            line-height: 50px !important;
            font-size: 12px;
        }

        .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
            right: 0px !important;
        }
.sdeMnuLbl{
    margin-left: 0px!important;
}
        .itmlngth {
            background: #41b883;
            color: white;
            padding: 1px 5px;
            border-radius: 4px;
            font-size: 9px;
            font-weight: 400;
        }

        .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::before {
            /* background:#537484!important; */
            /* background: #252f40 !important */
            background: #ffffff !important;
        }

        .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::after {
            /* background:#537484!important; */
            /* background: #252f40 !important */
            background: #ffffff !important;
        }

        /* 
.ant-menu-submenu>.ant-menu {
background-color: #f2f4ff!important;} */
        .ant-menu-inline-collapsed>.ant-menu-item .anticon {
            line-height: 35px !important;
        }

        .ant-menu-submenu-open {
            /* background: #d2eafd !important; */
            /* background: #233b4f !important; */
            /* background: #002c4f !important; */
            background:  #1c2a42!important;
           
            border-radius: 6px;
            padding-bottom: 8px!important;
        }

        /* .ant-menu-submenu-title {

background: transparent!important;
} */
        .side-nav .side-nav-menu:not(.ant-menu-inline-collapsed) .ant-menu-submenu-open>.dropdown-menu {
            display: block !important;
            padding-left: 0px;
            /* background: #0b263c !important; */
            /* background: #d2eafd !important; */
            margin-top: 6px;
        }

        /* .ant-menu-submenu>.ant-menu {
            background: #f8f9fa !important;
        } */

        .ant-menu-sub.ant-menu-inline {
    background: #fafafa00;

}
.ant-menu-inline.ant-menu-submenu:hover {
    background: #ffffff00!important;
}
/* .ant-menu-inline[_ngcontent-yfj-c1] .ant-menu-submenu[_ngcontent-yfj-c1]:hover {
    background: #ffffff00;
} */
        .side-nav .side-nav-menu {
            padding: 0 2px;    height: inherit;
        }

        .p-l-16 {
            padding-left: 8px;
        }

        .ant-menu-inline .ant-menu-submenu{
            margin-bottom: 0px;
            margin-top: 0px;
            padding: 0px 22px; 
            /* border-bottom: 1px solid #f0f1ff; */
            /* border-bottom: 1px solid #323232; */
            /* padding-left: 16px; */
            /* border-bottom: 1px dashed#323232; */
            border-bottom: 1px dotted #293548;
        }

        .ant-menu-inline .ant-menu-item {
            /* padding: 0px 0px 0px 30px; */
            padding: 0 22px !important;
            margin-bottom: 5px;
            /* border-bottom: 1px solid #e8f5ff; */
            /* border-bottom: 1px solid #323232; */
            /* border-bottom: 1px dashed#323232; */
            /* border-bottom: 1px dashed#1e1b1b; */
            border-bottom: 1px dotted #293548;
            width: 100%;
    margin: 0px auto;
    border-radius: 4px;

        }
        .ant-menu-inline-collapsed {
    width: 90px !important;
}

        .is-folded .side-nav {
padding: 0px!important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
   
    border: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
    /* color: #000000 !important; */
    color: #ffffff !important;
    
    font-weight: 500;

}

.ant-menu-inline .ant-menu-item:hover a {
    color: #ffffff !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .sdeMnuIcn{
    /* color: #000000; */
    color: #ffffff;
}

/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-inline-collapsed{
    color: #ffffff !important;
    border-bottom: 1px dashed#323232;
} */

/* .ant-menu[_ngcontent-exa-c1]:not(.ant-menu-horizontal) .ant-menu-item-selected[_ngcontent-exa-c1] .sdeMnuIcn[_ngcontent-exa-c1] {
    color: #3189cf !important;
    margin-top: 2px !important;
} */



/* .ant-menu-inline-collapsed:not(.ant-menu-horizontal) .ant-menu-item-selected.sdeMnuIcn{
    border-bottom: 1px dashed#323232;
} */

.ant-menu-inline-collapsed:not(.ant-menu-horizontal) .ant-menu-item-selected {
   
    border-bottom: 1px dashed#323232!important;
}


.ant-menu-inline.ant-menu-item:hover {

    border-bottom: 0px dashed#323232;
  
}
/* .ant-menu-inline .ant-menu-submenu:hover{
  background: white;
 
} */
/* .ant-menu-inline .ant-menu-submenu:hover a{
    color: black;
 
}
.ant-menu-inline .ant-menu-submenu:hover .sdeMnuIcn {
    color: black;
 
}

.ant-menu-inline .ant-menu-submenu:hover.ant-menu-submenu-inline > .ant-menu-submenu-title.ant-menu-submenu-arrow::before {
    background: #000000 !important;
}
.ant-menu-inline .ant-menu-submenu:hover.ant-menu-submenu-inline > .ant-menu-submenu-title.ant-menu-submenu-arrow::after {
    background: #000000 !important;
} */


</style>
</perfect-scrollbar>