import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ElementRef } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ca } from 'date-fns/locale';

@Component({
  selector: 'app-complaint-form',
  templateUrl: './complaint-form.component.html',
  styleUrls: ['./complaint-form.component.scss']
})
export class ComplaintFormComponent implements OnInit {
  @Output() complaintSubmitted = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();
  mainForm: FormGroup;
  settings = {
    isBtnLoading: false,
    shwGrd: false,
  };

  attachments: any[] = [];
  districtLst: any[] = [];
  mandalsLst: any[] = [];
  designation: any[] = [];
  complaintTypes: any[] = [];
  ticketTypes: any[] = [];
  complaintCategories: any[] = [];
  employees: any[] = [];
  uploadUrl: string;
  fl_nm: string;
  cmplnt_id: any;
  call_types_lst: any[] = [];
  Network_lst: any[] = [];
  Technology_lst: any[] = [];
  Sub_Technology_lst: any[] = [];
  Links_lst: any[] = [];
  Electronics_lst: any[] = [];
  fnlImgs: NzUploadFile[] = [];

  constructor(
    private router: Router,
    private apiSrvc: AppsService,
    private fb: FormBuilder,
    private nzMessageService: NzMessageService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.loadDropdownData();

    // Watch for complaint type changes
    this.mainForm.get('complaint_type')?.valueChanges.subscribe(value => {
      this.cmplnt_id = value;
      this.updateValidations(value);
      this.checkFormValidity();
    });

    // Watch for form changes to update validity
    this.mainForm.valueChanges.subscribe(() => {
      this.checkFormValidity();
    });
  }

  updateValidations(complaintType: string): void {
    // Reset all conditional validators first
    const conditionalFields = [
      'call_type_id', 'priority', 'complaint_category', 'caf_id', 'caf_count',
      'Network_id', 'ticket_type', 'Technology_id', 'Sub_Technology_id', 'tech_complaint_category',
      'dstrct_id', 'Link_id', 'Electronics_id', 'elct_complaint_category'
    ];

    conditionalFields.forEach(field => {
      const control = this.mainForm.get(field);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });

    // Apply new validators based on complaint type
    switch (complaintType) {
      case '1': // Enterprise
        ['call_type_id', 'priority', 'complaint_category'].forEach(field => {
          const control = this.mainForm.get(field);
          if (control) {
            control.setValidators([Validators.required]);
            control.updateValueAndValidity();
          }
        });
        break;

      case '2': // NOC
        ['Network_id', 'ticket_type', 'Technology_id', 'Sub_Technology_id', 'tech_complaint_category'].forEach(field => {
          const control = this.mainForm.get(field);
          if (control) {
            control.setValidators([Validators.required]);
            control.updateValueAndValidity();
          }
        });
        break;

      case '3': // DHQ
      case '4': // ZHQ
      case '5': // MDQ
      case '6': // SS
        ['ticket_type', 'dstrct_id', 'Link_id', 'Electronics_id', 'elct_complaint_category'].forEach(field => {
          const control = this.mainForm.get(field);
          if (control) {
            control.setValidators([Validators.required]);
            control.updateValueAndValidity();
          }
        });
        break;
    }
  }

  checkFormValidity() {
    const complaintType = this.mainForm.get('complaint_type')?.value;
    
    // Check common fields
    const commonValid = ['complaint_type', 'emple_dsgns_id', 'assigned_employee', 'description']
      .every(field => this.mainForm.get(field)?.valid);

    if (!commonValid) return false;

    // Check type-specific fields
    let typeSpecificValid = true;
    switch (complaintType) {
      case '1': // Enterprise
        typeSpecificValid = ['call_type_id', 'priority', 'complaint_category', 'caf_id', 'caf_count']
          .every(field => this.mainForm.get(field)?.valid);
        break;
      
      case '2': // NOC
        typeSpecificValid = ['Network_id', 'ticket_type', 'Technology_id', 'Sub_Technology_id', 'tech_complaint_category']
          .every(field => this.mainForm.get(field)?.valid);
        break;
      
      case '3': // DHQ
      case '4': // ZHQ
      case '5': // MDQ
      case '6': // SS
        typeSpecificValid = ['ticket_type', 'dstrct_id', 'Link_id', 'Electronics_id', 'elct_complaint_category']
          .every(field => this.mainForm.get(field)?.valid);
        break;
    }

    if (!typeSpecificValid) {
      this.mainForm.setErrors({ 'invalid': true });
    } else {
      this.mainForm.setErrors(null);
    }
    
    return typeSpecificValid && commonValid;
  }

  initForm(): void {
    this.mainForm = this.fb.group({
      complaint_type: [null, [Validators.required]],
      emple_dsgns_id: [null, [Validators.required]],
      assigned_employee: [null, [Validators.required]],
      description: [null, [Validators.required]],
      
      // Enterprise fields
      call_type_id: [null],
      priority: [null],
      complaint_category: [null],
      caf_id: [null],
      caf_count: [null],
      
      // NOC fields
      Network_id: [null],
      ticket_type: [null],
      Technology_id: [null],
      Sub_Technology_id: [null],
      tech_complaint_category: [null],
      
      // DHQ/ZHQ/MDQ/SS fields
      dstrct_id: [null],
      Link_id: [null],
      Electronics_id: [null],
      elct_complaint_category: [null],
      
      // Network/OLT fields
      olt_ip: [null]
    });

    // Watch for complaint type changes to update validations
    this.mainForm.get('complaint_type')?.valueChanges.subscribe(value => {
      if (value) {
        this.updateValidations(value);
      }
    });
  }

  loadDropdownData(): void {
    this.getDistricts();
    this.loadComplaintTypes();
    this.loadTechnologies();
    this.loadTicketTypes();
    this.loadDesignations();
    this.loadnetworks();
    this.loadelectronics();
    this.loadcalltypes();
  }

  loadElctronicsCategory($event){
    this.apiSrvc.get(`complaint/electronic-category/${$event}`).subscribe(
      (res: any) => {
        this.complaintCategories = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load electronics categories');
      }
    );
  }

  loadnetworks(){
    this.apiSrvc.get('complaint/network').subscribe(
      (res: any) => {
        this.Network_lst = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load networks');
      }
    );
  }
  loadtcnlgyCategory(e: any): void {
    if (!e) return;
    
    this.apiSrvc.get(`complaint/technology-category/${e}`).subscribe(
      (res: any) => {
        this.complaintCategories = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load technology categories');
      }
    );
  }
  loadTechnologies(){
    this.apiSrvc.get('complaint/technology').subscribe(
      (res: any) => {
        this.Technology_lst = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load technologies');
      }
    );
  }

  loadlinks(e){
    this.apiSrvc.get(`complaint/links/${e}`).subscribe(
      (res: any) => {
        this.Links_lst = res.data || [];
      },
      (error) => {
     
        this.nzMessageService.error('Failed to load links');
      }
    );
  }

  loadcalltypes(){
    this.apiSrvc.get('complaint/call-types').subscribe(
      (res: any) => {
        this.call_types_lst = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load call types');
      }
    );
  }
  loadFormFields(e: any) {
    if (!e) return;
    
    this.cmplnt_id = e;
    
    if (this.cmplnt_id === '1') { // Enterprise

      
    
      this.loadComplaintCategories(e)
    }
    
    else if (this.cmplnt_id === '2') { // NOC
    
    }
    
    else if (this.cmplnt_id >= '3' && this.cmplnt_id <= '6') { // DHQ, ZHQ, MDQ, SS
     
    }else{
        this.loadComplaintCategories(e)
    }
    
    console.log(this.mainForm, this.mainForm.valid, 'Form validation after loading fields');
  }

  loadelectronics(){
    this.apiSrvc.get(`complaint/electronics/`).subscribe(
      (res: any) => {
        this.Electronics_lst = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load electronics');
      }
    );
  }


  getDistricts(): void {
    this.apiSrvc.get('complaint/districts').subscribe(
      (res: any) => {
        this.districtLst = res['data'] || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load districts');
      }
    );
  }

  getMandals(districtId: string): void {
    if (!districtId) return;
    this.apiSrvc.get(`complaint/mandals/${districtId}`).subscribe(
      (res: any) => {
        this.mandalsLst = res['data'] || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load mandals');
      }
    );
  }

  loadDesignations(): void {
    this.apiSrvc.get('complaint/designations').subscribe(
      (res: any) => {
        this.designation = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load designations');
      }
    );
  }

  loadComplaintTypes(): void {
    this.apiSrvc.get('complaint/complaint-types').subscribe(
      (res: any) => {
        this.complaintTypes = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load complaint types');
      }
    );
  }

  loadTicketTypes(): void {
    this.apiSrvc.get('complaint/ticket-types').subscribe(
      (res: any) => {
        this.ticketTypes = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load ticket types');
      }
    );
  }

  loadComplaintCategories(complaintTypeId): void {

    this.apiSrvc.get(`complaint/complaint-categories/${complaintTypeId}`).subscribe(
      (res: any) => {
        this.complaintCategories = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load complaint categories');
      }
    );
  }

  loadEmployees(designationId: string): void {
    if (!designationId) return;
    this.apiSrvc.get(`complaint/employees/${designationId}`).subscribe(
      (res: any) => {
        this.employees = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load employees');
      }
    );
  }

  private compressImage(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;
        
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxWidth = 100;  // Passport size width
          const maxHeight = 120; // Passport size height
          let width = img.width;
          let height = img.height;

          // Calculate new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          // Get compressed base64 image with higher quality for small images
          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.8); // Increased quality to 0.8 since size is small
          resolve(compressedBase64);
        };
        
        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    const isImage = file.type?.startsWith('image/');
    if (!isImage) {
      this.nzMessageService.error('You can only upload image files!');
      return false;
    }
    const isLt2M = file.size! / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.nzMessageService.error('Image must be smaller than 2MB!');
      return false;
    }
    this.fnlImgs.push(file);
    return false;
  };

  

  submitComplaint(): void {
    if (this.mainForm.valid) {
      this.settings.isBtnLoading = true;
      const postdata = {
        data: {
          ...this.mainForm.value,
          attachments: this.attachments|| []
        }
      };



      this.apiSrvc.post(postdata, 'complaint/complaint/submit').subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.nzMessageService.success('Complaint submitted successfully');
            this.resetForm();
            this.complaintSubmitted.emit(); // This will now trigger both refresh and drawer close
          } else {
            this.nzMessageService.error(res.message || 'Failed to submit complaint');
          }
          this.settings.isBtnLoading = false;
        },
        (error) => {
          this.nzMessageService.error('Failed to submit complaint');
          this.settings.isBtnLoading = false;
        }
      );
    } else {
      Object.keys(this.mainForm.controls).forEach(key => {
        const control = this.mainForm.get(key);
        if (control) {
          control.markAsTouched();
          control.updateValueAndValidity();
        }
      });
      this.nzMessageService.warning('Please fill all required fields');
    }
  }

  private resetForm(): void {
    this.mainForm.reset();
    this.fnlImgs = [];
  }

  closeForm(): void {
    this.mainForm.reset();
    this.fnlImgs = [];
    this.complaintSubmitted.emit();
  }

  handleInputChange(event: any): void {
    const file = event.target.files[0];
    if (!file) return;

    // Check file type
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!validTypes.includes(file.type)) {
      this.nzMessageService.error('Please upload only PNG, JPG, or JPEG files');
      event.target.value = ''; // Clear the input
      return;
    }

    // Check file size (e.g., max 5MB)
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > maxSize) {
      this.nzMessageService.error('File size should not exceed 5MB');
      event.target.value = ''; // Clear the input
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      // this.attachments.push({
      //   file: file,
      //   base64: e.target.result
      // });
         this.attachments=[{
          fl_type: file.type,
        base64: e.target.result,
        fl_name: file.name
      }];
    };
    reader.readAsDataURL(file);
  }

  removeAttachment(index: number): void {
    this.attachments.splice(index, 1);
    // If this was the last attachment, clear the file input
    if (this.attachments.length === 0) {
      const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
    }
  }

  // Add method to load sub-technologies when technology is selected
  loadSubTechnologies(event: any): void {
    if (!event) return;
    
    // Clear previous sub-technologies
    this.Sub_Technology_lst = [];
    this.mainForm.patchValue({ Sub_Technology_id: null });

    this.apiSrvc.get(`complaint/sub-technology/${event}`).subscribe(
      (res: any) => {
        this.Sub_Technology_lst = res.data || [];
      },
      (error) => {
        this.nzMessageService.error('Failed to load sub-technologies');
      }
    );
  }
}
