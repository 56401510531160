<div class="container-fluid">
    <nz-card>
        <div class="row mt-3">
            <div class="col-md-12">
                <dx-data-grid id="gridContainer" [dataSource]="gridData" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
                    [showRowLines]="true" [showBorders]="true" style="width:100%" [allowColumnResizing]="true"
                    (onToolbarPreparing)="onToolbarPreparing($event)">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                        [showInfo]="true"></dxo-pager>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                    <dxo-group-panel [visible]="true"></dxo-group-panel>
                    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
                    <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true"
                        fileName="Vendor Services Details"></dxo-export>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>

                    <dxi-column caption="S.No" cellTemplate="serialNumberTemplate" [width]="70"></dxi-column>
                    <dxi-column dataField="srvce_nm" caption="Service Name"></dxi-column>
                    <dxi-column caption="Actions" cellTemplate="actionTemplate" [width]="100"></dxi-column>
                    <div *dxTemplate="let data of 'actionTemplate'">
                        <button nz-button nzType="primary" nzShape="circle" (click)="editRecord(data.data)">
                            <i nz-icon nzType="edit"></i>
                        </button>
                        <button nz-button nzType="danger" nzShape="circle" class="ml-2"
                            (click)="deleteRecord(data.data)">
                            <i nz-icon nzType="delete"></i>
                        </button>
                    </div>
                    <div *dxTemplate="let data of 'serialNumberTemplate'">
                        {{data.rowIndex + 1}}
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </nz-card>
</div>

<nz-drawer [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading" (nzOnClose)="closeSidebar()" [nzWidth]="500">
    <ng-container *nzDrawerContent>
        <div *ngIf="shwDeleteCnfrm == false">
            <form nz-form [formGroup]="serviceForm" (ngSubmit)="submitForm()">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzRequired>Service Name</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Please enter service name">
                        <input nz-input formControlName="srvce_nm" placeholder="Enter service name" />
                    </nz-form-control>
                </nz-form-item>

                <div class="text-right">
                    <button nz-button nzType="default" class="mr-2" (click)="closeSidebar()">Cancel</button>
                    <button nz-button nzType="primary" [nzLoading]="shwLdng">{{editdata ? 'Update' : 'Save'}}</button>
                </div>
            </form>
        </div>

        <div *ngIf="shwDeleteCnfrm == true">
            <h3>Are you sure want to delete this record?</h3>
            <div class="text-right mt-2">
                <button nz-button nzType="default" class="mr-2" (click)="cancelDelete()">No</button>
                <button nz-button nzType="primary" [nzLoading]="shwLdng" (click)="confirmDelete()">Yes</button>
            </div>
        </div>
    </ng-container>
</nz-drawer>
