import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-add-inward',
  templateUrl: './add-inward.component.html',
  styleUrls: ['./add-inward.component.css']
})
export class AddInwardComponent implements OnInit {
  // Form group declaration
  inwardForm: FormGroup;

  // Dropdown options
  poNoOptions = [
    { value: 'PO-001', label: 'PO-001' },
    { value: 'PO-002', label: 'PO-002' },
    { value: 'PO-003', label: 'PO-003' }
  ];

  inventoryNameOptions = [
    { value: 'Inventory A', label: 'Inventory A' },
    { value: 'Inventory B', label: 'Inventory B' },
    { value: 'Inventory C', label: 'Inventory C' }
  ];

  inwardTypeOptions = [
    { value: 'Purchase', label: 'Purchase' },
    { value: 'Transfer', label: 'Transfer' },
    { value: 'Return', label: 'Return' }
  ];

  constructor(
    private fb: FormBuilder, 
    private router: Router,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  // Initialize the form with default values and validators
  initForm(): void {
    this.inwardForm = this.fb.group({
      poNo: [null, Validators.required],
      inventoryName: [null, Validators.required],
      inwardType: [null, Validators.required],
      companyName: [null, Validators.required],
      branch: [null, Validators.required],
      make: [null],
      model: [null],
      macAddress: [null],
      serialNo: ['N/A'],
      quantity: [1, [Validators.required, Validators.min(1)]],
      warrantyPeriod: [null],
      purchaseDate: [null, Validators.required],
      warrantyUpTo: [null],
      remarks: [null]
    });
  }

  // Submit form method
  submitStockInward(): void {
    if (this.inwardForm.valid) {
      // Process form submission
      console.log('Form Submitted', this.inwardForm.value);
      this.message.success('Stock Inward Submitted Successfully!');
      // You can add API call here to submit the form data
    } else {
      // Mark all fields as touched to show validation errors
      Object.values(this.inwardForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.message.error('Please fill in all required fields');
    }
  }

  // Cancel form method
  cancelForm(): void {
    // Reset the form
    this.inwardForm.reset({
      serialNo: 'N/A',
      quantity: 1
    });
    this.message.info('Form Cancelled');
  }

  // Go back method
  goBack(): void {
    this.router.navigate(['/inventory-management']);
  }

  // Bulk Inv Stock Inward method (placeholder)
  bulkInvStockInward(): void {
    // Implement bulk stock inward logic
    this.message.info('Bulk Inv Stock Inward Clicked');
  }
}
