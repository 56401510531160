import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { WardsDashboardComponent } from './wards-dashboard/wards-dashboard.component';
// import { MicropocketDashboardComponent } from './micropocket-dashboard/micropocket-dashboard.component';
// import { OwmsDashboardComponent } from './owms-dashboard/owms-dashboard.component';
// import { SachivalayamDashboardComponent } from './sachivalayam-dashboard/sachivalayam-dashboard.component';
import { SachivalyamHomeDashboardComponent } from './sachivalyam-home-dashboard/sachivalyam-home-dashboard.component';
// import { GramaWardSachivalyamDashboardComponent } from './grama-ward-sachivalyam-dashboard/grama-ward-sachivalyam-dashboard.component';
import { UlbDashboardComponent } from './enterprise/ulb-dashboard/ulb-dashboard.component';
import { DesludgingDashboardComponent } from './enterprise/desludging-dashboard/desludging-dashboard.component';
import { RouteDeviationDashboardComponent } from './enterprise/route-deviation-dashboard/route-deviation-dashboard.component';
import { StateDashboardComponent } from './enterprise/state-dashboard/state-dashboard.component';
import { DoorDoorCollectionDashboardComponent } from './enterprise/door-door-collection-dashboard/door-door-collection-dashboard.component';
import { VehicleDashboardComponent } from './enterprise/vehicle-dashboard/vehicle-dashboard.component';
import { VendorDashboardComponent } from './enterprise/vendor-dashboard/vendor-dashboard.component';
import { TripsDashboardComponent } from './enterprise/trips-dashboard/trips-dashboard.component';
import { DriverDashboardComponent } from './enterprise/driver-dashboard/driver-dashboard.component';
import { SvmDashboardComponent } from './enterprise/svm-dashboard/svm-dashboard.component';
import { RuralDashboardComponent } from './enterprise/rural-dashboard/rural-dashboard.component';
import { UrbanDashboardComponent } from './enterprise/urban-dashboard/urban-dashboard.component';
import { DistrictDashboardComponent } from './enterprise/district-dashboard/district-dashboard.component';
import { MandalDashboardComponent } from './enterprise/mandal-dashboard/mandal-dashboard.component';
import { SachivalayamDashboardComponent } from './enterprise/svm-dashboard/sachivalayam-dashboard/sachivalayam-dashboard.component';
import { AnalysisDashboardComponent } from './enterprise/analysis-dashboard/analysis-dashboard.component';
import { TripRoutesDashboardComponent } from './enterprise/trip-routes-dashboard/trip-routes-dashboard.component';
import { MonitoringDashboardComponent } from './monitoring-dashboard/monitoring-dashboard.component';
import { TripsCvrgeDashboardComponent } from './enterprise/trips-cvrge-dashboard/trips-cvrge-dashboard.component';
import { UlbTripsCvrgeDashboardComponent } from './enterprise/ulb-trips-cvrge-dashboard/ulb-trips-cvrge-dashboard.component';
import { SvmTripsCvrgeDashboardComponent } from './enterprise/svm-trips-cvrge-dashboard/svm-trips-cvrge-dashboard.component';
import { StateLevelDahboardComponent } from './enterprise/revised/state-level-dahboard/state-level-dahboard.component';
import { TripsCvrgeScndryDashboardComponent } from './enterprise/trips-cvrge-scndry-dashboard/trips-cvrge-scndry-dashboard.component';
import { TripsCvrgeScndryUlbDashboardComponent } from './enterprise/trips-cvrge-scndry-ulb-dashboard/trips-cvrge-scndry-ulb-dashboard.component';
import { ScndryTripsCvrgeDashboardComponent } from './scndry-trips-cvrge-dashboard/scndry-trips-cvrge-dashboard.component';
import { SacDashboardComponent } from './enterprise/sac-dashboard/sac-dashboard.component';
import { AlerDdashboardComponent } from './aler-ddashboard/aler-ddashboard.component';
import { ApsflOmsDashboardComponent } from './apsfl/apsfl-oms-dashboard/apsfl-oms-dashboard.component';
import { ApsflOmsDistrictDashboardComponent } from './apsfl/apsfl-oms-district-dashboard/apsfl-oms-district-dashboard.component';
import { WrokProfileComponent } from './apsfl/wrok-profile/wrok-profile.component';
import { OmsAssetDashboardComponent } from './apsfl/oms-asset-dashboard/oms-asset-dashboard.component';
import { EmplyePrfleComponent } from './apsfl/emplye-prfle/emplye-prfle.component';
import { SubstationsComponent } from './apsfl/substations/substations.component';
import { AttndceDashboardComponent } from './apsfl/attndce-dashboard/attndce-dashboard.component';
import { WardsCoverageDashboardComponent } from './enterprise/wards-coverage-dashboard/wards-coverage-dashboard.component';
import { WardsCoverageUlbDashboardComponent } from './enterprise/wards-coverage-ulb-dashboard/wards-coverage-ulb-dashboard.component';
import { WardTripCvrgeDashboardComponent } from './enterprise/ward-trip-cvrge-dashboard/ward-trip-cvrge-dashboard.component';
// import { ComplaintDashboardComponent } from './apsfl/complaint-dashboard/complaint-dashboard.component';
import { ComplaintDashboardComponentNew } from './apsfl/Complaint-dashboard-new/complaint-dashboard.componentNew';

// import { EvmDashboardComponent } from './evm-dashboard/evm-dashboard.component';
// import { MicropocketDashboardComponent } from '../ulb/micropocket-dashboard/micropocket-dashboard.component';
const routes: Routes = [
  //  { path: 'home', component: HomeDashboardComponent , pathMatch: 'full' },
  //  { path: 'dashboard', component: OwmsDashboardComponent , pathMatch: 'full' },
  // { path: 'collect', component: ChargesDashboardComponent , pathMatch: 'full' },
  // { path: 'fines', component: FinesDashboardComponent , pathMatch: 'full' },
  // { path: 'wards', component: WardsDashboardComponent , pathMatch: 'full' },
  // { path: 'micropocket', component: MicropocketDashboardComponent , pathMatch: 'full' },
  // { path: 'sachivalayam', component: SachivalayamDashboardComponent , pathMatch: 'full' },
  // { path: 'home-dashboard', component: SachivalyamHomeDashboardComponent , pathMatch: 'full' },
  // { path: 'sachivalayam-services', component: GramaWardSachivalyamDashboardComponent , pathMatch: 'full' },
  { path: 'ulb-dashboard', component: UlbDashboardComponent , pathMatch: 'full' },
  { path: 'desludging-dashboard', component: DesludgingDashboardComponent , pathMatch: 'full' },
  { path: 'routeDeviation-dashboard', component: RouteDeviationDashboardComponent , pathMatch: 'full' },
  { path: 'state-dashboard', component: StateDashboardComponent , pathMatch: 'full' },
  { path: 'doorCollection-dashboard', component: DoorDoorCollectionDashboardComponent , pathMatch: 'full' },
  { path: 'vehicle-dashboard', component: VehicleDashboardComponent , pathMatch: 'full' },
  { path: 'driver-dashboard', component: DriverDashboardComponent , pathMatch: 'full' },
  { path: 'vendor-dashboard', component: VendorDashboardComponent , pathMatch: 'full' },
  { path: 'trips-dashboard', component: TripsDashboardComponent , pathMatch: 'full' },
  { path: 'svm-dashboard', component: SvmDashboardComponent , pathMatch: 'full' },
  { path: 'rural-dashboard', component: RuralDashboardComponent , pathMatch: 'full' },
  { path: 'urban-dashboard', component: UrbanDashboardComponent , pathMatch: 'full' },
  { path: 'district-dashboard', component: DistrictDashboardComponent , pathMatch: 'full' },
  { path: 'mandal-dashboard', component: MandalDashboardComponent , pathMatch: 'full' },
  { path: 'sachivalayam-dashboard', component: SachivalayamDashboardComponent , pathMatch: 'full' },
  { path: 'anlsys', component: AnalysisDashboardComponent , pathMatch: 'full' },
  { path: 'Trips-routes-dashboard', component: TripRoutesDashboardComponent , pathMatch: 'full' },
  { path: 'coc-dashboard', component: MonitoringDashboardComponent , pathMatch: 'full' },
  { path: 'tripCvrge-dashboard', component: TripsCvrgeDashboardComponent , pathMatch: 'full' },
  { path: 'ulbtripCvrge-dashboard', component: UlbTripsCvrgeDashboardComponent , pathMatch: 'full' },
  { path: 'svmtripCvrge-dashboard', component: SvmTripsCvrgeDashboardComponent , pathMatch: 'full' },
  { path: 'state-level-dashboard', component: StateLevelDahboardComponent , pathMatch: 'full' },
  { path: 'scndrytripCvrge-dashboard', component: TripsCvrgeScndryDashboardComponent , pathMatch: 'full' },
  { path: 'scndrytripulbCvrge-dashboard', component: TripsCvrgeScndryUlbDashboardComponent , pathMatch: 'full' },
  { path: 'scndrytripvechileCvrge-dashboard', component: ScndryTripsCvrgeDashboardComponent , pathMatch: 'full'},
  { path: 'district-level-dashboard', component: ApsflOmsDistrictDashboardComponent , pathMatch: 'full'},

  { path: 'assets-dashboard', component: OmsAssetDashboardComponent , pathMatch: 'full'},

  { path: 'sac-dashboard', component: SacDashboardComponent , pathMatch: 'full' },
  // { path: 'evm-dashboard', component: EvmDashboardComponent , pathMatch: 'full' },
  { path: 'alert-dashboard', component: AlerDdashboardComponent , pathMatch: 'full' },
  { path: 'apsfloms', component: ApsflOmsDashboardComponent , pathMatch: 'full' },
  { path: 'wrk', component: WrokProfileComponent , pathMatch: 'full' },  
  { path: 'substation', component: SubstationsComponent , pathMatch: 'full' },
  { path: 'attendence', component: AttndceDashboardComponent , pathMatch: 'full' },
  { path: 'wrdCvrge-dashboard', component: WardsCoverageDashboardComponent , pathMatch: 'full' },
  { path: 'wrdtripCvrge-dashboard', component: WardsCoverageUlbDashboardComponent , pathMatch: 'full' },
  { path: 'wardVehtripCvrge-dashboard', component: WardTripCvrgeDashboardComponent , pathMatch: 'full' },

  { path: 'complaint-dashboard', component: ComplaintDashboardComponentNew , pathMatch: 'full' },
  
  { path: '**', redirectTo : '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
