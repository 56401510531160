import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TimelineDataComponent } from '../timeline/timeline-data/timeline-data.component';
import { ServiceAssetProfilePageComponent } from '../service-request-profile/service-asset-profile-page/service-asset-profile-page.component';
import { IncidentComponent } from '../asset-ticketing/incident/incident.component';
import { ComplaintComponent } from './complaint/complaint.component';
// import { ComplaintDashboardComponent } from './complaint-dashboard/complaint-dashboard.component';
// import { MyComplaintsComponent } from './my-complaints/my-complaints.component';
// import { ComplaintInboxComponent } from './complaint-inbox/complaint-inbox.component';

const routes: Routes = [
  { path: 'add-complaint', component: ComplaintComponent, pathMatch: 'full' },
   { path: '**', redirectTo: 'add-complaint' },
  // ... other routes
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComplaintManagementRoutingModule { }
