import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

interface TableData {
  sno: number;
  fromBranch: string;
  toBranch: string;
  delivery: string;
  detail: string;
  challanNo: string;
  category: string;
  inventory: string;
  make: string;
  model: string;
  serial: string;
  qty: number;
  macAddress: string;
}

@Component({
  selector: 'app-outward-brnch-invntrylst',
  templateUrl: './outward-brnch-invntrylst.component.html',
  styleUrls: ['./outward-brnch-invntrylst.component.css']
})
export class OutwardBrnchInvntrylstComponent {

  listOfData = [
    {
      sno: 1,
      fromBranch: 'Branch A',
      toBranch: 'Branch B',
      delivery: '2024-12-10',
      detail: 'Details 1',
      challanNo: 'CH-001',
      category: 'Category A',
      inventory: 'Item 1',
      make: 'Make A',
      model: 'Model A',
      serial: 'SER123',
      qty: 10,
      macAddress: 'AA:BB:CC:DD:EE:FF'
    },
    {
      sno: 2,
      fromBranch: 'Branch C',
      toBranch: 'Branch D',
      delivery: '2024-12-11',
      detail: 'Details 2',
      challanNo: 'CH-002',
      category: 'Category B',
      inventory: 'Item 2',
      make: 'Make B',
      model: 'Model B',
      serial: 'SER456',
      qty: 5,
      macAddress: 'FF:EE:DD:CC:BB:AA'
    },
    // Add more records as needed
  ];

  // Pagination and search controls
  pageSize: number = 10;
  currentPage: number = 1;
  searchText: string = '';
  totalEntries: number = this.listOfData.length;
  filteredData: { sno: number; fromBranch: string; toBranch: string; delivery: string; detail: string; challanNo: string; category: string; inventory: string; make: string; model: string; serial: string; qty: number; macAddress: string; }[];

  // Go Back Functionality
  goBack(): void {
    console.log('Navigating back to the home page...');
    // Implement your navigation logic here (e.g., router.navigate(['/home']))
  }

  // Export to Excel
  exportToExcel(): void {
    console.log('Exporting data to Excel...');
    // Implement export functionality using a library like SheetJS (xlsx) if needed
  }

  // On Page Size Change
  onPageSizeChange(newPageSize: number): void {
    this.pageSize = newPageSize;
    this.updateGrid();
  }

  // On Page Index Change
  onPageIndexChange(newPageIndex: number): void {
    this.currentPage = newPageIndex;
    this.updateGrid();
  }



  // Update Grid Data (optional)
  private updateGrid(): void {
    console.log(`Current Page: ${this.currentPage}, Page Size: ${this.pageSize}`);
    // Handle pagination or other grid updates if needed
  }
  onSearch(event: any): void {
    const searchValue = event?.value; // Extract the value from the event object
    console.log('Search text:', searchValue);
    // Apply the search logic
    if (searchValue) {
      this.filteredData = this.listOfData.filter(item =>
        Object.values(item).some(value =>
          String(value).toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      this.filteredData = [...this.listOfData]; // Reset to original data if search text is empty
    }
  }
  
}
