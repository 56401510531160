import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/providers/api.service';

@Component({
  selector: 'app-activity-track',
  templateUrl: './activity-track.component.html',
  styleUrls: ['./activity-track.component.scss']
})
export class ActivityTrackComponent implements OnInit {
  activityForm: FormGroup;
  tntLst: any = [];
  wrkGrpLst: any = [];
  wrkTypLst: any = [];
  clntDtls: any;
  wrkLst: any=[];
  wrkStsLst: any=[];
  opnSdebar: boolean=true;
  isCollapsed = false;

  constructor(private apiServ: ApiService) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
  }
  ngOnInit(): void {
    this.activityForm = new FormGroup({
      tnt_id: new FormControl(''),
      wrk_grp_id: new FormControl(''),
      wrk_typ_id: new FormControl(''),
      wrk_sts_id: new FormControl(''),
    });
    this.getMasterData();
  }
  ontntSelects(tnt_id){

  }
  getMasterData() {
    this.apiServ.get(`apsfl/app/wrkGrpLst`).subscribe((res) => {
      console.log(res)
      if(res['status']==200){
        this.wrkGrpLst = res['data'][0];
        this.wrkStsLst= res['data'][4];
      }
      // this.mndlLst = response.data[1];
      // this.isueTypeLst = response.data[2];
      // this.emplyLst = response.data[3];

    });
    // this.apiServ.get(`apsfl/app/getDrpDwnDta`).subscribe((res) => {
    //   console.log(res)

    //   if(res['status']==200){
    //     this.wrkLst = res['data'][1];
    //   }

    // });
    
  }
  onWrkGrpSlct(wrk_grp_id){
    if(wrk_grp_id>0){
      this.apiServ.get(`apsfl/app/wrktypLst/${wrk_grp_id}`).subscribe((res) => {
        if(res['status']==200){
          this.wrkTypLst = res['data'];
        }
      });  
    }

  }
  closeSdr() {
    this.opnSdebar = false
  }
  closeSidebar() {
    this.isCollapsed = true;
    this.opnSdebar = false;

  }

  openSidebar() {
    this.isCollapsed = false;
    this.opnSdebar = true;


  }
}
