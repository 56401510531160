<div class="page-header">
  <div class="row align-items-md-center">
    <div class="col-md-12" style="margin-left: 11px">
      <div class="media m-v-10">
        <div class="media-body m-l-15 header-borders" style="    justify-content: space-between;display: flex;">
          <h6 class="mb-0 header-label" style="margin-bottom:0px">Asset Tracking Types</h6>
          <button nz-button nzType="default" (click)="goBack()" style="box-shadow: 0 4px 12px 0 rgb(123 149 153 / 24%);
                  color: #1969cd;
                  font-weight: 500; float:right"><i nz-icon nzType="left"></i> Go back</button>
        </div>
      </div>
    </div>

  </div>
</div>







<div *ngIf="usrAcsErrMsg == false">
  <div nz-row class="gridTop" *ngIf="(USER_PERMISSIONS.slct_in==1)?true:false">
    <ds-loading *ngIf="loader"></ds-loading>
    <div class="ds-card grid">
      <dx-data-grid id="gridContainer" [dataSource]="gridData" [remoteOperations]="false" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
        [showRowLines]="true" [showBorders]="true" [showBorders]="true" style="width:100%"
        (onToolbarPreparing)="(USER_PERMISSIONS.insrt_in==1)?onToolbarPreparing($event):false">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
          [showInfo]="true">
        </dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-column-chooser [enabled]="true" mode="select">
        </dxo-column-chooser>
        <dxo-export [enabled]="(USER_PERMISSIONS.exprt_in==1)?true:false" [allowExportSelectedData]="true"
          fileName="Designations List"></dxo-export>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column *ngFor="let f of columnDefs" [visible]="!f.hide" [dataField]="f.field" [caption]="f.headerName"
          [alignment]="f.alignment" [width]="f.width" [allowResizing]="true" [allowFiltering]="f.filter">
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" type="buttons" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false"
          [width]="100" caption="Edit" cellTemplate="editTemplate">
          <div *dxTemplate="let data of 'editTemplate'">
            <div class="tblEdtBtn" (click)="onEdit2(data)">
              Edit
            </div>
          </div>
        </dxi-column>
        <dxi-column [showInColumnChooser]="false" [visible]="(USER_PERMISSIONS.dlte_in==1)?true:false" type="buttons"
          [width]="100" caption="Delete" cellTemplate="delTemplate">
          <div *dxTemplate="let data of 'delTemplate'">
            <div class="tblDelBtn" (click)="onDelete2(data)">
              Delete
            </div>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
    <div *ngIf="(USER_PERMISSIONS.slct_in==1)?false:true" class="permMsg">{{mainMessage}}</div>
  </div>

  <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="shwSidebar" [nzTitle]="sidebarHeading" (nzOnClose)="cancel()">
    <ng-container *nzDrawerContent>
      <form nz-form [formGroup]="typeForm">
        <div class="input-row mt-2" nz-row>
          <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" *ngIf="!delete">
            <div class="inptLbl">Select Asset Type&nbsp;<span class="impFld">*</span></div>
            <nz-select style="width:100%;" nzPlaceHolder="Select Department" formControlName="ast_ctgry_id"
              (ngModelChange)="getCategoryType($event)">
              <nz-option *ngFor="let d of categories" [nzValue]="d.wrk_ast_ctgry_id" [nzLabel]="d.wrk_ast_ctgry_nm">
              </nz-option>
            </nz-select>
          </div>
          <div nz-col nzXs="12" nzSm="12" nzMd="6" nzLg="6" nzXl="24" *ngIf="delete">
            <div class="inptLbl">Select Asset Type&nbsp;<span class="impFld">*</span>
              <p>{{delData.wrk_ast_ctgry_nm}}</p>
            </div>
          </div>


          <div *ngIf="flag" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="schvlmMrgn">
            <div class="inptLbl">Enter {{ catgryType}}<span style="color: red;">*</span></div>
            <input *ngIf="!delete" type="text" nz-input formControlName="ast_typ_nm" />
            <p *ngIf="delete">{{delData.ast_typ_nm}}</p>
          </div>
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="schvlmMrgn">
            <div class="inptLbl">Description<span style="color: red;">*</span></div>
            <input *ngIf="!delete" type="text" nz-input formControlName="dscn_tx" />
            <p *ngIf="delete">{{delData.dscn_tx}}</p>
          </div>
        </div>
        <button nz-button nzType="primary" class="sbmtBtm" (click)="savetype()"
          *ngIf="shwschvlymEditDiv==false && shwschvlymDelDiv==false  && flag">Submit</button>
        <button nz-button nzType="primary" class="sbmtBtm" (click)="saveEdittype()"
          *ngIf="shwschvlymEditDiv==true && shwschvlymDelDiv==false">Update</button>
        <button nz-button nzType="primary" class="sbmtBtm" *ngIf="shwschvlymEditDiv==false && shwschvlymDelDiv==true"
          nz-popconfirm nzPopconfirmTitle="Are you sure delete this ?" nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="saveDeletetype()" (nzOnCancel)="oncancel()">Delete</button>
        <button nz-button nzType="default" class="cnclBtn" (click)="cancel()">Cancel</button>
        <div style="text-align: center; margin-top: 2px; color: rgb(35, 96, 209)" *ngIf="shwLdng">
          <div class="load-4">
            <div class="ring-1"></div>
          </div>
          Please wait....
        </div>
      </form>
    </ng-container>
  </nz-drawer>
</div>
<div *ngIf="usrAcsErrMsg == true" class="permMsg">
  <nz-result nzStatus="error" nzTitle="Access Denied"
    nzSubTitle="You do not have permissions to do this operation. Please contact Administrator to get permissions.">
  </nz-result>
</div>