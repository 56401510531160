<div class="complaint-details-container" *ngIf="ticketDtls?.length > 0; else noTicket">
  <div class="details-header">
    <div class="header-left">
      <h2>Ticket #{{selectedTicket?.tckt_id}}
      </h2>
      <nz-tag *ngIf="ticketDtls[0]?.wrk_sts_nm" [class]="'status-' + (ticketDtls[0]?.status || '').toLowerCase()">
        {{ticketDtls[0]?.wrk_sts_nm}}
      </nz-tag>
      <nz-tag *ngIf="ticketDtls[0]?.priority" [class]="'priority-' + (ticketDtls[0]?.priority || '').toLowerCase()">
        {{ticketDtls[0]?.priority}}
      </nz-tag>
    </div>
    <div class="header-right">
      <span class="date" *ngIf="ticketDtls[0]?.wrk_strt_dt">
        <i nz-icon nzType="calendar" nzTheme="outline"></i>
        Created on: {{ticketDtls[0]?.wrk_strt_dt}}
      </span>
    </div>
  </div>

  <div class="details-card">

   
    <!-- <nz-descriptions-item nzTitle="District">
        <i nz-icon nzType="environment" nzTheme="outline"></i>
        {{ticketDtls[0]?.dstrct_nm || 'N/A'}}
      </nz-descriptions-item> -->

    <div *ngIf="ticketDtls[0]?.wrk_grp_id==1">
      <nz-descriptions nzTitle="Complaint Information" [nzColumn]="2" nzBordered class="mt-4">
        <nz-descriptions-item nzTitle="Complaint Type">
          <i nz-icon nzType="folder" nzTheme="outline"></i>
          {{ticketDtls[0]?.wrk_grp_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="CAF Name">
          <i nz-icon nzType="user" nzTheme="outline"></i>
          {{ticketDtls[0]?.cstmr_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="CAF Count">
          <i nz-icon nzType="user" nzTheme="outline"></i>
          {{ticketDtls[0]?.cstmr_efctd_ct || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Call type">
          <i nz-icon nzType="user" nzTheme="outline"></i>
          {{ticketDtls[0]?.cl_typ_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Complaint Category">
          <i nz-icon nzType="tag" nzTheme="outline"></i>
          {{ticketDtls[0]?.wrk_typ_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Priority">
          <i nz-icon nzType="flag" nzTheme="outline"></i>
          {{ticketDtls[0]?.priority || 'N/A'}}
        </nz-descriptions-item>
      </nz-descriptions>
    </div>
    <div *ngIf="ticketDtls[0]?.wrk_grp_id==2">
      <nz-descriptions nzTitle="Complaint Information" [nzColumn]="2" nzBordered class="mt-4">
        <nz-descriptions-item nzTitle="Complaint Type">
          <i nz-icon nzType="folder" nzTheme="outline"></i>
          {{ticketDtls[0]?.wrk_grp_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Network">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          {{ticketDtls[0]?.ntwrk_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Ticket Type">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          {{ticketDtls[0]?.tckt_typ_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Technology">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          {{ticketDtls[0]?.tcnlgy_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Sub Technology">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          {{ticketDtls[0]?.sb_tcnlgy_nm || 'N/A'}}
        </nz-descriptions-item>

        <nz-descriptions-item nzTitle="Complaint Category">
          <i nz-icon nzType="tag" nzTheme="outline"></i>
          {{ticketDtls[0]?.wrk_typ_nm || 'N/A'}}
        </nz-descriptions-item>
      </nz-descriptions>
    </div>
    <div
      *ngIf=" ticketDtls[0]?.wrk_grp_id==3 || ticketDtls[0]?.wrk_grp_id==4 || ticketDtls[0]?.wrk_grp_id==5  || ticketDtls[0]?.wrk_grp_id==6">
      <nz-descriptions nzTitle="Complaint Information" [nzColumn]="2" nzBordered class="mt-4">
        <nz-descriptions-item nzTitle="Complaint Type">
          <i nz-icon nzType="folder" nzTheme="outline"></i>
          {{ticketDtls[0]?.wrk_grp_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Ticket Type">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          {{ticketDtls[0]?.tckt_typ_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="District">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          {{ticketDtls[0]?.dstrct_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Link Name">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          {{ticketDtls[0]?.lnk_nm || 'N/A'}}
        </nz-descriptions-item>

        <nz-descriptions-item nzTitle="Electronic">
          <i nz-icon nzType="tag" nzTheme="outline"></i>
          {{ticketDtls[0]?.elctrnc_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Complaint Category">
          <i nz-icon nzType="tag" nzTheme="outline"></i>
          {{ticketDtls[0]?.wrk_typ_nm || 'N/A'}}
        </nz-descriptions-item>
      </nz-descriptions>

    </div>




    <div
      *ngIf=" ticketDtls[0]?.wrk_grp_id==7 || ticketDtls[0]?.wrk_grp_id==8 || ticketDtls[0]?.wrk_grp_id==9  || ticketDtls[0]?.wrk_grp_id==10">
      <nz-descriptions nzTitle="Complaint Information" [nzColumn]="2" nzBordered class="mt-4">
        <nz-descriptions-item nzTitle="Complaint Type">
          <i nz-icon nzType="folder" nzTheme="outline"></i>
          {{ticketDtls[0]?.wrk_grp_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Ticket Type">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          {{ticketDtls[0]?.tckt_typ_nm || 'N/A'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Complaint Category">
          <i nz-icon nzType="tag" nzTheme="outline"></i>
          {{ticketDtls[0]?.wrk_typ_nm || 'N/A'}}
        </nz-descriptions-item>
      </nz-descriptions>

    </div>




    <nz-descriptions [nzTitle]="ticketDtls[0]?.wrk_sts_id==12 ? ' Closer Information' : ' Assigned Information'" [nzColumn]="2" nzBordered>
      <nz-descriptions-item nzTitle="Employee Name">
        <i nz-icon nzType="user" nzTheme="outline"></i>
        {{ticketDtls[0]?.emple_nm || 'N/A'}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Designation">
        <i nz-icon nzType="user" nzTheme="outline"></i>
        {{ticketDtls[0]?.dsgns_nm || 'N/A'}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Customer ID">
        <i nz-icon nzType="idcard" nzTheme="outline"></i>
        {{ticketDtls[0]?.emple_nu || 'N/A'}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="District">
        <i nz-icon nzType="environment" nzTheme="outline"></i>
        {{ticketDtls[0]?.dstrct_nm || 'N/A'}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Contact Number">
        <i nz-icon nzType="phone" nzTheme="outline"></i>
        {{ticketDtls[0]?.mble_ph || 'N/A'}}
      </nz-descriptions-item>
    </nz-descriptions>



    <div class="description-section">
      <h3>
        <i nz-icon nzType="file-text" nzTheme="outline"></i>
        Description
      </h3>
      <p>{{ticketDtls[0]?.oms_wrm_dsc_tx || 'No description provided.'}}</p>
    </div>

    <div class="timeline-container">
      <div class="modern-timeline">
        <h3 class="section-title">
          <span>
            <i nz-icon nzType="history" nzTheme="outline"></i>
            Complaint Progress
          </span>
          <button nz-button nzType="primary" nzSize="middle" (click)="assignFunc()" *ngIf="assgbBtnEnbled" class="assign-btn">
            <i nz-icon nzType="user-add" nzTheme="outline"></i>
            Assign
          </button>
        </h3>

        <div class="timeline-wrapper">
          <div class="timeline-item" *ngFor="let item of timelineData; let first = first; let last = last">
            <div class="timeline-badge" 
                 [class.active]="first && item.status == 'open'" 
                 [class.completed]="!first"
                 [ngClass]="{
                   'status-open': item.status == 'open',
                   'status-progress': item.status == 'in-progress',
                   'status-closed': item.status == 'Closed'
                 }">
              <i nz-icon [nzType]="first && item.status == 'open' ? 'sync' : 'check'" nzTheme="outline"></i>
            </div>

            <div class="timeline-panel">
              <div class="timeline-header">
                <div class="status-tag" [class.active]="first" [ngClass]="{
                  'status-open': first && item.status == 'open',
                  'status-completed': !first
                }">
                  {{first && item.status == 'open' ? 'Current Status' : 'Completed'}}
                </div>
                <div *ngIf="item.status === 'open'" class="timestamp">
                  <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
                  {{item.frwd_ts}}
                </div>
                <div *ngIf="item.status === 'Closed'" class="closed-status">
                  <i nz-icon nzType="check-circle" nzTheme="outline"></i>
                  {{item.status}}
                </div>
              </div>

              <div class="timeline-body">
                <div class="employee-info">
                  <nz-avatar [nzSrc]="item.avatar" [nzSize]="32" nzIcon="user"></nz-avatar>
                  <div class="employee-details">
                    <span class="name">{{item.frwd_empl_nm}}</span>
                    <span class="id">#{{item.frwd_empl_id}}</span>
                  </div>
                </div>

                <div class="action-details">
                  <div class="comment-section row">
                    <div class="comment-content col-md-9">
                      <p class="comment">{{item.stg_cmnt_tx}}</p>
                    </div>
                    <div class="work-image col-md-3" *ngIf="item.wrk_img_fle_pth">
                      <img [src]="'https://ent.wetrackon.com/' + item.wrk_img_fle_pth" alt="Work Image">
                    </div>
                  </div>

                  <div class="forward-info" *ngIf="item.assgn_empl_nm">
                    <div class="forward-header">
                      <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                      Forwarded to
                    </div>
                    <div class="forward-body">
                      <div class="forward-employee">
                        <nz-avatar [nzSrc]="item.frwd_avatar" [nzSize]="24" nzIcon="user"></nz-avatar>
                        <span>{{item.assgn_empl_nm}}</span>
                      </div>
                      <div class="forward-time">
                        <i nz-icon nzType="calendar" nzTheme="outline"></i>
                        {{item.assgn_ts}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<ng-template #noTicket>
  <div class="no-ticket-wrapper">
    <div class="no-ticket-selected">
      <div class="no-ticket-content">
        <div class="icon-wrapper">
          <i nz-icon nzType="file-search" nzTheme="outline"></i>
        </div>
        <h3>No Ticket Selected</h3>
        <p>Please select a ticket from the list to view its details</p>
        <nz-divider class="divider-style"></nz-divider>
        <span class="helper-text">You can click on any ticket from the left panel to view more information</span>
      </div>
    </div>
  </div>
</ng-template>




<nz-drawer [nzVisible]="isDrawerVisible" [nzWidth]="500" nzPlacement="right" nzTitle="Assign Complaint"
  (nzOnClose)="closeDrawer()" >
  <ng-container *nzDrawerContent>
    <form nz-form [formGroup]="assgnForm" class="complaint-form" nzLayout="vertical">
      <div class="form-content">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="24" class="form-col">
            <nz-form-item>
              <nz-form-label [nzRequired]="true" nzFor="wrk_sts">Work Status</nz-form-label>
              <nz-form-control nzErrorTip="Please select a Status">
                <nz-select id="wrk_sts" formControlName="wrk_sts" nzPlaceHolder="Select Status"
                  (ngModelChange)="statusAction($event)" class="status-select" nzShowSearch>
                  <nz-option *ngFor="let d of workStatus" [nzValue]="d.oms_wrk_sts_id"
                    [nzLabel]="d.wrk_sts_dsply_nm"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="24" class="form-col" *ngIf="!isCompletedStatus">
            <nz-form-item>
              <nz-form-label [nzRequired]="true" nzFor="emple_dsgns_id">Designation</nz-form-label>
              <nz-form-control nzErrorTip="Please select a designation">
                <nz-select id="emple_dsgns_id" formControlName="emple_dsgns_id" nzPlaceHolder="Select Designation"
                  (ngModelChange)="loadEmployees($event)" class="designation-select" nzShowSearch>
                  <nz-option *ngFor="let d of designation" [nzValue]="d.emple_dsgns_id"
                    [nzLabel]="d.emple_dsgns_nm"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <!-- 
          <div nz-col [nzSpan]="24" class="form-col" *ngIf="!isStateAdmin && !isCompletedStatus">
            <nz-form-item>
              <nz-form-label [nzRequired]="true" nzFor="dstrct_id">Districts</nz-form-label>
              <nz-form-control nzErrorTip="Please select a district">
                <nz-select id="dstrct_id" formControlName="dstrct_id" nzPlaceHolder="Select Districts"
                (ngModelChange)="loadEmployees($event)"  class="district-select">
                  <nz-option *ngFor="let d of districtLst" [nzValue]="d.dstrt_id" [nzLabel]="d.dstrt_nm"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div> -->

          <div nz-col [nzSpan]="24" class="form-col" *ngIf="!isCompletedStatus">
            <nz-form-item>
              <nz-form-label [nzRequired]="true" nzFor="assigned_employee">Assigned Employee</nz-form-label>
              <nz-form-control nzErrorTip="Please select an employee">
                <nz-select id="assigned_employee" formControlName="assigned_employee" nzPlaceHolder="Select Employee"
                  class="employee-select" nzShowSearch>
                  <nz-option *ngFor="let e of employees" [nzValue]="e.emple_id" [nzLabel]="e.emple_nm"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="24" class="form-col">
            <nz-form-item>
              <nz-form-label nzFor="file_upload">File Attachment</nz-form-label>
              <div class="file-upload-container">
                <input (change)="handleInputChange($event)" type="file" accept=".png,.jpg,.jpeg" #fileInput />
                <small class="file-hint">Accepted formats: PNG, JPG, JPEG</small>
              </div>
            </nz-form-item>
          </div>
    
          <div nz-col [nzSpan]="24" class="form-col">
            <nz-form-item *ngIf="attachments && attachments.length > 0">
              <nz-form-label>Attachments Preview</nz-form-label>
              <div class="attachment-preview">
                <div class="image-container" *ngFor="let attachment of attachments; let i = index">
                  <img class="w-15" [src]="attachment.base64" alt="Attachment Preview" />
                  <button class="remove-button" nz-button nzType="text" nzDanger (click)="removeAttachment(i)">
                    <i nz-icon nzType="close-circle" nzTheme="fill"></i>
                  </button>
                </div>
              </div>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="24" class="form-col">
            <nz-form-item>
              <nz-form-label [nzRequired]="true" nzFor="description">Description</nz-form-label>
              <nz-form-control nzErrorTip="Please enter a description">
                <textarea nz-input id="description" formControlName="description"
                  [nzAutosize]="{ minRows: 3, maxRows: 5 }" placeholder="Enter description"
                  class="description-textarea"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>

      <div class="form-actions">
        <button nz-button nzType="default" (click)="closeDrawer()" class="cancel-button">Cancel</button>
        <button nz-button nzType="primary" (click)="submitComplaint()"
          [disabled]="!assgnForm.valid || settings.isBtnLoading" [nzLoading]="settings.isBtnLoading"
          class="submit-button">
          <span nz-icon nzType="send" nzTheme="outline"></span>
          Submit
        </button>
      </div>
    </form>
  </ng-container>
</nz-drawer>