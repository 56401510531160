import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';

import { DxDataGridModule, DxSelectBoxModule, DxTextBoxModule, DxButtonModule } from 'devextreme-angular';
import { AddInwardComponent } from './add-inward/add-inward.component';
import { CreatePurchaseComponent } from './create-purchase/create-purchase.component';
import { InwardStocklistComponent } from './inward-stocklist/inward-stocklist.component';
import { OutwardBrnchInvntrylstComponent } from './outward-brnch-invntrylst/outward-brnch-invntrylst.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { InventoryManagementRoutingModule } from './invenory-management.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderDetailsPipe } from './dashboard/order-details.pipe';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
  declarations: [
    AddInwardComponent,
    CreatePurchaseComponent,
    InwardStocklistComponent,
    OutwardBrnchInvntrylstComponent,
    DashboardComponent,
    OrderDetailsPipe
  ],
  imports: [
    NzDrawerModule,
    DxButtonModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxDataGridModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InventoryManagementRoutingModule,
    NzLayoutModule,
    NzButtonModule,
    NzTableModule,
    NzSelectModule,
    NzInputModule,
    NzDatePickerModule,
    NzIconModule,
    NzMessageModule,
    NzInputNumberModule,
    NzUploadModule,
    NzCardModule,
    NzCheckboxModule,
    NzPaginationModule,
    NzSpinModule,
    NzFormModule,
    NzGridModule,
    NzDividerModule,
    NgApexchartsModule
  ],
  exports: [
    AddInwardComponent,
    CreatePurchaseComponent,
    InwardStocklistComponent,
    OutwardBrnchInvntrylstComponent
  ]
})
export class InventoryManagementModule { }
