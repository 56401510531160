import { Component, OnInit, ViewChild } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-complaint-dashboard',
  templateUrl: './complaint-dashboard.component.html',
  styleUrls: ['./complaint-dashboard.component.scss']
})
export class ComplaintDashboardComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  header_label = {
    name: 'Complaint Dashboard',
    dtepkrlbl: false
  };

  loading = false;
  selectedComplaintType: number | null = null;
  complaintCards: any[] = [];
  ticketsData: any[] = [];
  selectedCard: any;
  columnDefs: { headerName: string; field: string; width: string; alignment: string; }[];

  

  constructor(
    private apiService: AppsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadComplaintTypesCount();
    this.loadComplaintTypeTickets(1)
  }

  loadComplaintTypesCount() {
    this.loading = true;
    this.apiService.get('complaint/complaint-types-count').subscribe({
      next: (response: any) => {
        if (response && response.data) {
          this.complaintCards = response.data.map((item: any) => ({
            ...item,
            total: item.ttl,
            pending: item.pending,
            completed: item.completed,
            color: item.clr_cd
          }));
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('Error loading complaint types:', error);
        this.loading = false;
      }
    });
  }

  onCardSelect(card: any) {
    this.selectedCard = card;
    this.selectedComplaintType = card.wrk_grp_id;
    this.loadComplaintTypeTickets(card.wrk_grp_id);
  }

  loadComplaintTypeTickets(complaintTypeId: number) {
    console.log(complaintTypeId)
    this.loading = true;
    this.apiService.get(`complaint/complaint-types/tickts/${complaintTypeId}`).subscribe({
      next: (response: any) => {
        if (response && response.data) {
          this.ticketsData = response.data;
          console.log('Tickets Data:', this.ticketsData);
          this.columnDefs = [
            { headerName: 'Ticket ID', field: 'tckt_id', width: '100', alignment: 'center' },
            { headerName: 'Status', field: 'wrk_sts_nm', width: '120', alignment: 'center' },
            { headerName: 'Priority', field: 'priority', width: '100', alignment: 'center' },
            { headerName: 'Customer', field: 'cstmr_nm', width: '150', alignment: 'left' },
            { headerName: 'Type', field: 'wrk_typ_nm', width: '150', alignment: 'left' },
            { headerName: 'District', field: 'dstrct_nm', width: '120', alignment: 'left' },
            { headerName: 'Assigned To', field: 'emple_nm', width: '150', alignment: 'left' },
            { headerName: 'Start Date', field: 'wrk_strt_dt', width: '120', alignment: 'center' },
            { headerName: 'Description', field: 'oms_wrm_dsc_tx', width: '200', alignment: 'left' }
          ];
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('Error loading tickets:', error);
        this.loading = false;
      }
    });
  }

  // Grid event handlers
  onRowClick(e: any) {
    // Handle row click if needed
    console.log('Row clicked:', e.data);
  }
}
