<div class="content-layout">
    <!-- Left Side - Complaint Tickets List -->
    <div class="tickets-list-container">
        <app-complaint-tickets-list (ticketSelected)="onTicketSelected($event)"
            (newComplaintClicked)="showComplaintForm()"></app-complaint-tickets-list>
    </div>
    <div class="ticket-details">
        <app-complaint-details 
          [selectedTicket]="selectedTicket"
          (complaintSubmitted)="refreshTicketsListAssgn()">
        </app-complaint-details>   
    </div>


</div>

<!-- </nz-layout> -->

<nz-drawer 
    [nzVisible]="isDrawerVisible" 
    [nzWidth]="720" 
    nzPlacement="right" 
    nzTitle="Add New Complaint"
    (nzOnClose)="closeComplaintForm()">
    <ng-container *nzDrawerContent>
        <app-complaint-form 
            (complaintSubmitted)="refreshTicketsList(); closeComplaintForm()"
            (closeModal)="closeComplaintForm()">
        </app-complaint-form>
    </ng-container>
</nz-drawer>
<!-- </div> -->