import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AtomServiceService } from 'src/app/shared/services/atom-service.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {
  shwDeleteCnfrm: boolean;
  USER_PERMISSIONS: any = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };
  usrAcsErrMsg: boolean = false;
  columnDefs;
  mainMessage;
  loader;
  gridData;
  shwSidebar;
  sidebarHeading;
  cityForm: FormGroup;
  shwLdng;
  editdata;
  delData;
  delete: boolean = false;

  constructor(
    private fb: FormBuilder,
    private themeService: ThemeConstantService,
    public apiSrv: AppsService,
    public atmSrv: AtomServiceService,
    private message: NzMessageService,
    private modal: NzModalService,
    private location: Location,
    private nzMessageService: NzMessageService
  ) { }

  ngOnInit() {
    this.cityForm = new FormGroup({
      cty_nm: new FormControl('')
    });
    this.getGridData();
  }

  getGridData() {
    this.mainMessage = null;
    this.loader = true;
    this.usrAcsErrMsg = false;

    this.apiSrv.get('/core/api/master/cities/list').subscribe((res) => {
      this.loader = false;
      if (res['status'] == 200) {
        this.gridData = res['data'];
        this.columnDefs = [
          { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 100, filter: false },
          { headerName: 'City Name', field: 'cty_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: 265 },
          { headerName: 'Creation Time', field: 'i_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
          { headerName: 'Updated Time', field: 'u_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
          { headerName: 'Created By', field: 'crte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
          { headerName: 'Updated By', field: 'updte_usr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 200, sortable: true, filter: true },
        ];
      } else {
        this.mainMessage = { msg: res['message'], type: 'error' };
      }
    }, error => {
      this.loader = false;
      this.mainMessage = { msg: error.message, type: 'error' };
    });
  }

  editRecord(data) {
    this.editdata = data;
    this.cityForm.patchValue({
      cty_nm: data.cty_nm
    });
    this.shwDeleteCnfrm = false;

    this.shwSidebar = true;
  }

  deleteRecord(data) {
    this.delData = data;
    this.cityForm.patchValue({
      cty_nm: data.cty_nm
    });
    this.shwDeleteCnfrm = true;
    this.shwSidebar = true;
  }

  confirmDelete() {
    this.shwLdng = true;
    this.apiSrv.delete(`core/api/master/cities/${this.delData.cty_id}`).subscribe((res) => {
      this.shwLdng = false;
      if (res['status'] == 200) {
        this.nzMessageService.success('City deleted successfully');
        this.getGridData();
        this.delete = false;
      } else {
        this.nzMessageService.error(res['message']);
      }
    }, error => {
      this.shwLdng = false;
      this.nzMessageService.error(error.message);
    });
  }

  submitForm() {
    if (this.cityForm.valid) {
      this.shwLdng = true;
      if (this.editdata) {
        this.apiSrv.put(this.cityForm.value, `core/api/master/cities/${this.editdata.cty_id}`).subscribe((res) => {
          this.shwLdng = false;
          if (res['status'] == 200) {
            this.nzMessageService.success('City updated successfully');
            this.getGridData();
            this.closeSidebar();
          } else {
            this.nzMessageService.error(res['message']);
          }
        }, error => {
          this.shwLdng = false;
          this.nzMessageService.error(error.message);
        });
      } else {
        this.apiSrv.post(this.cityForm.value, `core/api/master/cities`).subscribe((res) => {
          this.shwLdng = false;
          if (res['status'] == 200) {
            this.nzMessageService.success('City added successfully');
            this.getGridData();
            this.closeSidebar();
          } else {
            this.nzMessageService.error(res['message']);
          }
        }, error => {
          this.shwLdng = false;
          this.nzMessageService.error(error.message);
        });
      }
    }
  }

  closeSidebar() {
    this.shwSidebar = false;
    this.editdata = null;
    this.cityForm.reset();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add City',
        onClick: this.openSideBar.bind(this, 'new')
      }
    });
  }

  openSideBar(opt) {
    this.shwDeleteCnfrm = false;
    this.cityForm.reset();
    this.shwSidebar = true;
  }
}
