import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ComplaintTicketsListComponent } from './complaint-tickets-list/complaint-tickets-list.component';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent {
  TicketDtlsObj = {
    dataSrc: [
      { id: 1, priority: 'High', department: 'Sales', timestamp: '2m ago', description: 'Issue with product delivery.', showDetails: false },
      { id: 2, priority: 'Medium', department: 'Marketing', timestamp: '5m ago', description: 'Feedback on campaign.', showDetails: false },
      { id: 3, priority: 'Low', department: 'Support', timestamp: '10m ago', description: 'General inquiry.', showDetails: false }
    ]
  };


  settings = {
    entity: "Complaint",
    sidebarHeading: "",
    shwLdg: false,
    shwerrmsg: false,
    showAlert: false,
    shwMasterEditDiv: false,
    shwSidebar: false,
    shwMasterDelDiv: false,
    in_delete_mode: false,
    usrAcsErrMsg: false,
    errMsg: null,
    isBtnLoading: false,
    shwGrd: false,
  };

  assgnForm: FormGroup;
  selectedTicket: any = null;
  districtLst: any[] = [];
  designationLst: any[] = [];
  employees: any[] = [];
  complaint_id: any;
  complaint: any;
  addNewForm: boolean = false;
  updateForm: boolean = false;
  deleteForm: boolean = false;
  show: boolean = false;
  incative: any;
  shouldDisplayColumns: boolean = true;
  isDrawerVisible = false;

  // Arrays for dropdowns
  complaintTypes: string[] = [];
  ticketTypes: string[] = [];
  complaintCategories: string[] = [];
  imagePreview: string | ArrayBuffer | null = null;
  tkt_dstrt_id: any;
  colDef: ({ headerName: string; field: string; alignment: string; filter: boolean; width: string; } | { headerName: string; field: string; alignment: string; filter?: undefined; width?: undefined; })[];
  isFormOpen: boolean;

  @ViewChild(ComplaintTicketsListComponent) ticketsList: ComplaintTicketsListComponent;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: AppsService,
    private message: NzMessageService
  ) {
    this.initForm();
    // this.loadDistricts();
    this.loadDesignations();
  }

  initForm() {
    this.assgnForm = this.fb.group({
      dstrct_id: [null, Validators.required],
      emple_dsgns_id: [null, Validators.required],
      assigned_employee: [null, Validators.required],
      description: ['']
    });
  }

  loadDistricts() {
    this.apiService.get('complaint/districts').subscribe(
      (response: any) => {
        this.districtLst = response['fltr_res'];
      },
      error => {
        this.message.error('Failed to load districts');
      }
    );
  }

  loadDesignations() {
    this.apiService.get('complaint/designations').subscribe(
      (response: any) => {
        this.designationLst = response.data;
      },
      error => {
        this.message.error('Failed to load designations');
      }
    );
  }

  loadEmployees(designationId: any) {
    if (!this.assgnForm || !this.assgnForm.value.dstrct_id || !designationId) {
      return;
    }

    this.apiService.get(`complaint/employees/${this.assgnForm.value.dstrct_id}/${designationId}`).subscribe({
      next: (response: any) => {
        if (response && response.data) {
          this.employees = response.data;
        } else {
          this.employees = [];
        }
      },
      error: (error) => {
        console.error('Error loading employees:', error);
        this.message.error('Failed to load employees');
        this.employees = [];
      }
    });
  }

  setSelectedTicket(ticket: any) {
    this.selectedTicket = ticket;
    this.tkt_dstrt_id = ticket.dstrt_id;
    this.loadDesignations();
  }

  showComplaintForm(): void {
    this.isDrawerVisible = true;
  }

  closeComplaintForm(): void {
    this.isDrawerVisible = false;
    this.selectedTicket = null;
    this.getComplaintDetails(); // Refresh the list after closing
  }

  openComplaintForm() {
    this.isDrawerVisible = true;
  }

  onTicketSelected(event: any) {
    this.selectedTicket = {
      ...event.ticket,
      isAssignedTab: event.isAssignedTab
    };
    this.tkt_dstrt_id = this.selectedTicket.dstrt_id;
    
    // Initialize form if not already initialized
    if (!this.assgnForm) {
      this.initForm();
    }
    
    // Load designations first
    this.loadDesignations();
    
    // Then update form values
    setTimeout(() => {
      this.assgnForm.patchValue({
        dstrct_id: this.selectedTicket.district_id || null,
        emple_dsgns_id: this.selectedTicket.designation_id || null,
        assigned_employee: this.selectedTicket.assigned_employee_id || null,
        description: this.selectedTicket.description || ''
      });
      
      // Only load employees if we have both district and designation
      if (this.selectedTicket.district_id && this.selectedTicket.designation_id) {
        this.loadEmployees(this.selectedTicket.designation_id);
      }
    }, 0);
  }

  submitComplaint() {
    console.log(this.assgnForm);
    let postdata = {
      data: this.assgnForm.value
    }
    let rte = `complaint/complaint/submit`;
    this.apiService.post(postdata, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.message.success('Complaint submitted successfully.');
        this.assgnForm.reset();
        this.settings.shwSidebar = false;
        this.getComplaintDetails();
      }
    });
  }

  updateComplaint() {
    let postdata = {
      complaint_name: this.assgnForm.value.complaint_name,
      complaint_id: this.complaint_id
    }
    const rte = `complaint/complaint/update`;
    this.apiService.put(postdata, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.message.success('Complaint updated successfully.');
        this.assgnForm.reset();
        this.settings.shwSidebar = false;
        this.getComplaintDetails();
      }
    });
  }

  submitComplaintMain() {
    if (this.assgnForm.valid) {
      const formData = this.assgnForm.value;

      // If updating existing complaint
      if (this.complaint_id) {
        formData.complaint_id = this.complaint_id;
        this.apiService.put(formData, 'complaint/complaint/update').subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.message.success('Complaint updated successfully');
              this.closeSideBar();
              this.getComplaintDetails();
            }
          },
          error => {
            this.message.error('Failed to update complaint');
          }
        );
      }
      // If creating new complaint
      else {
        this.apiService.post(formData, 'complaint/complaint/create').subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.message.success('Complaint created successfully');
              this.closeSideBar();
              this.getComplaintDetails();
            }
          },
          error => {
            this.message.error('Failed to create complaint');
          }
        );
      }
    }
  }

  closeSideBarMain() {
    this.settings.shwSidebar = false;
    this.assgnForm.reset();
    this.complaint_id = null;
  }

  deleteComplaintRecord(data) {
    const rte = `complaint/complaint/delete/${data.complaint_id}`;
    this.apiService.delete(rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.message.success('Complaint deleted successfully.');
        this.getComplaintDetails();
      }
    });
  }

  getInactiveComplaints() {
    // Mock data for inactive complaints
    const mockInactiveData = [
      {
        sno: 1,
        complaint_type: 'Network Issue',
        caf_id: 'CAF002345',
        ticket_type: 'Technical Issue',
        complaint_category: 'Internet Connectivity',
        priority: 'Low',
        assigned_employee: 'John Doe',
        complaint_subject: 'Old Network Problem',
        description: 'Previous network issue - resolved',
        created_at: '2023-12-15 10:30:00',
        updated_at: '2023-12-16 14:20:00'
      },
      {
        sno: 2,
        complaint_type: 'Service Quality',
        caf_id: 'CAF006789',
        ticket_type: 'Customer Support',
        complaint_category: 'Service Quality',
        priority: 'Medium',
        assigned_employee: 'Jane Smith',
        complaint_subject: 'Past Connection Issue',
        description: 'Previous connection problem - fixed',
        created_at: '2023-12-14 09:15:00',
        updated_at: '2023-12-15 16:45:00'
      },
      {
        sno: 3,
        complaint_type: 'Technical Support',
        caf_id: 'CAF000123',
        ticket_type: 'Service Request',
        complaint_category: 'Hardware Issues',
        priority: 'High',
        assigned_employee: 'Robert Johnson',
        complaint_subject: 'Resolved Hardware Issue',
        description: 'Hardware problem - replaced',
        created_at: '2023-12-13 11:20:00',
        updated_at: '2023-12-14 13:30:00'
      }
    ];

    setTimeout(() => {

    }, 500);
  }

  getComplaintDetails() {
    this.complaint = [];

    this.settings.shwLdg = true;

    // Mock data for testing
    const mockData = [
      {
        sno: 1,
        complaint_type: 'Network Issue',
        caf_id: 'CAF001234',
        ticket_type: 'Technical Issue',
        complaint_category: 'Internet Connectivity',
        priority: 'High',
        assigned_employee: 'John Doe',
        complaint_subject: 'Fiber Connection Down',
        description: 'Complete network outage in area',
        created_at: '2024-01-15 10:30:00',
        updated_at: '2024-01-16 14:20:00'
      },
      {
        sno: 2,
        complaint_type: 'Service Quality',
        caf_id: 'CAF005678',
        ticket_type: 'Customer Support',
        complaint_category: 'Service Quality',
        priority: 'Medium',
        assigned_employee: 'Jane Smith',
        complaint_subject: 'Intermittent Connection',
        description: 'Frequent disconnections during peak hours',
        created_at: '2024-01-14 09:15:00',
        updated_at: '2024-01-15 16:45:00'
      },
      {
        sno: 3,
        complaint_type: 'Technical Support',
        caf_id: 'CAF009012',
        ticket_type: 'Service Request',
        complaint_category: 'Hardware Issues',
        priority: 'Low',
        assigned_employee: 'Robert Johnson',
        complaint_subject: 'Router Configuration',
        description: 'Need assistance with router settings',
        created_at: '2024-01-13 11:20:00',
        updated_at: '2024-01-14 13:30:00'
      },
      {
        sno: 4,
        complaint_type: 'Installation Request',
        caf_id: 'CAF003456',
        ticket_type: 'Complaint',
        complaint_category: 'Installation',
        priority: 'High',
        assigned_employee: 'Emily Brown',
        complaint_subject: 'New Connection Delay',
        description: 'Installation pending for 1 week',
        created_at: '2024-01-12 14:45:00',
        updated_at: '2024-01-13 09:15:00'
      },
      {
        sno: 5,
        complaint_type: 'Billing Problem',
        caf_id: 'CAF007890',
        ticket_type: 'Inquiry',
        complaint_category: 'Billing Issues',
        priority: 'Medium',
        assigned_employee: 'Michael Wilson',
        complaint_subject: 'Wrong Bill Amount',
        description: 'Incorrect charges in monthly bill',
        created_at: '2024-01-11 16:30:00',
        updated_at: '2024-01-12 11:45:00'
      }
    ];

    // Simulate API response
    setTimeout(() => {
      this.settings.shwLdg = false;
      this.complaint = mockData;
      this.colDef = [
        {
          headerName: 'S.No',
          field: 'sno',
          alignment: 'center',
          filter: false,
          width: '80'
        },
        { headerName: 'Complaint Type', field: 'complaint_type', alignment: 'left' },
        { headerName: 'CAF ID / Username', field: 'caf_id', alignment: 'left' },
        { headerName: 'Ticket Type', field: 'ticket_type', alignment: 'left' },
        { headerName: 'Complaint Category', field: 'complaint_category', alignment: 'left' },
        { headerName: 'Priority', field: 'priority', alignment: 'center' },
        { headerName: 'Assigned To', field: 'assigned_employee', alignment: 'left' },
        { headerName: 'Subject', field: 'complaint_subject', alignment: 'left' },
        { headerName: 'Description', field: 'description', alignment: 'left' },
        { headerName: 'Created At', field: 'created_at', alignment: 'left' },
        { headerName: 'Updated At', field: 'updated_at', alignment: 'left' }
      ];

      this.settings.shwGrd = true;
    }, 500);
  }

  onTabChange(event: any): void {
    if (event.index === 0) {
      this.getComplaintDetails();
    } else {
      this.getInactiveComplaints();
    }
  }

  grdClckActnEmtr(event) {
    console.log(event);
    if (event.clckd_btn_hndlr == 'Add New Complaint') {
      this.openSideBarEdit('new', event.data);
    } else if (event.clckd_btn_hndlr == 'Delete') {
      console.log('deleteeeeeeeeeeeeeeeeeeeeee')
      // this.deleteComplaint(event.data);
    } else if (event.clckd_btn_hndlr == "edit") {
      this.openSideBarEdit("edit", event.data);
    }
  }

  onGetFilterActionEvntEmtr(event) {
    if (event.action === 'add') {
      this.openSideBarEdit('new', null);
    }
  }

  Back() {
    let rte = '/setup';
    this.router.navigate([rte]);
  }

  // File selection method
  onFileSelected(event: any) {
    const file = event.target.files[0];

    // Check if file is an image
    if (file) {
      const reader = new FileReader();

      // Validate file type
      if (!file.type.match('image.*')) {
        // Reset file input and preview
        this.assgnForm.get('file_attachment').setValue(null);
        this.imagePreview = null;

        // Optional: Show error message
        this.message.error('Please select an image file only.');
        return;
      }

      // Check file size (optional, e.g., max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        this.assgnForm.get('file_attachment').setValue(null);
        this.imagePreview = null;
        this.message.error('File size should not exceed 5MB.');
        return;
      }

      // Read the image file
      reader.onload = (e: any) => {
        this.imagePreview = e.target.result;
      };

      reader.readAsDataURL(file);
    }
  }

  openSideBarEdit(opt, data) {
    if (opt == 'new') {
      this.settings.sidebarHeading = 'Add Complaint';
      this.settings.shwSidebar = true;
      this.addNewForm = true;
      this.updateForm = false;
      this.deleteForm = false;
    }
    else if (opt == 'edit') {
      this.settings.sidebarHeading = 'Edit Complaint';
      this.settings.shwSidebar = true;
      this.updateForm = true;
      this.addNewForm = false;
      this.deleteForm = false;
      this.assgnForm.get('complaint_name').setValue(data.complaint_name);
      this.complaint_id = data.complaint_id;
    }
  }

  closeSideBar() {
    this.settings.shwSidebar = false;
    this.assgnForm.reset();
  }

  refreshTicketsList() {
    if (this.ticketsList) {
      this.ticketsList.refreshAndSelectCreatedTab();
    }
  }
  refreshTicketsListAssgn(){
    if (this.ticketsList) {
      this.ticketsList.refreshAndSelectCreatedTabassign();
    }
  }
}
