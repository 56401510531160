<div class="flex flex-col flex-auto w-full">
  <div class="container">
    <!-- Header -->
    <div class="top-header">
      <div class="page-title">Inward Stock List</div>
      <div class="header-right">
        <span>Home / Inward / Inward Stock List</span>
        <button nz-button nzType="default" class="go-back-btn" (click)="goBack()">Go Back</button>
      </div>
    </div>

    <!-- Subheader -->
    <div class="sub-header">
      <div class="label">Inward Stock List</div>
      <div class="button-group">
        <button nz-button class="export-btn" (click)="exportToExcel()">Export to Excel</button>
      </div>
    </div>

    <div class="table-container">
      <!-- Form Section -->
      <div class="form-section">
        <label class="bold">Select Branch</label>
        <nz-select class="branch-select" [(ngModel)]="selectedBranch">
          <nz-option *ngFor="let branch of branches" [nzValue]="branch.value" [nzLabel]="branch.label"></nz-option>
        </nz-select>
        <label nz-checkbox [(ngModel)]="selectAll" (ngModelChange)="handleSelectAllChange()">Select All</label>
        <button nz-button nzType="primary" (click)="handleSubmit()">Submit</button>
      </div>

      <!-- Action Buttons -->
      <div class="action-buttons">
        <button nz-button nzType="primary" class="bulk-verify">Bulk Verify</button>
        <button nz-button nzDanger class="bulk-mismatched">Bulk Mismatched</button>
      </div>

      <!-- Table -->
      <dx-data-grid
        [dataSource]="dataSource"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [rowAlternationEnabled]="true"
        [showColumnLines]="true"
        [showRowLines]="true"
        [paging]="{ pageSize: 10 }"
        [pager]="{ showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true }"
        [searchPanel]="{ visible: true, highlightSearchText: true, placeholder: 'Search...' }"
        [sorting]="{ mode: 'multiple' }"
      >
        <dxo-toolbar>
          <dxi-item name="searchPanel" location="after"></dxi-item>
          <dxi-item location="after">
            <div class="toolbar-item">
              <button nz-button nzType="primary" class="outward-btn" (click)="openOutwardDrawer()">
                 Add Inward Stock List
              </button>
            </div>
          </dxi-item>
        </dxo-toolbar>

        <dxo-paging [enabled]="true"></dxo-paging>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20]" [showInfo]="true"></dxo-pager>

        <dxi-column dataField="sno" caption="S.No" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="user" caption="User" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="inward" caption="Inward" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="po" caption="PO" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="branch" caption="Branch" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="category" caption="Category" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="inventory" caption="Inventory" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="qty" caption="Qty" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="status" caption="Status" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="make" caption="Make" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="model" caption="Model" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="mag" caption="Mag" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="period" caption="Period" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="ware" caption="Ware" [allowSorting]="true"></dxi-column>
        <dxi-column caption="Actions" [width]="100" cellTemplate="actionsTemplate"></dxi-column>

        <div *dxTemplate="let data of 'actionsTemplate'">
          <div class="action-buttons-container">
            <button nz-button nzType="primary" nzSize="small" (click)="openEditDrawer(data)">
              <i nz-icon nzType="edit"></i>
            </button>
            <button nz-button nzType="danger" nzSize="small" (click)="openDeleteDrawer(data)">
              <i nz-icon nzType="delete"></i>
            </button>
          </div>
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>

<!-- Edit Drawer -->
<nz-drawer
  [nzVisible]="editDrawerVisible"
  nzTitle="Edit Inward"
  (nzOnClose)="closeDrawer()"
  [nzWidth]="520"
>
  <ng-container *nzDrawerContent>
    <div class="drawer-content">
      <p>Edit Inward Content</p>
    </div>
  </ng-container>
</nz-drawer>

<!-- Delete Drawer -->
<nz-drawer
  [nzVisible]="deleteDrawerVisible"
  nzTitle="Delete Inward"
  (nzOnClose)="closeDrawer()"
  [nzWidth]="520"
>
  <ng-container *nzDrawerContent>
    <div class="drawer-content">
      <p>Are you sure you want to delete this inward record?</p>
      <div class="drawer-footer">
        <button nz-button nzType="default" (click)="closeDrawer()">Cancel</button>
        <button nz-button nzType="danger" (click)="deleteRecord()">Delete</button>
      </div>
    </div>
  </ng-container>
</nz-drawer>

<!-- Outward Drawer -->
<nz-drawer
  [nzVisible]="outwardDrawerVisible"
  nzTitle="Outward Stock Details"
  (nzOnClose)="closeDrawer()"
  [nzWidth]="520"
>
  <ng-container *nzDrawerContent>
    <div class="drawer-content">
      <p>INward Stock List Details</p>
    </div>
  </ng-container>
</nz-drawer>