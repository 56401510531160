import { Component } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inward-stocklist',
  templateUrl: './inward-stocklist.component.html',
  styleUrls: ['./inward-stocklist.component.css']
})
export class InwardStocklistComponent {
  dataSource: any[] = [];
  editDrawerVisible = false;
  deleteDrawerVisible = false;
  outwardDrawerVisible = false;
  selectedBranch: string | null = null;
  selectAll = false;

  branches = [
    { value: 'branch1', label: 'Branch 1' },
    { value: 'branch2', label: 'Branch 2' },
    { value: 'branch3', label: 'Branch 3' }
  ];

  constructor(
    private drawerService: NzDrawerService,
    private router: Router
  ) {
    this.initializeData();
  }

  // Method to initialize the data source
  initializeData(): void {
    this.dataSource = [
      { 
        sno: 1, 
        user: 'John Doe', 
        inward: 'INV001', 
        po: 'PO123', 
        branch: 'Branch A', 
        category: 'Electronics', 
        inventory: 'Laptop', 
        qty: 10, 
        status: 'Verified', 
        make: 'Dell', 
        model: 'XPS 15', 
        mag: 'Yes', 
        period: '12 Months', 
        ware: 'Warehouse A' 
      },
      { 
        sno: 2, 
        user: 'Jane Smith', 
        inward: 'INV002', 
        po: 'PO124', 
        branch: 'Branch B', 
        category: 'Furniture', 
        inventory: 'Chair', 
        qty: 25, 
        status: 'Pending', 
        make: 'IKEA', 
        model: 'Model B', 
        mag: 'No', 
        period: '6 Months', 
        ware: 'Warehouse B' 
      }
    ];
  }

  // Navigate back to the previous page
  goBack(): void {
    this.router.navigate(['../']);
  }

  // Export data to Excel
  exportToExcel(): void {
    console.log('Exporting to Excel...');
  }

  // Handle select all change
  handleSelectAllChange(): void {
    console.log('Select all changed:', this.selectAll);
  }

  // Handle form submission
  handleSubmit(): void {
    console.log('Form submitted with branch:', this.selectedBranch);
  }

  // Delete record
  deleteRecord(): void {
    console.log('Deleting record');
    this.closeDrawer();
  }

  // Drawer methods
  openEditDrawer(data: any): void {
    this.editDrawerVisible = true;
    console.log('Editing record:', data);
  }

  openDeleteDrawer(data: any): void {
    this.deleteDrawerVisible = true;
    console.log('Deleting record:', data);
  }

  openOutwardDrawer(): void {
    this.outwardDrawerVisible = true;
    console.log('Opening outward drawer');
  }

  closeDrawer(): void {
    this.editDrawerVisible = false;
    this.deleteDrawerVisible = false;
    this.outwardDrawerVisible = false;
  }
}
