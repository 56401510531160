<div>
  <div class="dashboard-container">

    <div class="dashboard-grid">

      <!-- Left Section -->
      <div class="asset-cards-section">
        <div class="asset-card-wrapper" *ngFor="let asset of leftAssts; let i = index" [(ngClass)]="asset.hglt_cls">

          <div class="asset-card-left">
            <div class="card-content">
              <div class="card-header">
                <div class="card-icon">
                  <img [src]="asset.icn_tx" alt="{{asset.ast_typ_nm}} icon">
                </div>
                <div class="card-type-info">
                  <div class="card-name">{{asset.ast_typ_nm}}</div>
                </div>
              </div>
              <div class="card-details">
                <div class="info-sections">

                  <div class="info-section working-section">
                    <div class="section-header">{{asset.ast_sts_lst[0].sts_nm}} ({{working(asset)}})</div>
                    <div class="section-content">
                      <div class="info-row">
                        <div class="info-data" *ngFor="let asg of asset.ast_sts_lst[0]['sub_sts_lst']">
                          <div class="info-value">{{asg.ct || 0}}</div>
                          <div class="info-label">{{asg.sub_sts_nm}}</div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="info-section not-working-section">
                    <div class="section-header">{{asset.ast_sts_lst[1].sts_nm}} ({{notWorking(asset)}})</div>
                    <div class="section-content">
                      <div class="info-row">
                        <div class="info-data" *ngFor="let asg of asset.ast_sts_lst[1]['sub_sts_lst']">
                          <div class="info-value">{{asg.ct || 0}}</div>
                          <div class="info-label">{{asg.sub_sts_nm}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="asset-card-shadow-left"></div>
        </div>
      </div>

      <!-- Center Section -->
      <div class="center-section">
        <div class="summary-card">
          <div class="total-section">
            <div class="total-item">
              <div class="search-wrapper">
                <div class="search-input-container">
                  <i nz-icon nzType="search" nzTheme="outline" class="search-prefix-icon"></i>
                  <input type="text" 
                         [(ngModel)]="searchQuery" 
                         (ngModelChange)="onSearch($event)" 
                         placeholder="Search Assets" 
                         class="search-input" />
                  <span class="clear-icon" *ngIf="searchQuery" (click)="clearSearch()">
                    <i nz-icon nzType="close-circle" nzTheme="fill"></i>
                  </span>
                </div>
              </div>
              <h3>TOTAL ASSETS</h3>
              <div class="total-number">{{dshbrd_data.length}}</div>
            </div>
          </div>

          <div class="city-dropdown-container">
            <div class="breadcrumb">
              <span class="breadcrumb-item" (click)="onAndhrapradeshClick()">Andhra Pradesh</span>
              <span class="breadcrumb-separator">></span>
              <span class="breadcrumb-item active">{{slct_dst_nm}}</span>
            </div>
            <nz-select class="city-dropdown" [(ngModel)]="selectedCity" (ngModelChange)="onCityChange($event)"
              nzPlaceHolder="Select District">
              <nz-option *ngFor="let city of dstcts" [nzValue]="city.dstrt_id" [nzLabel]="city.tnt_nm">
              </nz-option>
            </nz-select>
          </div>

          <div class="chart-section">
            <div class="chart-container">
              <canvas #chartCanvas id="pieChart" [style.display]="shwEmptyPieChart ? 'none' : 'block'"></canvas>
              <div class="no-data-container" *ngIf="shwEmptyPieChart">
                <div class="no-data-message">
                  <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                  <span>No data found for the selected district</span>
                </div>
              </div>
            </div>
            <div class="chart-legend">
              <div class="legend-column">
                <div class="legend-item" *ngFor="let item of firstColumnLegendItems; let i = index">
                  <div class="color-dot" [style.background-color]="item.color"></div>
                  <div class="legend-label">{{item.label}} ({{dshbrd_data[i]?.ttl_ast || 0}})</div>
                </div>
              </div>
              <div class="legend-column">
                <div class="legend-item" *ngFor="let item of secondColumnLegendItems; let i = index">
                  <div class="color-dot" [style.background-color]="item.color"></div>
                  <div class="legend-label">{{item.label}} ({{dshbrd_data[firstColumnLegendItems.length + i]?.ttl_ast ||
                    0}})</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Section -->
      <div class="asset-cards-section">
        <div class="asset-card-wrapper" *ngFor="let asset of rightAssts; let i = index" [(ngClass)]="asset.hglt_cls">
          <div class="asset-card-shadow-right"></div>
          <div class="asset-card-right">
            <div class="card-content">
              <div class="card-header">
                <div class="card-icon">
                  <img [src]="asset.icn_tx" alt="{{asset.ast_typ_nm}} icon">
                </div>
                <div class="card-type-info">
                  <div class="card-name">{{asset.ast_typ_nm}}</div>
                </div>
              </div>
              <div class="card-details">
                <div class="info-sections">

                  <div class="info-section working-section">
                    <div class="section-header">{{asset.ast_sts_lst[0].sts_nm}} ({{working(asset)}})</div>
                    <div class="section-content">
                      <div class="info-row">
                        <div class="info-data" *ngFor="let asg of asset.ast_sts_lst[0]['sub_sts_lst']">
                          <div class="info-value">{{asg.ct || 0}}</div>
                          <div class="info-label">{{asg.sub_sts_nm}}</div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="info-section not-working-section">
                    <div class="section-header">{{asset.ast_sts_lst[1].sts_nm}} ({{notWorking(asset)}})</div>
                    <div class="section-content">
                      <div class="info-row">
                        <div class="info-data" *ngFor="let asg of asset.ast_sts_lst[1]['sub_sts_lst']">
                          <div class="info-value">{{asg.ct || 0}}</div>
                          <div class="info-label">{{asg.sub_sts_nm}}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>