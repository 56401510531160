import { Component, OnInit ,ViewChild } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
@Component({
  selector: 'app-complaint-dashboard-new',
  templateUrl: './complaint-dashboard.componentNew.html',
  styleUrls: ['./complaint-dashboard.componentNew.scss']
})
export class ComplaintDashboardComponentNew implements OnInit{
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  dstrtdata: any;
  header_label = {
    name: `Complaint Dashboard`,
    dtepkrlbl: false
  };
  slctd_dstrt=0
  slectdCrd;
  assetCardsData: any[];
  slectdCrd_nm: any;
  columnDefs;
  assetGridData: any[];
  columnDefs2: ({ headerName: string; field: string; alignment: string; width: number; visible: boolean; groupIndex?: undefined; } | { headerName: string; field: string; width: string; alignment: string; visible?: undefined; groupIndex?: undefined; } | { headerName: string; field: string; alignment: string; groupIndex: number; width?: undefined; visible?: undefined; } | { headerName: string; field: string; alignment: string; width?: undefined; visible?: undefined; groupIndex?: undefined; })[];
  columnDefs1: ({ headerName: string; field: string; alignment: string; width: number; visible: boolean; } | { headerName: string; field: string; width: string; alignment: string; visible?: undefined; } | { headerName: string; field: string; alignment: string; width?: undefined; visible?: undefined; })[];
  slct_index= 0;
  userDtls: any;
  ComplaintTypeCardsData: any[];
  ComplaintGridData: any;
  selectedTicket: any;
  sidenav: boolean;
  constructor(public apiServ: AppsService, private route: ActivatedRoute, private router: Router,) {
    this.userDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.userDtls)
    this.slctd_dstrt=this.userDtls[0].tnts.length==1 ? this.userDtls[0].tnts[0].tnt_id:0;

  }
  
  ngOnInit(): void {

     this.columnDefs = [
    { headerName: 'District', field: 'dstrct_nm', width: '120', alignment: 'left' },
    { headerName: 'Ticket ID', field: 'tckt_id', width: '100', alignment: 'center' },
    { headerName: 'Created Date', field: 'wrk_strt_dt', width: '120', alignment: 'center' },
    { headerName: 'Status', field: 'wrk_sts_dsply_nm', width: '120', alignment: 'center' ,  cellTemplate: 'statusTemplate'},
    { headerName: 'Priority', field: 'priority', width: '100', alignment: 'center' },
    { headerName: 'Assigned To', field: 'emple_nm', width: '150', alignment: 'left' },
    { headerName: 'Customer', field: 'cstmr_nm', width: '150', alignment: 'left' },
    { headerName: 'Type', field: 'wrk_typ_nm', width: '150', alignment: 'left' },
    { headerName: 'Description', field: 'oms_wrm_dsc_tx', width: '200', alignment: 'left' }
  ];

    this.getDstrtData();
    this.getAssetCardsData();
  }

  colorChanges = (clr_cd) => {
    console.log("test case")
    document.documentElement.style.setProperty('--colorcode', clr_cd);

  }
  
  getDstrtData() {

    this.apiServ.get(`apsfl/data/districts`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.dstrtdata = res['data'];
        console.log(this.slctd_dstrt)
        if(this.slctd_dstrt > 0){
          this.dstrtdata = res['data'].filter((w)=> w.tnt_id==this.slctd_dstrt)
          console.log(this.dstrtdata)
        }else{
          this.dstrtdata=res['data']
        }
      }

    })

  }

  getAssetCardsData() {
    this.ComplaintTypeCardsData = []
    this.apiServ.get('complaint/complaint-types-count').subscribe((res) => {
      if (res['status'] == 200) {
        this.ComplaintTypeCardsData = res['data'];
        console.log(this.slct_index)
        this.selctedcard(this.ComplaintTypeCardsData[this.slct_index],this.slct_index)
      }
    })
  }

  statusTemplate = (cellElement, cellInfo) => {
    // console.log(cellInfo , cellElement , "info")
    const statusColor = cellInfo.data.status_color; 
    const statusText = cellInfo.data.wrk_sts_dsply_nm;  
  
    cellElement.innerHTML = `
      <span style="color: ${statusColor}; font-weight: bold;">
        ${statusText}
    </span>
    `;
  };
  

  // loadComplaintTypesCount() {

  //   this.apiService.get('complaint/complaint-types-count').subscribe({
  //     next: (response: any) => {
  //       if (response && response.data) {
  //         this.complaintCards = response.data.map((item: any) => ({
  //           ...item,
  //           total: item.ttl,
  //           pending: item.pending,
  //           completed: item.completed,
  //           color: item.clr_cd
  //         }));
  //       }
  //       this.loading = false;
  //     },
  //     error: (error) => {
  //       console.error('Error loading complaint types:', error);
  //       this.loading = false;
  //     }
  //   });
  // }


  getAssetGridData() {
    this.ComplaintGridData = []
    this.columnDefs=[]
    this.columnDefs1=[]
    this.columnDefs2=[]
    this.apiServ.get(`complaint/complaint-types/tickts/${this.slectdCrd}`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.ComplaintGridData = res['data'];
console.log(this.ComplaintGridData,"data")
        var count = 1
        this.ComplaintGridData.forEach(e => {
          e['sno'] = count++
        });

        this.columnDefs = [
          { headerName: 'District', field: 'dstrct_nm', width: 'auto', alignment: 'left' },
          { headerName: 'Ticket ID', field: 'tckt_id', width: 'auto', alignment: 'center' },
          { headerName: 'Created Date', field: 'wrk_strt_dt', width: 'auto', alignment: 'center' },
          { headerName: 'Status', field: 'wrk_sts_dsply_nm', width: 'auto', alignment: 'center', cellTemplate: this.statusTemplate },
          { headerName: 'Priority', field: 'priority', width: 'auto', alignment: 'center' },
          { headerName: 'Assigned To', field: 'emple_nm', width: 'auto', alignment: 'left' },
          { headerName: 'Customer', field: 'cstmr_nm', width: 'auto', alignment: 'left' },
          { headerName: 'Type', field: 'wrk_typ_nm', width: 'auto', alignment: 'left' },
          { headerName: 'Description', field: 'oms_wrm_dsc_tx', width: 'auto', alignment: 'left' }
        ];
      
        console.log(this.slectdCrd)
      // Component TypeScript Code

this.dataGrid.instance.refresh();
       
      }
    })
  }


//   groupCellTemplate = (cellElement, cellInfo) => {
//     // cellInfo provides information about the group
//     const groupField = cellInfo.column.caption;
//     const groupCount = cellInfo.data.items.length;

//     // Customize the group header with the field name and group count
//     cellElement.innerHTML = `${groupField}: ${cellInfo.key} (${groupCount} items)`;
// };
groupCellTemplate = (cellElement, cellInfo) => {
  const groupField = cellInfo.column.caption;
  const groupKey = cellInfo.key;

  // Check if items exist
  const groupCount = cellInfo.data?.items?.length ?? 0;

  // Customize the group header
  cellElement.innerHTML = `${groupField}: ${groupKey} (${groupCount} items)`;
};


  getDstrwiseData(e){
    this.getAssetCardsData()
  }

  selctedcard(e,index){
    this.dataGrid.instance.clearGrouping();
    this.columnDefs=[]
    this.ComplaintGridData=[]
    console.log(e,index)
    this.slct_index=index
    this.slectdCrd_nm=e.wrk_grp_nm;
    this.slectdCrd=e.wrk_grp_id
    this.colorChanges(e.crd_clr)
    console.log(this.slectdCrd)
    this.getAssetGridData()
  }
  
  gotodetails(data){
    this.selectedTicket=data.data
    console.log(this.selectedTicket)
    this.sidenav=true
  }
  closeSideBar(){
    this.sidenav=false
  }

}
